// eslint-disable-next-line no-shadow
enum PaymentType {
  CREDIT_CARD = 'CREDIT_CARD',
  TWO_CREDIT_CARDS = 'TWO_CREDIT_CARDS',
  DEBIT_CARD = 'DEBIT_CARD',
  PIX = 'PIX',
  BANK_SLIP = 'BANK_SLIP',
  MONEY = 'MONEY',
}

export default PaymentType;
