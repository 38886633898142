import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { onlyNumbers } from '../../../../helpers/common';
import validators from '../../../../helpers/validators';
import useForm from '../../../../hooks/useForm';
import { setLoading } from '../../../../redux/loading/loadingSlice';
import { api } from '../../../../services/api';
import DiscountCoupon from 'src/model/DiscountCoupon';
import { CupomCreateUI } from './ui';
import { FormInputNameCupom } from '../../types';

export const CupomCreateScreen = () => {
  const dispatch = useDispatch();
  const [cupons, setCupons] = useState<DiscountCoupon[]>([]);

  // Busca todos os cupons
  const getCupons = async (): Promise<void> => {
    dispatch(setLoading(true));
    try {
      const response = await api.get<DiscountCoupon[]>('/cupons/find-all');
      setCupons(response.data);
    } catch (error) {
      console.error('Erro ao buscar cupons:', error);
      toast.error('Erro ao buscar cupons.');
    } finally {
      dispatch(setLoading(false));
    }
  };

  // Configuração do formulário
  const {
    formData: formDataCupomCreate,
    formErrors: formErrorsCupomCreate,
    onChangeFormInput: onChangeFormInputCupomCreate,
    isFormValid: isFormValidCupomCreate,
    resetForm: resetFormCupomCreate,
  } = useForm({
    initialData: {
      name: '',
      code: '',
      amount: '',
      discountType: '',
      discount: '',
      amountSold: '',
      status: '',
    },
    validators: {
      name: [validators.required],
      code: [validators.required],
      amount: [validators.required],
      discountType: [validators.required],
      discount: [validators.required],
      status: [validators.required],
    },
  });

  // Submissão do formulário
  const onSubmitCupomCreate = async (e: React.FormEvent): Promise<void> => {
    e.preventDefault();

    if (!isFormValidCupomCreate()) {
      toast.error('Preencha todos os campos corretamente.');
      return;
    }

    try {
      dispatch(setLoading(true));

      const response = await api.post('/cupons/create', {
        name: formDataCupomCreate[FormInputNameCupom.name],
        code: formDataCupomCreate[FormInputNameCupom.code],
        amount: onlyNumbers(formDataCupomCreate[FormInputNameCupom.amount]),
        discountType: formDataCupomCreate[FormInputNameCupom.discountType],
        discount: onlyNumbers(formDataCupomCreate[FormInputNameCupom.discount]),
        amountSold: onlyNumbers(
          formDataCupomCreate[FormInputNameCupom.amountSold]
        ),
        status: formDataCupomCreate[FormInputNameCupom.status],
      });

      if (response.status === 201) {
        toast.success('Cupom criado com sucesso!');
        resetFormCupomCreate();
        getCupons(); // Atualiza a lista de cupons
      }
    } catch (error) {
      console.error('Erro ao criar cupom:', error);
      toast.error('Erro ao criar cupom.');
    } finally {
      dispatch(setLoading(false));
    }
  };

  // Exclusão de cupom
  const handleOnDeleteCupom = async (id: string): Promise<void> => {
    try {
      dispatch(setLoading(true));
      await api.delete(`/cupons/delete/${id}`);
      toast.success('Cupom excluído com sucesso!');
      getCupons(); // Atualiza a lista de cupons
    } catch (error) {
      console.error('Erro ao excluir cupom:', error);
      toast.error('Erro ao excluir cupom.');
    } finally {
      dispatch(setLoading(false));
    }
  };

  // Busca os cupons ao carregar a tela
  useEffect(() => {
    getCupons();
  }, []);

  return (
    <CupomCreateUI
      formData={formDataCupomCreate}
      formErrors={formErrorsCupomCreate}
      onChangeFormInput={onChangeFormInputCupomCreate}
      onSubmitRegister={onSubmitCupomCreate}
      onDeleteCupom={handleOnDeleteCupom}
      cupons={cupons}
    />
  );
};
