import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { onlyNumbers } from '../../../../helpers/common';
import { encrypt } from '../../../../helpers/crypt/crypt';
import {
  getCreditCardLabel,
  updateHolderName,
  updateMaskCVV,
  updateMaskCard,
  validateCVV,
} from '../../../../helpers/masks/cardNumber';
import { updateMask as cpfCnpjMask } from '../../../../helpers/masks/cpfCnpj';
import { updateMask as expirationDateMask } from '../../../../helpers/masks/expirationDate';
import validators from '../../../../helpers/validators';
import useForm from '../../../../hooks/useForm';
import Card from '../../../../model/Card';
import CardClient from '../../../../model/CardClient';
import CardClientCrypt from '../../../../model/CardClientCrypt';
import CardDb from '../../../../model/CardDb';
import { setLoading } from '../../../../redux/loading/loadingSlice';
import { api } from '../../../../services/api';
import { FormInputNameCreditCard } from '../../types';
import { CreditCardPaymentAddUI } from './ui';

export const CreditCardPaymentAddScreen = () => {
  //   const { loading } = useSelector(useLoading);
  const dispatch = useDispatch();
  // const [cardSelected, setCardSelected] = useState<CardDb>();
  const [cards, setCard] = useState<CardDb[]>([]);

  const getCards = async (): Promise<void> => {
    dispatch(setLoading(true));
    const response = await api.get<CardDb[]>('/client-card/find-all');
    setCard(response.data);
    console.log(response.data);
    dispatch(setLoading(false));
  };

  const {
    formData: formDataAddCreditCard,
    formErrors: formErrorsAddCreditCard,
    setErrors: setErrorsAddCreditCard,
    onChangeFormInput: onChangeFormInputAddCreditCard,
    isFormValid: isFormValidAddCreditCard,
    resetForm: resetFormAddCreditCard,
  } = useForm({
    initialData: {
      number: '',
      date: '',
      cvv: '',
      name: '',
      document: '',
    },
    validators: {
      number: [validators.required, validators.cardNumber],
      date: [validators.required, validators.cardExpirationDate],
      cvv: [validators.required, validators.cvv],
      name: [validators.required],
      document: [validators.required, validators.cpforcnpj],
    },
    formatters: {
      number: updateMaskCard,
      date: expirationDateMask,
      cvv: updateMaskCVV,
      name: updateHolderName,
      document: cpfCnpjMask,
    },
  });

  const onSubmitAddCreditCard = async (e: React.FormEvent): Promise<void> => {
    e.preventDefault();
    if (isFormValidAddCreditCard()) {
      if (
        validateCVV(
          formDataAddCreditCard[FormInputNameCreditCard.number],
          formDataAddCreditCard[FormInputNameCreditCard.cvv]
        )
      ) {
        try {
          dispatch(setLoading(true));
          const expDate =
            formDataAddCreditCard[FormInputNameCreditCard.date].split('/');

          const card: Card = {
            holder: formDataAddCreditCard[FormInputNameCreditCard.name],
            number: onlyNumbers(
              formDataAddCreditCard[FormInputNameCreditCard.number]
            ),
            expMonth: expDate[0],
            expYear: `20${expDate[1]}`,
            securityCode: formDataAddCreditCard[FormInputNameCreditCard.cvv],
            document: onlyNumbers(
              formDataAddCreditCard[FormInputNameCreditCard.document]
            ),
            cardType: getCreditCardLabel(
              formDataAddCreditCard[FormInputNameCreditCard.number]
            ),
          };
          const encrypted = encrypt(JSON.stringify(card));

          const cardClientCrypt: CardClientCrypt = {
            data: encrypted,
          };

          console.log(cardClientCrypt);

          const responseCardClient = await api.post<CardClient>(
            '/client-card',
            cardClientCrypt
          );
          // await getUser();
          // const responseEncrypted = await api.get(
          //   `/client-card/find-one/${responseCardClient.data.id}`
          // );
          // const cardDB = JSON.parse(
          //   decrypt(responseEncrypted.data.data)
          // ) as CardDb;
          // setTimeout(() => {
          //   setSelectedCard(parseInt(String(responseCardClient.data.id)));
          // }, 500);
          if (responseCardClient.status === 201) {
            getCards();
            resetFormAddCreditCard();
          }
          // onSetVisible(false);

          toast.success('Cartão adicionado com sucesso!');
          //eslint-disable-next-line
        } catch (error: any) {
          console.error('Erro ao adicionar o cartão:', error);
          setErrorsAddCreditCard({
            ['document']: ['Algo deu errado'],
          });
        } finally {
          dispatch(setLoading(false));
        }
      } else {
        toast.error('Código de segurança inválido!');
        resetFormAddCreditCard();
        dispatch(setLoading(false));
      }
    }
  };

  const handleOnDeleteCard = async (id: string): Promise<void> => {
    try {
      if (id) {
        dispatch(setLoading(true));
        await api.get(`/client-card/delete/${id}`);
        getCards();
        toast.success('Cartão removido com sucesso!');
      } else {
        toast.error('Nem um cartão selecionado');
      }

      // eslint-disable-next-line
    } catch (error: any) {
      dispatch(setLoading(false));
      console.error('Erro ao buscar cartões de crédito:', error);
    } finally {
      dispatch(setLoading(false));
    }
  };

  // useEffect(() => {
  //   //eslint-disable-next-line
  //   cardSelected && resetFormAddCreditCard();
  //   //eslint-disable-next-line
  // }, []);

  useEffect(() => {
    getCards();
    //eslint-disable-next-line
  }, []);

  return (
    <CreditCardPaymentAddUI
      formData={formDataAddCreditCard}
      formErrors={formErrorsAddCreditCard}
      onChangeFormInput={onChangeFormInputAddCreditCard}
      onSubmitRegister={onSubmitAddCreditCard}
      cards={cards}
      onDeleteCard={handleOnDeleteCard}
    />
  );
};
