import React from 'react';
import { Route } from 'src/navigation/Route';
import { AdminScreen } from './screens/Admin';

export const ADMIN_ROUTES = {
  itself: '/admin',
  onParams: '/admin/:id',
};

export const AdminNavigation = (): JSX.Element => (
  <React.Fragment>
    <Route
      exact
      path={ADMIN_ROUTES.itself}
      component={AdminScreen}
      isPrivateRoute={true}
    />
    <Route
      exact
      path={ADMIN_ROUTES.onParams}
      component={AdminScreen}
      isPrivateRoute={true}
    />
  </React.Fragment>
);
