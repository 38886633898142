import React, { useState } from 'react';
import { ProfileSideMenu } from 'src/components/ProfileSideMenu';
import { CategoriesManagerScreen } from 'src/features/admin/components/Categories';
import { UsersManagerScreen } from 'src/features/admin/components/UsersManager';
// import DiscountController from 'src/features/admin/components/DiscountController';
import AppQrCodeManagerScreen from 'src/features/admin/components/AppQrCode';
import { DashboardScreen } from 'src/features/admin/components/Dashboard';
import { HomePrimaryBannerManagerScreen } from 'src/features/admin/components/HomePrimaryBanner';
import { HomeSecondaryBannerManagerScreen } from 'src/features/admin/components/HomeSecondaryBanner';
import { HomeThirdBannerManagerScreen } from 'src/features/admin/components/HomeThirdBanner';
import { HomeMidleBannerManagerScreen } from 'src/features/admin/components/MidleBanner';
import { PlatformProfitControllerScreen } from 'src/features/admin/components/PlataformProfitController';
import { ProductApprovalScreen } from 'src/features/admin/components/ProductApproval';
import { SocialLinksManagerScreen } from 'src/features/admin/components/SocialLinks';
import VideoTutorialManagerScreen from 'src/features/admin/components/VideoTutorial';
import { AuthWrapper } from 'src/features/core/auth/screen/AuthWrapper';
import Client from 'src/model/User';
import { NotificationComponentScreen } from 'src/components/NotificationComponent';
import { AlertMessage } from 'src/model/AlertMessage';
import ProductModal from 'src/components/ProductInfo/ProductModal';

interface AdminUIProps {
  user: Client;
  notifications: AlertMessage[];
}

export const AdminUI: React.FC<AdminUIProps> = ({ user, notifications }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [selectedProduct] = useState<any>(null);

  const menuItems = [
    {
      key: 'dashboard',
      title: 'Dashboard',
      content: (
        <div className="w-full">
          <DashboardScreen />
        </div>
      ),
    },
    {
      key: 'notifications',
      title: 'Notificações',
      content: (
        <NotificationComponentScreen
          alertMessage={notifications}
          variant="page"
        />
      ),
    },
    {
      key: 'manage-users',
      title: 'Gerenciar Usuários',
      content: (
        <div className="w-full">
          <UsersManagerScreen />
        </div>
      ),
    },
    {
      key: 'manage-products',
      title: 'Gerenciar Produtos',
      content: (
        <div className="w-full">
          <ProductApprovalScreen />
        </div>
      ),
    },
    {
      key: 'manage-earnings',
      title: 'Gerenciar Ganhos',
      content: (
        <div className="w-full">
          <PlatformProfitControllerScreen />
        </div>
      ),
    },
    {
      key: 'manage-tutorials',
      title: 'Gerenciar Tutoriais',
      content: (
        <div className="w-full">
          <VideoTutorialManagerScreen />
        </div>
      ),
    },
    {
      key: 'manage-social-links',
      title: 'Gerenciar Links Sociais',
      content: (
        <div className="w-full">
          <SocialLinksManagerScreen />
        </div>
      ),
    },
    {
      key: 'manage-qrcode',
      title: 'Gerenciar QrCode',
      content: (
        <div className="w-full h-screen">
          <AppQrCodeManagerScreen />
        </div>
      ),
    },
    {
      key: 'main-banners',
      title: 'Banners Principais',
      content: (
        <div className="w-full">
          <HomePrimaryBannerManagerScreen />
        </div>
      ),
    },
    {
      key: 'highlight-banners',
      title: 'Banners Destaques',
      content: (
        <div className="w-full">
          <HomeSecondaryBannerManagerScreen />
        </div>
      ),
    },
    {
      key: 'news-extras',
      title: 'Novidades e extras',
      content: (
        <div className="w-full">
          <HomeThirdBannerManagerScreen />
        </div>
      ),
    },
    {
      key: 'middle-banners',
      title: 'Banners do Meio',
      content: (
        <div className="w-full">
          <HomeMidleBannerManagerScreen />
        </div>
      ),
    },
    {
      key: 'manage-home-categories',
      title: 'Gerenciar Categorias Home',
      content: (
        <div className="w-full">
          <CategoriesManagerScreen />
        </div>
      ),
    },
  ];

  return (
    <AuthWrapper>
      <div className="container bg-white mt-4 rounded-md border border-red-200">
        <ProfileSideMenu user={user} menuItems={menuItems} />
      </div>

      <ProductModal
        isOpen={isModalOpen}
        product={selectedProduct}
        onClose={() => setIsModalOpen(false)} // Função para fechar o modal
      />
    </AuthWrapper>
  );
};
