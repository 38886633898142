import React, { useEffect, useState } from 'react';
import { Category } from 'src/model/Category';
import { Grade } from 'src/model/Grade';
import { Product } from 'src/model/Product';
import EditProductUI from './ui';
import { useDispatch } from 'react-redux';
import { setLoading } from 'src/redux/loading/loadingSlice';
import { toCurrency } from 'src/helpers/currency/toCurrency';
import { api } from 'src/services/api';
import { toast } from 'react-toastify';
import {
  EditProductBasic,
  FormInputGrade,
  FormInputGradeSize,
} from 'src/features/createProduct/types/types';
import { GradeSize } from 'src/model/GradeSize';

interface EditProductScreenProps {
  id: string;
}

export const EditProductScreen: React.FC<EditProductScreenProps> = ({
  id,
}): JSX.Element => {
  const [formData, setFormData] = useState<Product>({} as Product);
  const [categoryOptions, setCategoryOptions] = useState<Category[]>([]);
  const [inputTags, setInputTags] = useState<string[]>([]);
  const [filesImage, setFilesImage] = useState<File[]>([]);
  const [filesBase64Grade, setFilesBase64Grade] = useState<string>('');
  const [previewFiles, setPreviewFiles] = useState<string[]>([]);
  const [previewFilesGrade, setPreviewFilesGrade] = useState<string>('');
  const [previewGradesSizes, setPreviewGradesSizes] = useState<GradeSize[]>([]);
  const [isGrade, setIsGrade] = useState<boolean>(false);
  const [grade, setGrade] = useState<Grade[]>([]);
  const [gradeSize, setGradeSize] = useState<GradeSize[]>([]);
  const [shippingCost, setShippingCost] = useState<string | null>(null);
  const [selectedCategories, setSelectedCategories] = useState<Category[]>([]);
  const [tags, setTags] = useState<string[]>([]);
  const [dataProduct, setData] = useState<Product>({} as Product);

  const dispatch = useDispatch();

  const handleShippingCalc = async (
    altura: string,
    largura: string,
    profundidade: string,
    peso: string
  ) => {
    try {
      console.log('Entrou handleShippingCalc');
      const payload = { altura, largura, profundidade, peso };

      const response = await api.post('/product/calc-frete', payload);

      setShippingCost(toCurrency(response.data.frete));
    } catch (error) {
      console.error('Erro ao calcular frete:', error);
      setShippingCost('Erro ao calcular o frete.');
    }
  };

  const getProductEdit = async (): Promise<void> => {
    dispatch(setLoading(true));
    try {
      if (id !== '' && id !== undefined) {
        const response = await api.get<Product>(`/product/one/${id}`);
        console.log('Entrou aqui shippingcost');
        console.log(response.data);
        const valorFrete = handleShippingCalc(
          response.data?.dimensions.height ?? '',
          response.data?.dimensions.width ?? '',
          response.data?.dimensions.depth ?? '',
          response.data?.dimensions.weight ?? ''
        );
        if (response.status === 200) {
          console.log(response.data.freeShipping);
          setSelectedCategories(response.data.category);
          setIsGrade(true);
          setFormData(response.data);
          setGrade(response.data.grade);
          setInputTags(response.data.tags);
          setShippingCost(valorFrete.toString());
          setPreviewFiles(response.data.imagens);
          setTags(response.data.tags);
          setData(response.data);
        }
      }
    } catch (error) {
      console.error('Erro ao buscar produto:', error);
      toast.error('Erro ao carregar o produto para edição');
    } finally {
      dispatch(setLoading(false));
    }
  };

  const getCategory = async (): Promise<void> => {
    try {
      dispatch(setLoading(true));
      const response = await api.get<Category[]>('/category/find-all');

      if (response.data) {
        setCategoryOptions(response.data);
      }
      // eslint-disable-next-line
    } catch (error: any) {
      // resetFormRegister();
      let errorMessage =
        'Falha ao realizar o cadastro, tente novamente mais tarde';
      if (error.response && error.response.data) {
        errorMessage =
          typeof error.response.data === 'string'
            ? error.response.data
            : JSON.stringify(error.response.data);
      }
      toast.error(errorMessage);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleSaveCategory = async (
    section: string,
    data: Partial<Product>
  ): Promise<void> => {
    const newCategories: string[] = [];
    let isCreateCategories = false;
    let isCreateTags = false;
    const newTags: string[] = [];

    console.log(data);

    if (data.category && data.category.length > 0) {
      data.category.forEach((category) => {
        console.log({ category });
        console.log({ selectedCategories });
        if ((selectedCategories ?? []).includes(category)) {
          newCategories.push(category.id);
          isCreateCategories = true;
        }
      });
    }

    data.tags?.forEach((tag) => {
      if (!(tags ?? []).includes(tag)) {
        isCreateTags = true;
        newTags.push(tag);
      }
    });

    if (isCreateCategories) {
      try {
        const payload = {
          productId: id,
          categoryId: newCategories,
        };
        console.log('Payload para salvar categoria:', payload);
        const response = await api.post('/product/update-categorie', payload);
        if (response.status === 200) {
          toast.success('Categoria salva com sucesso!');
          //getProductEdit();
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        let errorMessage =
          'Falha ao salvar a categoria, tente novamente mais tarde';
        if (error.response && error.response.data) {
          errorMessage =
            typeof error.response.data === 'string'
              ? error.response.data
              : JSON.stringify(error.response.data);
        }
        toast.error(errorMessage);
      } finally {
        dispatch(setLoading(false));
      }
    }

    if (isCreateTags) {
      try {
        const payload = {
          productID: id,
          tag: newTags,
        };
        console.log('Payload para salvar tags:', payload);
        const response = await api.post('/product/update-tags', payload);

        if (response.status === 200) {
          toast.success('Tags salvas com sucesso!');
          getProductEdit();
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        let errorMessage =
          'Falha ao salvar as tags, tente novamente mais tarde';
        if (error.response && error.response.data) {
          errorMessage =
            typeof error.response.data === 'string'
              ? error.response.data
              : JSON.stringify(error.response.data);
        }
        toast.error(errorMessage);
      } finally {
        dispatch(setLoading(false));
      }
    }
  };

  const handleSaveSection = async (
    section: string,
    data?: Partial<Product>
  ): Promise<void> => {
    try {
      dispatch(setLoading(true));

      if (section === 'basicInfo' && data) {
        console.log(`Salvando seção ${section}`, data);

        const payload: EditProductBasic = {
          id: id,
          name: data.name ? data.name : formData.name,
          description: data.description
            ? data.description
            : formData.description,
          sku: data.sku ? data.sku : formData.sku,
          promotion: data.promotion ? data.promotion : formData.promotion,
          freeShipping: data.freeShipping
            ? data.freeShipping
            : formData.freeShipping,
          status: data.status ? data.status : formData.status,
          branding: data.brand?.name ? data.brand.name : formData.brand.name,
          imagens:
            data.imagens && data.imagens.length > 0
              ? data.imagens
              : formData.imagens,
        };

        console.log('Payload produto', payload);

        const response = await api.put(
          '/product/update-product-basic',
          payload
        );
        console.log(response);
        getProductEdit();

        toast.success('Informações Básicas salvas com sucesso!');
      }

      if (section === 'valuesVariations') {
        console.log('Salvando grade', grade);

        for (const item of grade) {
          const gradeExists = dataProduct.grade?.find(
            (itx) => itx.id === item.id
          );

          if (!gradeExists) {
            const payload = {
              productID: id,
              grades: [item],
            };

            const response = await api.put('/product/create-grade', payload);
            console.log(response);

            getProductEdit();

            toast.success('Grade salva com sucesso!');
          }
        }
      }
    } catch (error) {
      console.error('Erro ao salvar', error);

      toast.error('Erro ao salvar os dados');
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleSaveImages = async () => {
    if (filesImage.length === 0) {
      return;
    }

    try {
      dispatch(setLoading(true));

      const convertToBase64 = (
        file: File
      ): Promise<string | ArrayBuffer | null> => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = (error) => reject(error);
        });
      };

      const base64Images = await Promise.all(
        filesImage.map((file: File) => convertToBase64(file))
      );

      const payload = {
        productID: id,
        images: base64Images,
      };

      console.log(payload);

      const response = await api.put('/product/update-imagens', payload);
      console.log(response);

      toast.success('Imagens atualizadas com sucesso!');

      getProductEdit();
    } catch (error) {
      console.log(error);
      toast.error('Erro ao atualizar imagens');
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleSelectedCaegories = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedId = e.target.value;

    if (!selectedId) return;

    const selectedCategory = categoryOptions.find(
      (cat) => cat.id === selectedId
    );

    console.log({ selectedCategory });

    if (selectedCategory) {
      console.log('Entrou aqui');
      setSelectedCategories([...selectedCategories, selectedCategory]);
      console.log(selectedCategories);
      // setSelectedCategories(prev => {

      //   if (!prev.some(cat => cat.id === selectedId)) {
      //     console.log({prev, selectedId})
      //     return [...prev, selectedCategory];
      //   }
      //   return prev;
      // });
    }
  };

  const handleSaveWarrantyDimensions = async () => {
    if (
      !formData.warranty &&
      (!formData.dimensions || Object.keys(formData.dimensions).length === 0)
    ) {
      return;
    }

    try {
      dispatch(setLoading(true));

      console.log(formData);

      if (formData.warrantyValue) {
        const warrantyPayload = {
          id: formData.warranty.id,
          warranty: formData.warrantyValue,
        };

        console.log(warrantyPayload);

        const responseWarranty = await api.put(
          '/product/update-warranty',
          warrantyPayload
        );

        if (
          responseWarranty.status === 200 ||
          responseWarranty.status === 201
        ) {
          toast.success('Garantia e dimensões atualizadas com sucesso!');
        }
      }

      if (
        formData.dimensionsWidth ||
        formData.dimensionsHeight ||
        formData.dimensionsDepth ||
        formData.dimensionsWeight
      ) {
        const dimensionsPayload = {
          id: formData.dimensions.id,
          width: formData.dimensionsWidth,
          height: formData.dimensionsHeight,
          depth: formData.dimensionsDepth,
          weight: formData.dimensionsWeight,
        };
        console.log(dimensionsPayload);
        const responseDimensions = await api.put(
          '/product/update-dimensions',
          dimensionsPayload
        );

        if (
          responseDimensions.status === 200 ||
          responseDimensions.status === 201
        ) {
          toast.success('Garantia e dimensões atualizadas com sucesso!');
        }
      }
      getProductEdit();
    } catch (error) {
      console.log(error);
      toast.error('Erro ao atualizar garantia e dimensões');
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleOnRemoveCategory = async (categoryId: string): Promise<void> => {
    try {
      dispatch(setLoading(true));
      console.log(`Salvando seção ${categoryId} / `, id);
      const response = await api.get(
        `/product/delete-category/${categoryId}/${id}`
      );
      if (response.status === 200) {
        toast.success('Categoria excluida com sucesso');
        setSelectedCategories((prev) =>
          prev.filter((cat) => cat.id !== categoryId)
        );
        //getCategory();
      }
    } catch (error) {
      console.error('Erro ao excluir categoria:', error);
      toast.error('Algo deu Errado');
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleRemoveTag = async (tag: string): Promise<void> => {
    try {
      dispatch(setLoading(true));
      console.log(`Salvando seção ${tag} / `, id);
      const response = await api.delete(`/product/delete-tags/${tag}/${id}`);
      if (response.status === 200) {
        toast.success('Tag excluida com sucesso');
        getProductEdit();
      }
    } catch (error) {
      console.error('Erro ao excluir categoria:', error);
      toast.error('Algo deu Errado');
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleInputChange = (field: string) => (value: string) => {
    const keys = field.split('.'); // Divide o campo em chaves, por exemplo: 'dimensions.width'
    setFormData((prevData) => {
      const updatedData = { ...prevData };

      // Atualiza o campo específico com base no array de chaves
      keys.reduce((acc, key, index) => {
        if (index === keys.length - 1) {
          acc[key] = value; // Atribui o valor final
        } else {
          acc[key] = acc[key] || {}; // Cria um objeto caso ainda não exista
        }
        return acc[key];
      }, updatedData);

      return updatedData;
    });
  };

  const onChangeFormInput = (inputName: string) => (value: string | number) => {
    console.log('entrou aqui', inputName, value);
    setFormData((prev) => {
      const updatedData = { ...prev, [inputName]: value };
      console.log('Novo formData:', updatedData);
      return updatedData;
    });
  };

  useEffect(() => {
    getProductEdit();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getCategory();
    // eslint-disable-next-line
  }, []);

  return (
    <EditProductUI
      formData={formData}
      formErrors={{}}
      categoryOptions={categoryOptions}
      selectedCategories={selectedCategories}
      inputTags={inputTags}
      filesImage={filesImage}
      previewFiles={previewFiles}
      isGrade={isGrade}
      grade={grade}
      filesImageGrade={[]}
      previewFilesGrade={previewFilesGrade}
      previewGradesSizes={previewGradesSizes}
      shippingCost={shippingCost}
      FileUpload={(e) => {
        const newFiles = Array.from(e.target.files || []);
        setFilesImage(newFiles);
        setPreviewFiles(newFiles.map((file) => URL.createObjectURL(file)));
      }}
      onDeleteFile={async (index) => {
        const imageUrl = previewFiles[index];
        if (!imageUrl) {
          return;
        }

        try {
          await api.delete(`/product/delete-image/${imageUrl}`);

          setFilesImage((prev) => {
            return prev.filter((_, i) => i !== index);
          });

          setPreviewFiles((prev) => {
            return prev.filter((_, i) => i !== index);
          });
        } catch (error) {
          console.log(error);
        }
      }}
      addQuantiValue={(e) => {
        e.preventDefault();
        const newGrade: Grade = {
          color: formData[FormInputGrade.color] || '',
          name: formData[FormInputGrade.nameGrade] || '',
          image: filesBase64Grade || '',
          gradeSizes: gradeSize?.length ? gradeSize : [],
        };

        const isDuplicate = grade.some((item) => {
          const isSameColor =
            item.color?.toLowerCase() === newGrade.color?.toLowerCase();
          const isSameName =
            item.name &&
            item.name.toLowerCase() === newGrade.name?.toLowerCase();
          setFilesBase64Grade('');
          return isSameColor || isSameName;
        });

        if (isDuplicate) {
          toast.error('Já existe uma variação com esta cor ou nome.');
          return;
        }

        if (!newGrade) {
          toast.error('Por favor, preencha a grade.');
          return;
        }

        if (newGrade.color === '') {
          if (newGrade.name === '') {
            toast.error('Por favor, preencha a grade com nome');
            return;
          }
        }

        if (newGrade && gradeSize.length > 0) {
          setGrade([...grade, newGrade]);
          onChangeFormInput(FormInputGrade.color)('');
          onChangeFormInput(FormInputGrade.nameGrade)('');
          onChangeFormInput(FormInputGrade.imageBase64Grade)('');
          setGradeSize([]);
          setPreviewGradesSizes([]);
          setPreviewFilesGrade('');
        }
      }}
      removeGrade={() => {}}
      addSize={(e) => {
        e.preventDefault();

        const newGradeSize: GradeSize = {
          size: formData[FormInputGradeSize.size],
          stock: formData[FormInputGradeSize.stock],
          price: {
            price: Number(formData[FormInputGradeSize.price]),
            discount: parseInt(formData[FormInputGradeSize.discount]),
          },
        };
        if (newGradeSize && !inputTags.includes(newGradeSize.size.toString())) {
          setGradeSize([...gradeSize, newGradeSize]);
          onChangeFormInput(FormInputGradeSize.size)('');
          onChangeFormInput(FormInputGradeSize.stock)('');
          onChangeFormInput(FormInputGradeSize.price)('');
          onChangeFormInput(FormInputGradeSize.discount)('');
        }

        setPreviewGradesSizes([...previewGradesSizes, newGradeSize]);
      }}
      onChangeFormInput={onChangeFormInput}
      handleInputChange={handleInputChange}
      onSelectCategory={handleSelectedCaegories}
      onRemoveCategory={handleOnRemoveCategory}
      onAddTag={(tag) =>
        setInputTags((prev) => {
          if (prev === null) {
            return [tag];
          }
          return [...prev, tag];
        })
      }
      onRemoveTag={handleRemoveTag}
      onGrade={() => () => {}}
      onChangeFormFileInputGradeSize={(e) => {
        if (e.target.files && e.target.files.length > 0) {
          const file = e.target.files[0];
          setPreviewFilesGrade(URL.createObjectURL(file));
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => {
            const base64 = reader.result?.toString();
            if (base64) {
              setFilesBase64Grade(base64);
            }
          };
        }
      }}
      onShippingCalc={() => {}}
      onSaveSection={handleSaveSection}
      onSaveCategory={handleSaveCategory}
      handleSaveImages={handleSaveImages}
      onSaveWarrantyDimensions={handleSaveWarrantyDimensions}
    />
  );
};
