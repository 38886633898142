'use client';

import type React from 'react';
import { InputText } from 'src/components/InputText';
import { Select } from 'src/components/Select';
import { FormRegisterProduct } from 'src/features/createProduct/types/types';
import type { Product } from 'src/model/Product';

interface BasicInfoProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formData: any;
  formErrors: Partial<Record<keyof Product, string[]>>;
  onChangeFormInput: (inputName: string) => (value: string) => void;
}

const statusOptions = [
  { value: 'ACTIVE', label: 'Ativo' },
  { value: 'INACTIVE', label: 'Inativo' },
  { value: 'DISCONTINUED', label: 'Estoque Zerado' },
];

const BasicInfo: React.FC<BasicInfoProps> = ({
  formData,
  formErrors,
  onChangeFormInput,
}) => {
  return (
    <div className="bg-white p-5 rounded-lg border border-gray-100 shadow-sm">
      <h2 className="text-lg font-semibold mb-5 text-gray-800">
        Informações Básicas
      </h2>

      <div className="space-y-4">
        <div>
          <InputText
            name="name"
            label="Nome do Produto *"
            placeholder="Digite o nome do produto..."
            value={formData[FormRegisterProduct.name]}
            onChange={(e) =>
              onChangeFormInput(FormRegisterProduct.name)(e.target.value)
            }
            error={formErrors.name && formErrors.name[0]}
            inputClassName="rounded-lg p-2.5 w-full bg-gray-50 border border-gray-200 focus:border-primary focus:ring-1 focus:ring-primary transition-colors"
          />
        </div>

        <div>
          <InputText
            name="description"
            label="Descrição *"
            placeholder="Descreva o produto..."
            value={formData[FormRegisterProduct.description]}
            onChange={(e) =>
              onChangeFormInput(FormRegisterProduct.description)(e.target.value)
            }
            error={formErrors.description && formErrors.description[0]}
            inputClassName="rounded-lg p-2.5 w-full bg-gray-50 border border-gray-200 focus:border-primary focus:ring-1 focus:ring-primary transition-colors"
          />
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <InputText
              name="sku"
              label="SKU *"
              placeholder="Digite o código do SKU..."
              value={formData[FormRegisterProduct.sku]}
              onChange={(e) =>
                onChangeFormInput(FormRegisterProduct.sku)(e.target.value)
              }
              error={formErrors.sku && formErrors.sku[0]}
              inputClassName="rounded-lg p-2.5 w-full bg-gray-50 border border-gray-200 focus:border-primary focus:ring-1 focus:ring-primary transition-colors"
            />
          </div>

          <div>
            <InputText
              name="brand"
              label="Marca *"
              placeholder="Digite a marca do produto..."
              value={formData.brand?.name || ''}
              onChange={(e) =>
                onChangeFormInput('brand')({
                  ...formData.brand,
                  name: e.target.value,
                })
              }
              error={formErrors.brand && formErrors.brand[0]}
              inputClassName="rounded-lg p-2.5 w-full bg-gray-50 border border-gray-200 focus:border-primary focus:ring-1 focus:ring-primary transition-colors"
            />
          </div>
        </div>

        <div className="max-w-md">
          <Select
            name="status"
            label="Status *"
            value={formData[FormRegisterProduct.status]}
            onChange={(e) =>
              onChangeFormInput(FormRegisterProduct.status)(e.target.value)
            }
            error={formErrors.status && formErrors.status[0]}
            options={statusOptions}
            selectClassName="rounded-lg p-2.5 w-full bg-gray-50 border border-gray-200 focus:border-primary focus:ring-1 focus:ring-primary transition-colors"
          />
        </div>
      </div>
    </div>
  );
};

export default BasicInfo;
