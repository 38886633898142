import React, { useEffect, useState } from 'react';
import { Product } from 'src/model/Product';
import { Category } from 'src/model/Category';
import { SidebarWithProductsUI } from './ui';

interface SidebarProductScreenProps {
  products: Product[];
  valuePrice: (product: Product, discount: boolean) => string;
}

export const SidebarProductScreen: React.FC<SidebarProductScreenProps> = ({
  products,
  valuePrice,
}) => {
  const [categories, setCategories] = useState<Category[]>([]);
  const [activeCategoryId, setActiveCategoryId] = useState<string | null>(
    'all'
  );
  const [filteredProducts, setFilteredProducts] = useState<Product[]>(products);

  useEffect(() => {
    const allCategories = products
      .flatMap((product) => product.category)
      .filter(
        (category, index, self) =>
          self.findIndex((c) => c.id === category.id) === index
      );
    setCategories([{ id: 'all', name: 'Todos' }, ...allCategories]);
  }, [products]);

  useEffect(() => {
    const filtrado: Product[] = [];

    if (activeCategoryId === 'all' || !activeCategoryId) {
      setFilteredProducts(products);
    } else {
      for (const produto of products) {
        if (!produto.category) continue;

        for (const categoria of produto.category) {
          if (categoria.id === activeCategoryId) {
            filtrado.push(produto);
            break;
          }
        }
      }
      setFilteredProducts(filtrado);
    }
  }, [activeCategoryId, products]);

  const handleCategorySelect = (categoryId: string | null) => {
    setActiveCategoryId(categoryId);
  };

  return (
    <SidebarWithProductsUI
      categories={{
        categories,
        activeCategoryId,
        onCategorySelect: handleCategorySelect,
      }}
      products={{ products: filteredProducts }}
      valuePrice={valuePrice}
    />
  );
};
