import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { Grade } from 'src/model/Grade';
import { Product } from 'src/model/Product';
import { setLoading } from 'src/redux/loading/loadingSlice';
import { api } from 'src/services/api';
import { ProductManagerUI } from './ui';
import { Modal } from 'src/components/Modal';
import { EditProductScreen } from '../EditProduct';

export const ProductManagerScreen: React.FC = () => {
  const dispatch = useDispatch();
  const [products, setProducts] = useState<Product[]>([]);
  const [selectedProduct, setSelectedProduct] = useState<string>('');
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Função para abrir o modal e setar o produto selecionado
  const handleEdit = async (id: string) => {
    //   const response = await api.get<Product>(`/product/${id}`);
    console.log(id);

    if (id !== '' && id !== undefined) {
      setSelectedProduct(id);
      setIsModalOpen(true);
    }
  };

  const getProduct = async (): Promise<void> => {
    dispatch(setLoading(true));
    try {
      const response = await api.get<Product[]>('/product/find-store');
      if (response.status === 200) {
        console.log(response.data);
        setProducts(response.data);
      }
    } catch (error) {
      console.error('Erro ao buscar produtos:', error);
    } finally {
      dispatch(setLoading(false));
    }
  };

  // Função para deletar um produto
  const handleDelete = async (id: string): Promise<void> => {
    dispatch(setLoading(true));
    try {
      await api.get(`/product/delete/${id}`);
      getProduct();
      toast.success('Produto deletado com sucesso');
    } catch (error) {
      console.error('Erro ao deletar produto:', error);
      toast.error('Erro ao deletar o produto');
    } finally {
      dispatch(setLoading(false));
    }
  };

  // Função para calcular o estoque total de um produto
  const handleStock = (stock: Grade[]): number => {
    let total = 0;
    stock.forEach((grade) => {
      grade.gradeSizes.forEach((size) => {
        total += parseInt(size.stock);
      });
    });
    return total;
  };

  // Fecha o modal e reseta o produto selecionado
  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedProduct('');
  };

  useEffect(() => {
    getProduct();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ProductManagerUI
        products={products}
        handleEdit={handleEdit}
        handleDelete={handleDelete}
        handleStock={handleStock}
      />

      <Modal
        visible={isModalOpen}
        onSetVisible={handleCloseModal}
        //title="Editar Produto"
      >
        {selectedProduct !== '' && <EditProductScreen id={selectedProduct} />}
      </Modal>
    </>
  );
};
