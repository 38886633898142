import React from 'react';
import {
  amex,
  boleto,
  elo,
  master,
  pix,
  visa,
} from '../../../../assets/images/payments-img';

interface ListMethodsPaymentsProps {
  methods?: { name: string; image: string }[];
}

export const ListMethodsPayments: React.FC<ListMethodsPaymentsProps> = () => {
  const methods = [
    { name: 'Amex', image: amex },
    { name: 'Boleto', image: boleto },
    { name: 'Elo', image: elo },
    { name: 'Master', image: master },
    { name: 'Pix', image: pix },
    { name: 'Visa', image: visa },
  ];

  return (
    <div className="grid grid-cols-3 gap-2 sm:grid-cols-3 md:grid-cols-3">
      {methods.map((method) => (
        <div key={method.name} className="flex items-center justify-center">
          <img
            src={method.image}
            alt={method.name}
            className="w-16 h-16 object-contain"
          />
        </div>
      ))}
    </div>
  );
};
