// Logic Component
import React from 'react';
import { NotificationComponentUI } from './ui';
import { AlertMessage } from 'src/model/AlertMessage';

interface NotificationProps {
  alertMessage: AlertMessage[];
  variant: 'dropdown' | 'page';
}

export const NotificationComponentScreen: React.FC<NotificationProps> = ({
  alertMessage,
  variant,
}) => {
  return (
    <NotificationComponentUI notifications={alertMessage} variant={variant} />
  );
};
