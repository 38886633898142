import React, { useState } from 'react';

interface Carousel {
  id: number;
  imageUrl: string;
  altText: string;
}

interface CarouselProps {
  carousel: Carousel[];
}

export const Carousel: React.FC<CarouselProps> = ({ carousel }) => {
  const [currentIndex, setCurrentIndex] = useState<number>(0);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % carousel.length);
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? carousel.length - 1 : prevIndex - 1
    );
  };

  return (
    <div className="relative min-w-[80%] h-96 overflow-hidden rounded-b-lg ">
      <div
        className="flex transition-transform duration-500 ease-in-out"
        style={{ transform: `translateX(-${currentIndex * 100}%)` }}
      >
        {carousel.map((banner) => (
          <div
            key={banner.id}
            className="w-full flex justify-center items-center  flex-shrink-0"
          >
            <img
              src={banner.imageUrl}
              alt={banner.altText}
              className="w-full h-auto object-contain "
            />
          </div>
        ))}
      </div>

      {/* Controles do slider */}
      <button
        onClick={handlePrev}
        className="absolute left-0 top-1/2 transform -translate-y-1/2 bg-black bg-opacity-50 text-white p-2 m-2 w-10 rounded-full"
      >
        &lt;
      </button>
      <button
        onClick={handleNext}
        className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-black bg-opacity-50 text-white p-2 m-2 w-10 rounded-full"
      >
        &gt;
      </button>

      {/* Indicadores */}
      <div className="absolute bottom-2 left-1/2 transform -translate-x-1/2 flex space-x-2">
        {carousel.map((_, index) => (
          <span
            key={index}
            className={`block w-3 h-3 rounded-full ${index === currentIndex ? 'bg-white' : 'bg-gray-400'}`}
          />
        ))}
      </div>
    </div>
  );
};
