import type React from 'react';
import CategorySelector from 'src/features/createProduct/screens/CreateProduct/components/CategorySelect';
import InputTags from 'src/features/createProduct/screens/CreateProduct/components/InputsTags';

interface CategoriesTagsProps {
  // eslint-disable-next-line
  categoryOptions: any[];
  // eslint-disable-next-line
  selectedCategories: any[];
  inputTags: string[];
  onSelectCategory: (e: React.FocusEvent<HTMLSelectElement>) => void;
  onRemoveCategory: (value: string) => void;
  onAddTag: (tag: string) => void;
  onRemoveTag: (value: string) => void;
}

const CategoriesTags: React.FC<CategoriesTagsProps> = ({
  categoryOptions,
  selectedCategories,
  inputTags,
  onSelectCategory,
  onRemoveCategory,
  onAddTag,
  onRemoveTag,
}) => {
  return (
    <div className="bg-white p-5 rounded-lg border border-gray-100 shadow-sm">
      <h2 className="text-lg font-semibold mb-5 text-gray-800">
        Categorias e Tags
      </h2>

      <div className="space-y-6">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Categorias
          </label>
          <CategorySelector
            categorys={categoryOptions}
            selectedCategories={selectedCategories}
            selectCategory={(e) => onSelectCategory(e)}
            removeCategory={(value) => onRemoveCategory(value)}
            error={
              selectedCategories.length === 0
                ? 'Selecione pelo menos uma Categoria'
                : null
            }
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Tags
          </label>
          <InputTags
            tags={inputTags}
            addTag={(tag) => onAddTag(tag)}
            removeTag={(value) => onRemoveTag(value)}
            error={
              inputTags.length === 0 ? 'Adicione pelo menos uma tag.' : null
            }
            inputClassName="rounded-lg p-2.5 w-full bg-gray-50 border border-gray-200 focus:border-primary focus:ring-1 focus:ring-primary transition-colors"
          />
        </div>
      </div>
    </div>
  );
};

export default CategoriesTags;
