import { Brand } from 'src/model/Brand';
import { Category } from 'src/model/Category';
import { Dimensions } from 'src/model/Dimensions';
import { Grade } from 'src/model/Grade';
import { ProductStatus } from 'src/model/ProductStatus';

import { Warranty } from 'src/model/Warranty';

export enum FormRegisterProduct {
  name = 'name',
  description = 'description',
  stock = 'stock',
  price = 'price',
  priceDiscount = 'priceDiscount',
  sku = 'sku',
  status = 'status',
  promotion = 'promotion',
  brandName = 'brandName',
  isGrade = 'isGrade',
  dimensionsWidth = 'dimensionsWidth',
  dimensionsHeight = 'dimensionsHeight',
  dimensionsDepth = 'dimensionsDepth',
  dimensionsWeight = 'dimensionsWeight',
  discount = 'discount',
  parcel = 'parcel',
  warranty = 'warranty',
  tag = 'tag',
  freeShipping = 'freeShipping',
}

export interface ProductBasic {
  name: string;
  description: string;
  stock: string;
  sku: string;
  promotion: boolean;
  status: ProductStatus;
  category: Category[];
  brand: Brand;
  dimensions: Dimensions;
  imagens: string[];
  tags: string[];
  warranty: Warranty;
  grade: Grade[];
  freeShipping: boolean;
}

export enum FormInputGrade {
  nameGrade = 'name',
  color = 'color',
  imageBase64Grade = 'image',
}

export enum FormInputGradeSize {
  size = 'size',
  stock = 'stock',
  price = 'price',
  discount = 'discount',
}

export interface EditProductBasic {
  id: string;
  name: string;
  description: string;
  sku: string;
  promotion: boolean;
  freeShipping: boolean;
  status: ProductStatus;
  branding: string;
  imagens: string[];
}
