import React from 'react';
import { Accordion, AccordionItem } from 'src/components/Accordion';
import { Button } from 'src/components/Button';
import { Category } from 'src/model/Category';
import { Grade } from 'src/model/Grade';
import { GradeSize } from 'src/model/GradeSize';
import { Product } from 'src/model/Product';
import BasicInfo from '../components/BasicInfo';
import CategoriesTags from '../components/CategoriesTags';
import { UploadImagens } from '../components/UploadImagens';
import ValuesVariations from '../components/ValuesVariations';
import { WarrantyShippingDimensions } from '../components/WarrantyShippingDimensions';

interface EditProductUIProps {
  formData: Product;
  formErrors: Record<string, string[]>;
  categoryOptions: Category[];
  selectedCategories: Category[];
  inputTags: string[];
  filesImage: File[];
  previewFiles: string[];
  isGrade: boolean;
  grade: Grade[];
  filesImageGrade: File[];
  previewFilesGrade: string;
  previewGradesSizes: GradeSize[];
  shippingCost: string | null;
  FileUpload: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onDeleteFile: (index: number) => void;
  addQuantiValue: (e: React.FormEvent) => void;
  removeGrade: (index: number) => void;
  addSize: (e: React.FormEvent) => void;
  onChangeFormInput: (inputName: string) => (value: string) => void;
  onSelectCategory: (e: React.FocusEvent<HTMLSelectElement>) => void;
  onRemoveCategory: (value: string) => void;
  onAddTag: (tag: string) => void;
  onRemoveTag: (value: string) => void;
  onGrade: (inputName: string) => (value: string) => void;
  onChangeFormFileInputGradeSize: (
    e: React.ChangeEvent<HTMLInputElement>
  ) => void;
  onShippingCalc: (
    height: string,
    width: string,
    depth: string,
    weight: string
  ) => void;
  onSaveSection: (section: string, data: Partial<Product>) => void;
  onSaveCategory: (section: string, data: Partial<Product>) => void;
  handleSaveImages: () => void;
  onSaveWarrantyDimensions: () => void;
  handleInputChange: (field: string) => (value: string) => void;
}

const EditProductUI: React.FC<EditProductUIProps> = ({
  formData,
  formErrors,
  categoryOptions,
  selectedCategories,
  inputTags = [],
  filesImage,
  previewFiles,
  FileUpload,
  onDeleteFile,
  isGrade,
  grade,
  addQuantiValue,
  removeGrade,
  addSize,
  onChangeFormInput,
  onSelectCategory,
  onRemoveCategory,
  onAddTag,
  onRemoveTag,
  onGrade,
  onChangeFormFileInputGradeSize,
  filesImageGrade,
  previewFilesGrade,
  previewGradesSizes,
  onShippingCalc,
  shippingCost,
  onSaveSection,
  onSaveCategory,
  handleSaveImages,
  onSaveWarrantyDimensions,
}): JSX.Element => {
  return (
    <section className="container mx-auto p-4 bg-white rounded-lg shadow">
      <h1 className="text-2xl font-bold text-center mb-6">
        Editar Produto - {formData.name}
      </h1>

      <Accordion type="single">
        <AccordionItem
          value="basicInfo"
          title="Informações Básicas"
          icon={<span>📝</span>}
        >
          <BasicInfo
            formData={formData}
            formErrors={formErrors}
            onChangeFormInput={onChangeFormInput}
          />
          <Button
            onClick={() =>
              onSaveSection('basicInfo', {
                name: formData.name,
                description: formData.description,
                sku: formData.sku,
                brand: formData.brand,
              })
            }
            buttonStyle="red"
            className="mt-4"
            title="Salvar Alterações"
          />
        </AccordionItem>

        <AccordionItem
          value="categoriesTags"
          title="Categorias e Tags"
          icon={<span>🏷️</span>}
        >
          <CategoriesTags
            categoryOptions={categoryOptions}
            selectedCategories={selectedCategories}
            inputTags={inputTags ?? []}
            onSelectCategory={onSelectCategory}
            onRemoveCategory={onRemoveCategory}
            onAddTag={onAddTag}
            onRemoveTag={onRemoveTag}
          />
          <Button
            onClick={() =>
              onSaveCategory('categoriesTags', {
                category: selectedCategories,
                tags: inputTags,
              })
            }
            buttonStyle="red"
            className="mt-4"
            title="Salvar Alterações"
          />
        </AccordionItem>

        <AccordionItem
          value="valuesVariations"
          title="Valores e Variações"
          icon={<span>💲</span>}
        >
          <ValuesVariations
            product={formData}
            formErrors={formErrors}
            isGrade={isGrade}
            grade={grade}
            onChangeFormInput={onChangeFormInput}
            addQuantiValue={addQuantiValue}
            removeGrade={removeGrade}
            addSize={addSize}
            onGrade={onGrade}
            onChangeFormFileInputGradeSize={onChangeFormFileInputGradeSize}
            filesImageGrade={filesImageGrade}
            previewFilesGrade={previewFilesGrade}
            previewGradesSizes={previewGradesSizes}
          />
          <Button
            onClick={() =>
              onSaveSection('valuesVariations', {
                price: formData.price,
                stock: formData.stock,
              })
            }
            buttonStyle="red"
            className="mt-4"
            title="Salvar Alterações"
          />
        </AccordionItem>

        {/* Garantia, Frete e Dimensões */}
        <AccordionItem
          value="warrantyShippingDimensions"
          title="Garantia, Frete e Dimensões"
          icon={<span>📦</span>}
        >
          <WarrantyShippingDimensions
            formData={formData}
            formErrors={formErrors}
            onChangeFormInput={onChangeFormInput}
            onShippingCalc={onShippingCalc}
            shippingCost={shippingCost}
          />
          <Button
            onClick={onSaveWarrantyDimensions}
            buttonStyle="red"
            className="mt-4"
            title="Salvar Alterações"
          />
        </AccordionItem>

        <AccordionItem
          value="uploadImages"
          title="Upload de Imagens"
          icon={<span>📸</span>}
        >
          <UploadImagens
            filesImage={filesImage}
            previewFiles={previewFiles}
            FileUpload={FileUpload}
            onDeleteFile={onDeleteFile}
          />
          <Button
            onClick={handleSaveImages}
            buttonStyle="red"
            className="mt-4"
            title="Salvar Alterações"
          />
        </AccordionItem>
      </Accordion>
    </section>
  );
};

export default EditProductUI;
