'use client';

import type { FormEvent } from 'react';
import type CardDb from 'src/model/CardDb';
import { Button } from '../../../../../components/Button';
import { InputText } from '../../../../../components/InputText';
import { FormInputNameCreditCard } from '../../../../../features/userProfile/types';
import type { UseFormReturn } from '../../../../../hooks';

interface AddCreditCardUIProps
  extends Pick<UseFormReturn, 'formData' | 'formErrors' | 'onChangeFormInput'> {
  onSubmitRegister: (e: FormEvent) => void;
  cards: CardDb[];
  onDeleteCard: (id: string) => void;
}

export const CreditCardPaymentAddUI = ({
  formData,
  formErrors,
  cards,
  onChangeFormInput,
  onSubmitRegister,
  onDeleteCard,
}: AddCreditCardUIProps) => {
  return (
    <div className="w-full max-w-7xl mx-auto px-4 py-6">
      <div className="flex flex-col lg:flex-row gap-8">
        {/* Formulário de Adição de Cartão */}
        <div className="w-full lg:w-1/2 bg-white rounded-xl shadow-sm p-6">
          <h2 className="text-xl font-bold mb-6 text-gray-800">
            Adicionar Novo Cartão
          </h2>

          <form onSubmit={onSubmitRegister} className="flex flex-col space-y-5">
            <div className="w-full">
              <InputText
                name="number"
                label="Número do cartão"
                placeholder="1234 5678 9012 3456"
                value={formData[FormInputNameCreditCard.number]}
                onChange={(e) =>
                  onChangeFormInput(FormInputNameCreditCard.number)(
                    e.target.value
                  )
                }
                error={formErrors.number && formErrors.number[0]}
                inputClassName="rounded-lg p-3 w-full bg-[#fafafa] shadow-inner border border-gray-200 focus:border-red-400 focus:ring-1 focus:ring-red-400 transition-all"
              />
            </div>

            <div className="w-full grid grid-cols-1 sm:grid-cols-2 gap-4">
              <InputText
                name="date"
                label="Data de vencimento"
                placeholder="MM/AA"
                value={formData[FormInputNameCreditCard.date]}
                onChange={(e) =>
                  onChangeFormInput(FormInputNameCreditCard.date)(
                    e.target.value
                  )
                }
                maxLength={5}
                error={formErrors.date && formErrors.date[0]}
                inputClassName="rounded-lg p-3 w-full bg-[#fafafa] shadow-inner border border-gray-200 focus:border-red-400 focus:ring-1 focus:ring-red-400 transition-all"
              />

              <InputText
                name="cvv"
                label="CVV"
                placeholder="000"
                value={formData[FormInputNameCreditCard.cvv]}
                onChange={(e) =>
                  onChangeFormInput(FormInputNameCreditCard.cvv)(e.target.value)
                }
                error={formErrors.cvv && formErrors.cvv[0]}
                inputClassName="rounded-lg p-3 w-full bg-[#fafafa] shadow-inner border border-gray-200 focus:border-red-400 focus:ring-1 focus:ring-red-400 transition-all"
              />
            </div>

            <div className="w-full">
              <InputText
                name="name"
                label="Nome do titular"
                placeholder="Maria da Silva"
                value={formData[FormInputNameCreditCard.name]}
                onChange={(e) =>
                  onChangeFormInput(FormInputNameCreditCard.name)(
                    e.target.value
                  )
                }
                error={formErrors.name && formErrors.name[0]}
                inputClassName="rounded-lg p-3 w-full bg-[#fafafa] shadow-inner border border-gray-200 focus:border-red-400 focus:ring-1 focus:ring-red-400 transition-all"
              />
            </div>

            <div className="w-full">
              <InputText
                name="document"
                label="CPF do titular"
                placeholder="123.456.789-00"
                value={formData[FormInputNameCreditCard.document]}
                onChange={(e) =>
                  onChangeFormInput(FormInputNameCreditCard.document)(
                    e.target.value
                  )
                }
                error={formErrors.document && formErrors.document[0]}
                inputClassName="rounded-lg p-3 w-full bg-[#fafafa] shadow-inner border border-gray-200 focus:border-red-400 focus:ring-1 focus:ring-red-400 transition-all"
              />
            </div>

            <div className="w-full pt-2">
              <Button
                type="submit"
                title="Adicionar cartão"
                buttonStyle="red"
                size="lg"
                className="w-full rounded-lg transition-all duration-200 transform hover:scale-[1.02] active:scale-[0.98]"
                disabled={
                  formData.number === '' ||
                  formData.date === '' ||
                  formData.cvv === '' ||
                  formData.name === '' ||
                  formData.document === ''
                }
              />
            </div>
          </form>
        </div>

        {/* Lista de Cartões */}
        <div className="w-full lg:w-1/2 bg-white rounded-xl shadow-sm p-6">
          <h2 className="text-xl font-bold mb-6 text-gray-800">Seus Cartões</h2>

          <div className="space-y-4 max-h-[500px] overflow-y-auto pr-2">
            {cards.length > 0 ? (
              cards.map((card) => (
                <div
                  key={card.id}
                  className="bg-gradient-to-r from-gray-50 to-gray-100 rounded-lg p-4 shadow-sm border border-gray-200 transition-all duration-200 hover:shadow-md"
                >
                  <div className="flex flex-col sm:flex-row sm:items-center justify-between gap-4">
                    <div className="flex flex-col space-y-2">
                      <p className="font-semibold text-gray-800">
                        {card.cardholderName}
                      </p>
                      <div className="flex items-center">
                        <span className="text-sm bg-white px-2 py-1 rounded border border-gray-200 text-gray-700 font-mono">
                          {card.exibitionNumber}
                        </span>
                        <span className="text-xs text-gray-500 ml-2">
                          Válido até {card.expirationDate}
                        </span>
                      </div>
                    </div>
                    <Button
                      title="Excluir"
                      buttonStyle="red"
                      size="sm"
                      className="self-end sm:self-auto rounded-lg transition-all duration-200 hover:bg-red-600"
                      onClick={() => onDeleteCard(card.id)}
                    />
                  </div>
                </div>
              ))
            ) : (
              <div className="flex flex-col items-center justify-center py-10 text-gray-500 bg-gray-50 rounded-lg">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-12 w-12 text-gray-400 mb-3"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                    d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z"
                  />
                </svg>
                <p className="text-center">Nenhum cartão cadastrado.</p>
                <p className="text-center text-sm mt-1">
                  Adicione seu primeiro cartão no formulário ao lado.
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
