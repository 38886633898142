import React from 'react';
import { FaEdit, FaTrash } from 'react-icons/fa';
import { Product, ProductStatus } from 'src/model/Product';
import { Grade } from 'src/model/Grade';

interface ProductManagerUIProps {
  products: Product[];
  handleEdit: (id: string) => void;
  handleDelete: (id: string) => void;
  handleStock: (stock: Grade[]) => number;
}

export const ProductManagerUI: React.FC<ProductManagerUIProps> = ({
  products,
  handleEdit,
  handleDelete,
  handleStock,
}) => {
  return (
    <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-3 gap-6 m-2">
      {products.map(
        (product) =>
          product.status === ProductStatus.ACTIVE && (
            <div
              key={product.id}
              className="border border-gray-100 rounded-lg shadow-md bg-white relative w-full sm:w-[12rem] md:w-[14rem] lg:w-[14rem]"
            >
              <div className="h-32 md:h-40 bg-white flex items-center justify-center overflow-hidden">
                {product.imagens && product.imagens.length > 0 ? (
                  <img
                    src={product.imagens[0]}
                    alt={product.name}
                    className="md:p-0 p-1 h-full w-full object-contain transform transition-transform duration-300 group-hover:scale-110"
                  />
                ) : (
                  <div className="text-gray-400 text-sm">
                    Imagem indisponível
                  </div>
                )}
              </div>

              <div className="flex flex-col px-3 mt-2">
                <h2
                  className="text-lg font-semibold text-gray-800 leading-tight overflow-hidden"
                  style={{
                    display: '-webkit-box',
                    WebkitBoxOrient: 'vertical',
                    WebkitLineClamp: 2,
                    overflow: 'hidden',
                  }}
                  title={product.name}
                >
                  {product.name}
                </h2>

                <p className="text-sm text-gray-500 mb-1">
                  Estoque:{' '}
                  <span className="text-red-500 font-semibold">
                    {handleStock(product.grade)}
                  </span>
                </p>

                {/* Botões de ação */}
                <div className="w-full flex justify-around items-center space-x-2 my-2">
                  <button
                    onClick={() => handleEdit(product.id)}
                    className="flex items-center text-md text-blue-500 hover:text-blue-600 transition-transform duration-300 transform hover:scale-105"
                  >
                    <FaEdit className="mr-1" /> Editar
                  </button>
                  <button
                    onClick={() => handleDelete(product.id)}
                    className="flex items-center text-md text-red-500 hover:text-red-600 transition-transform duration-300 transform hover:scale-105"
                  >
                    <FaTrash className="mr-1" /> Excluir
                  </button>
                </div>
              </div>
            </div>
          )
      )}
    </div>
  );
};
