import dayjs from 'dayjs';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom'; // Importação do hook de navegação
import { ShowModal } from 'src/components/Modal';
import { AlertMessage } from 'src/model/AlertMessage';

interface NotificationUIProps {
  notifications: AlertMessage[];
  variant: 'dropdown' | 'page'; // Define o contexto de exibição
}

export const NotificationComponentUI: React.FC<NotificationUIProps> = ({
  notifications,
  variant,
}) => {
  const history = useHistory(); // Hook para redirecionamento
  const [selectedNotification, setSelectedNotification] =
    useState<AlertMessage | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Função para abrir o modal com os detalhes da notificação
  const openModal = (notification: AlertMessage) => {
    setSelectedNotification(notification);
    setIsModalOpen(true);
  };

  // Função para redirecionar para a página de notificações
  const goToNotificationsPage = () => {
    history.push('/user-profile?tab=notificacoes'); // Redireciona com um parâmetro de tab ativa
  };

  return (
    <div className="w-full">
      {notifications.map((notification) => (
        <div
          key={notification.id}
          className="border-b border-red-200 bg-white p-3"
        >
          <div className="flex justify-between items-center mb-2 flex-wrap">
            <h3 className="font-semibold text-md">{notification.title}</h3>
            <p className="text-xs text-gray-500">
              {dayjs(notification.dateAt).format('DD/MM/YYYY HH:mm')}
            </p>
          </div>

          {/* Mensagem com limite de 2 linhas */}
          <p className="text-gray-600 text-sm line-clamp-2">
            {notification.message}
          </p>

          {variant === 'dropdown' ? (
            // Se estiver no dropdown, exibe "Ver Notificações"
            <div className="flex justify-end mt-2">
              <button
                onClick={goToNotificationsPage}
                className="text-blue-500 text-sm"
              >
                Ver Notificações
              </button>
            </div>
          ) : (
            // Se estiver na página, exibe "Ver Detalhes" com modal
            <div className="flex justify-end mt-2">
              <button
                className="bg-red-500 text-white px-2 py-1 text-xs rounded"
                onClick={() => openModal(notification)}
              >
                Ver Detalhes
              </button>
            </div>
          )}
        </div>
      ))}

      {/* Modal para exibir detalhes completos da notificação */}
      {selectedNotification && (
        <ShowModal
          visible={isModalOpen}
          onSetVisible={setIsModalOpen}
          title={selectedNotification.title}
          showCloseButton
        >
          <div className="p-4">
            {/* Data da notificação */}
            <p className="text-xs text-gray-500 mb-2">
              {dayjs(selectedNotification.dateAt).format('DD/MM/YYYY HH:mm')}
            </p>

            {/* Mensagem completa sem limitação */}
            <p className="text-gray-600">{selectedNotification.message}</p>
          </div>
        </ShowModal>
      )}
    </div>
  );
};
