import React from 'react';
import { Cart } from '../../../../../../../model/Cart';
import { toCurrency } from 'src/helpers/currency/toCurrency';

interface ProductListUIProps {
  cartsProducts?: Cart[];
}

export const ProductListUI: React.FC<ProductListUIProps> = ({
  cartsProducts,
}) => {
  const lastFiveProducts = cartsProducts ? cartsProducts.slice(-3) : [];
  const remainingProducts = cartsProducts
    ? Math.max(0, cartsProducts.length - 3)
    : 0;
  return (
    <div className="bg-white px-6 py-3 shadow-2xl border rounded-lg w-full">
      <h2 className="text-lg font-semibold mb-2 border-b-2 border-red-200">
        Carrinho
      </h2>

      {lastFiveProducts.length > 0 &&
        lastFiveProducts.map((cart, index) => (
          <div
            key={index}
            className="flex justify-between items-center py-2 border-b border-red-200 min-w-full"
          >
            <div className="flex items-center">
              {cart ? (
                <img
                  src={cart?.products?.imagens?.[0] ?? '/placeholder.jpg'}
                  alt={cart.products.name}
                  className="w-16 h-16 object-cover rounded"
                />
              ) : (
                <div className="w-16 h-16 bg-gray-200 rounded"></div>
              )}
              <div className="flex flex-col ml-3">
                <p className="font-medium">{cart.products.name}</p>
                <div className="flex items-center gap-24">
                  <p className="text-gray-600 text-sm">
                    Quantidade:{' '}
                    <span className="text-secondary">
                      {cart.cartProduct.quantity}
                    </span>
                  </p>
                  <p className="font-semibold text-[#F14A3E] text-end">
                    {toCurrency(cart.cartProduct.grade.price)}
                  </p>
                </div>
              </div>
            </div>
          </div>
        ))}

      <div
        className={`mt-4 text-sm text-gray-600 flex items-center ${remainingProducts > 0 ? 'justify-between' : 'justify-end'}`}
      >
        {remainingProducts > 0 && (
          <p>Mais {`${remainingProducts}`} produto(s) no carrinho</p>
        )}
        <a
          href="/carts"
          className="py-1 px-4 w-fit bg-[#F14A3E] hover:bg-red-600 text-white border border-red-400 rounded-md transition-all duration-300"
        >
          Ver Carrinho
        </a>
      </div>
    </div>
  );
};
