import React from 'react';
import {
  FormData,
  FormErrors,
  OnChangeFormInput,
  UseFormReturn,
} from 'src/hooks';
import CardClient from 'src/model/CardClient';
import { Cart } from 'src/model/Cart';
import { DeliveryOptions } from 'src/model/DeliveryOptions';
import PaymentType from 'src/model/PaymentType';
import Client from 'src/model/User';
import { Summary } from '..';
import { AuthWrapper } from '../../../../../features/core/auth/screen/AuthWrapper';
import { Address } from '../../../../../model/Address';
import { BillingAddressScreen } from '../components/BillingAddress/';
import { OrderSummary } from '../components/OrderSummary';
import { PaymentFormUI } from '../components/PaymentForm';
import { ProductListUI } from '../components/ProductList/ui';
import { CartView } from '../../Cart';

interface CheckoutUIProps
  extends Pick<UseFormReturn, 'formData' | 'formErrors' | 'onChangeFormInput'> {
  addresses: Address[] | undefined;
  selectedAddress?: string;
  carts: Cart[];
  cartView: CartView[];
  user: Client;
  selectedCard?: number;
  addNewCard: boolean;
  deliveryOptions?: DeliveryOptions[];
  summary: Summary;
  selectedCreditCard: boolean;
  selectedPix: boolean;
  discount: number;
  totalAfterDiscount?: number;
  formDataCoupon: FormData;
  formDataCouponErrors: FormErrors;
  onAddCard: () => void;
  onSelectCard: (index: number, cardSelected: CardClient) => void;
  onSubmitRegister: (e: React.FormEvent) => void;
  onSelectAddress: (id: string) => void;
  onPayment: () => void;
  onApplyCoupon: (coupon: string) => void;
  onSelectType: (type: PaymentType) => void;
  onChangeFormCouponInput: OnChangeFormInput;
}

export const CheckoutUI: React.FC<CheckoutUIProps> = ({
  formData,
  formErrors,
  carts,
  user,
  addresses,
  selectedAddress,
  selectedCard,
  addNewCard,
  summary,
  selectedCreditCard,
  selectedPix,
  discount,
  totalAfterDiscount,
  formDataCoupon,
  formDataCouponErrors,
  onAddCard,
  onSelectCard,
  onSubmitRegister,
  onSelectAddress,
  onChangeFormInput,
  onPayment,
  onApplyCoupon,
  onSelectType,
  onChangeFormCouponInput,
}) => {
  const handleReturnToShopping = () => {
    window.location.href = '/';
  };

  return (
    <AuthWrapper>
      <div className="container p-0 m-4  w-full">
        <h2 className="text-xl sm:text-2xl font-semibold mb-4 text-center">
          Dados Para Finalizar Compra
        </h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 grid-rows-1 gap-0 sm:gap-4">
          <div className="w-full space-y-4">
            <BillingAddressScreen
              addresses={addresses}
              onSelectAddress={onSelectAddress}
              selectedAddress={selectedAddress}
            />
            {carts && carts.length > 0 && (
              <ProductListUI cartsProducts={carts} />
            )}
          </div>

          <div className="w-full  space-y-4">
            {/* {deliveryOptions && deliveryOptions.length > 0 && (
              <DeliveryMethodsScreen
                methods={deliveryOptions}
                onApplyCoupon={onApplyCoupon}
              />
            )} */}
            {user && (
              <PaymentFormUI
                addNewCard={addNewCard}
                formData={formData}
                formErrors={formErrors}
                onAddCard={onAddCard}
                onChangeFormInput={onChangeFormInput}
                card={user.cards}
                selectedCard={selectedCard}
                selectedPix={selectedPix}
                onSelectCard={onSelectCard}
                onSubmitRegister={onSubmitRegister}
                onSelectType={onSelectType}
                selectedCreditCard={selectedCreditCard}
              />
            )}
          </div>
        </div>
        <div className="md:w-full  mt-4 ">
          <OrderSummary
            summary={summary}
            onContinueToPayment={onPayment}
            onReturnToShopping={handleReturnToShopping}
            discount={discount}
            fetchCouponDiscount={onApplyCoupon}
            totalAfterDiscount={totalAfterDiscount}
            onChangeFormInput={onChangeFormCouponInput}
            formData={formDataCoupon}
            formErrors={formDataCouponErrors}
          />
        </div>
      </div>
    </AuthWrapper>
  );
};
