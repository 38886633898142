import React from 'react';
import { Route } from 'src/navigation/Route';
import { UserProfileScreen } from './screens';

export const USERPROFILE_ROUTES = {
  itself: '/user-profile',
  onParams: '/user-profile/:id',
};

export const UserProfileNavigation = (): JSX.Element => (
  <React.Fragment>
    <Route
      exact
      path={USERPROFILE_ROUTES.itself}
      component={UserProfileScreen}
      isPrivateRoute={true}
    />
    <Route
      exact
      path={USERPROFILE_ROUTES.onParams}
      component={UserProfileScreen}
      isPrivateRoute={true}
    />
  </React.Fragment>
);
