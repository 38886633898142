import React, { FormEvent } from 'react';
import { Button } from 'src/components/Button';
import { InputFile } from 'src/components/InputFile';
import { InputText } from 'src/components/InputText';
import { NameFiles } from 'src/features/admin/components/Categories';
import { UseFormReturn } from 'src/hooks';
import { FormInputStore } from '../types';

interface StoreProfileManagerUIProps
  extends Pick<UseFormReturn, 'formData' | 'formErrors' | 'onChangeFormInput'> {
  formNameFiles?: NameFiles;
  onSubmitRegister: (e: FormEvent) => void;
  onChangeFormFileInput: (
    inputName: string
  ) => (file: File | undefined) => void;
  selectedStoreImage?: string;
  selectedCoverImage?: string;
  isEditingStoreImage: boolean;
  isEditingCoverImage: boolean;
  onToggleStoreEdit: () => void;
  onToggleCoverEdit: () => void;
  isStore: boolean;
}

export const StoreProfileManagerUI: React.FC<StoreProfileManagerUIProps> = ({
  formData,
  formErrors,
  selectedStoreImage,
  formNameFiles,
  selectedCoverImage,
  isEditingStoreImage,
  isEditingCoverImage,
  isStore,
  onSubmitRegister,
  onChangeFormInput,
  onChangeFormFileInput,
  onToggleStoreEdit,
  onToggleCoverEdit,
}) => {
  const imageRefBanner = React.useRef<HTMLInputElement>(null);
  const imageRefDetail = React.useRef<HTMLInputElement>(null);

  return (
    <>
      <form onSubmit={onSubmitRegister} className="w-full">
        <p className="text-sm text-gray-600 mb-4">
          {isStore
            ? 'Gerenciar e proteger as informações da loja'
            : 'Você ainda não criou uma loja'}
        </p>
        <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-6">
          {/* Coluna 1: Dados da Loja */}
          <div className="w-full">
            <div className="mb-4">
              <InputText
                label="Nome Loja"
                name="storeName"
                value={formData[FormInputStore.name]}
                onChange={(e) =>
                  onChangeFormInput(FormInputStore.name)(e.target.value)
                }
                error={formErrors.name && formErrors.name[0]}
                inputClassName="rounded-lg p-2 w-full bg-[#fafafa] shadow-inner"
                placeholder="Digite o nome da loja"
              />
            </div>

            <div className="mb-4">
              <InputText
                label="E-mail"
                name="storeEmail"
                value={formData[FormInputStore.email]}
                onChange={(e) =>
                  onChangeFormInput(FormInputStore.email)(e.target.value)
                }
                error={formErrors.email && formErrors.email[0]}
                inputClassName="rounded-lg p-2 w-full bg-[#fafafa] shadow-inner"
                placeholder="Digite o e-mail"
              />
            </div>

            <div className="mb-4">
              <InputText
                name="storePhone"
                label="Telefone"
                value={formData[FormInputStore.phone]}
                onChange={(e) =>
                  onChangeFormInput(FormInputStore.phone)(e.target.value)
                }
                error={formErrors.phone && formErrors.phone[0]}
                inputClassName="rounded-lg p-2 w-full bg-[#fafafa] shadow-inner"
                placeholder="Digite o telefone"
              />
            </div>

            <div className="mb-4">
              <InputText
                label="Descrição breve"
                name="storeDescription"
                value={formData[FormInputStore.description]}
                onChange={(e) =>
                  onChangeFormInput(FormInputStore.description)(e.target.value)
                }
                error={formErrors.description && formErrors.description[0]}
                inputClassName="rounded-lg p-2 w-full bg-[#fafafa] shadow-inner"
                placeholder="Descrição da loja"
              />
            </div>
          </div>

          {/* Coluna 2: Upload de Imagens */}
          <div className="w-full flex flex-col justify-center items-center">
            {/* Seção de Foto do Perfil */}
            <div className="flex flex-col items-center justify-center mb-4 w-full">
              <label className="block text-gray-700 mb-2 text-center">
                Foto do Perfil
              </label>
              <div className="relative w-40 h-40 mb-4 items-center">
                {isEditingStoreImage ? (
                  <div className="flex flex-col items-center">
                    <InputFile
                      refInput={imageRefDetail}
                      name="imageDetail"
                      fileName={formNameFiles?.imageDetail}
                      onChange={(e) =>
                        onChangeFormFileInput(FormInputStore.imageDetail)(
                          e.target.files?.[0]
                        )
                      }
                      error={
                        formErrors.imageDetail && formErrors.imageDetail[0]
                      }
                      wrapperClass="file:border file:border-gray-300 file:rounded file:px-4 file:py-2"
                    />
                    <button
                      onClick={onToggleStoreEdit}
                      className="mt-2 bg-red-500 text-white px-4 py-1 rounded-full hover:bg-red-600 text-xs"
                    >
                      Cancelar
                    </button>
                  </div>
                ) : (
                  <>
                    {selectedStoreImage ? (
                      <img
                        src={
                          selectedStoreImage.startsWith('data:image/')
                            ? selectedStoreImage // Se for base64
                            : `https://sandbox.buunpsgpsystem.com.br/uploads/${selectedStoreImage}` // Se for URL
                        }
                        alt="Selected Profile"
                        className="w-full h-full object-cover rounded-sm shadow-lg"
                      />
                    ) : (
                      <div className="w-full h-full bg-gray-200 rounded-sm flex items-center justify-center shadow-lg">
                        <span className="text-gray-400">Nenhuma imagem</span>
                      </div>
                    )}
                    <button
                      onClick={onToggleStoreEdit}
                      className="absolute bottom-0 right-0 bg-blue-500 text-white p-2 rounded-full cursor-pointer hover:bg-blue-600 text-xs"
                      title="Alterar imagem"
                    >
                      Editar
                    </button>
                  </>
                )}
              </div>
              <p className="text-xs text-gray-500 text-center">
                Tamanho máximo: 1 MB. Formatos: JPEG, PNG.
              </p>
            </div>

            {/* Seção de Imagem de Capa */}
            <div className="w-full flex flex-col justify-center items-center">
              <label className="block text-gray-700 mb-2 text-center">
                Imagem da Capa
              </label>
              <div className="relative w-full h-32 mb-4">
                {isEditingCoverImage ? (
                  <div className="flex flex-col items-center">
                    <InputFile
                      refInput={imageRefBanner}
                      name="imageBanner"
                      fileName={formNameFiles?.imageBanner}
                      onChange={(e) =>
                        onChangeFormFileInput(FormInputStore.imageBanner)(
                          e.target.files?.[0]
                        )
                      }
                      error={
                        formErrors.imageBanner && formErrors.imageBanner[0]
                      }
                      wrapperClass="file:border file:border-gray-300 file:rounded file:px-4 file:py-2"
                    />
                    <button
                      onClick={onToggleCoverEdit}
                      className="mt-2 bg-red-500 text-white px-4 py-1 rounded-full hover:bg-red-600 text-xs"
                    >
                      Cancelar
                    </button>
                  </div>
                ) : (
                  <>
                    {selectedCoverImage ? (
                      <img
                        src={
                          selectedCoverImage.startsWith('data:image/')
                            ? selectedCoverImage // Se for base64
                            : `https://sandbox.buunpsgpsystem.com.br/uploads/${selectedCoverImage}` // Se for URL
                        }
                        alt="Selected Cover"
                        className="w-full h-full object-cover rounded-sm shadow-lg"
                      />
                    ) : (
                      <div className="w-full h-full bg-gray-200 rounded-sm flex items-center justify-center shadow-lg">
                        <span className="text-gray-400">Nenhuma imagem</span>
                      </div>
                    )}
                    <button
                      onClick={onToggleCoverEdit}
                      className="absolute bottom-0 right-0 bg-blue-500 text-white p-2 rounded-full cursor-pointer hover:bg-blue-600 text-xs"
                      title="Alterar imagem da capa"
                    >
                      Editar
                    </button>
                  </>
                )}
              </div>
              <p className="text-xs text-gray-500 text-center">
                Tamanho máximo: 2 MB. Formatos: JPEG, PNG.
              </p>
            </div>
          </div>
        </div>

        <Button
          type="submit"
          title={isStore ? 'Salvar Alterações' : 'Criar minha loja'}
          buttonStyle="red"
          size="md"
        />
      </form>
    </>
  );
};
