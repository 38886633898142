import cep from 'cep-promise';
import React, { FormEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { FormInputNameRegister } from 'src/features/core/auth/types';
import { updateMask as updateMaskCEP } from 'src/helpers/masks/cep';
import { unmask } from 'src/helpers/masks/generalDate';
import validators from 'src/helpers/validators';
import useForm from 'src/hooks/useForm';
import Client from 'src/model/User';
import { UserBasic } from 'src/model/UserBasic';
import { setLoading, useLoading } from 'src/redux/loading/loadingSlice';
import { api } from 'src/services/api';
import { Address } from './../../model/Address';
import { AddressManagerUI } from './ui';

interface AddressManagerScreenProps {
  address: Address[];
}

export const AddressManagerScreen: React.FC<AddressManagerScreenProps> = ({
  address,
}): JSX.Element => {
  const { loading } = useSelector(useLoading);
  //   const [shouldShowPasswordToText, setShouldShowPasswordToText] =
  //     useState(false);
  //   const [shouldShowPasswordToText2, setShouldShowPasswordToText2] =
  //     useState(false);
  //   const { visible, onSetVisible } = useDialog();
  const dispatch = useDispatch();
  //   const [formNameFiles, setFormNameFiles] = useState<NameFiles>({});

  //   const handleOnTogglePasswordToText = (): void =>
  //     setShouldShowPasswordToText(!shouldShowPasswordToText);

  //   const handleOnTogglePasswordToText2 = (): void =>
  //     setShouldShowPasswordToText2(!shouldShowPasswordToText2);

  const {
    formData: formDataRegister,
    formErrors: formErrorRegister,
    setErrors: setErrorRegister,
    onChangeFormInput: onChangeFormInputRegister,
    isFormValid: isFormValidRegister,
    resetForm: resetFormRegister,
  } = useForm({
    initialData: {
      zipCode: '',
      state: '',
      city: '',
      street: '',
      complement: '',
      number: '',
      neighborhood: '',
      imageBase64: '',
      country: '',
    },
    validators: {
      zipCode: [validators.required],
      state: [validators.required],
      city: [validators.required],
      street: [validators.required],
      number: [validators.required],
      country: [validators.required],
      neighborhood: [validators.required],
    },
    formatters: {
      zipCode: updateMaskCEP,
    },
  });

  //   const showModal = ({ value, title }: ShouldShowModalProps): void => {
  //     onSetVisible(true);
  //   };

  //   const handleOnChangeFileInput =
  //     (inputName: string) =>
  //     (file: File | undefined): void => {
  //       console.log('Arquivo selecionado:', file);
  //       // validate if file is image
  //       if (file && file.type.match(/image\/(jpg|jpeg|png)/)) {
  //         const reader = new FileReader();
  //         reader.readAsDataURL(file);
  //         reader.onload = () => {
  //           const base64 = reader.result?.toString();
  //           if (base64) {
  //             setFormNameFiles({ ...formNameFiles, [inputName]: file.name });
  //             onChangeFormInputRegister(inputName)('');
  //             onChangeFormInputRegister(inputName)(base64);
  //           }
  //         };
  //       } else {
  //         setErrorRegister({
  //           [inputName]: ['O formato deve ser .jpg, .jpeg ou .png'],
  //         });
  //       }
  //     };

  const handleEditAddress = async (id: string, e: FormEvent): Promise<void> => {
    try {
      e.preventDefault();
      if (isFormValidRegister()) {
        console.log('Editando endereço', id);
        dispatch(setLoading(true));

        const payload: Address = {
          id: id,
          zipcode: formDataRegister[FormInputNameRegister.zipCode],
          state: formDataRegister[FormInputNameRegister.state],
          city: formDataRegister[FormInputNameRegister.city],
          district: formDataRegister[FormInputNameRegister.neighborhood],
          street: formDataRegister[FormInputNameRegister.street],
          complement: formDataRegister[FormInputNameRegister.complement],
          number: formDataRegister[FormInputNameRegister.number],
          country: formDataRegister[FormInputNameRegister.country],
        };
        console.log('Payload:', payload);
        const response = await api.put<Address>(
          '/user/update-address',
          payload
        );

        if (response.status === 200) {
          resetFormRegister();
          toast.success('Dados salvos com sucesso!');
        } else if (response.status === 400) {
          const errorMessage =
            typeof response.data === 'string'
              ? response.data
              : JSON.stringify(response.data);
          toast.error(errorMessage);
        }
      }
      // eslint-disable-next-line
    } catch (error: any) {
      //   resetFormRegister();
      let errorMessage =
        'Falha ao realizar o cadastro, tente novamente mais tarde';
      if (error.response && error.response.data) {
        errorMessage =
          typeof error.response.data === 'string'
            ? error.response.data
            : JSON.stringify(error.response.data);
      }
      console.log('Erro:', error);
      toast.error(errorMessage);
      setErrorRegister({
        document: [errorMessage],
      });
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleOnSubmitRegister = async (e: FormEvent): Promise<void> => {
    try {
      e.preventDefault();
      if (isFormValidRegister()) {
        dispatch(setLoading(true));
        const dateString = unmask(formDataRegister[FormInputNameRegister.date]);

        if (!dateString) {
          const fielName = FormInputNameRegister.date.toString();
          setErrorRegister({ [fielName]: ['Data de nascimento Inválida'] });
          return;
        }
        if (
          formDataRegister[FormInputNameRegister.password] !==
          formDataRegister[FormInputNameRegister.confirmPassword]
        ) {
          setErrorRegister({
            [FormInputNameRegister.password]: ['Senhas Diferentes'],
            [FormInputNameRegister.confirmPassword]: ['Senhas Diferentes'],
          });
          return;
        }
        const address = {
          zipcode: formDataRegister[FormInputNameRegister.zipCode],
          state: formDataRegister[FormInputNameRegister.state],
          city: formDataRegister[FormInputNameRegister.city],
          district: formDataRegister[FormInputNameRegister.neighborhood],
          street: formDataRegister[FormInputNameRegister.street],
          complement: formDataRegister[FormInputNameRegister.complement],
          number: formDataRegister[FormInputNameRegister.number],
          country: formDataRegister[FormInputNameRegister.country],
        } as Address;
        const payload: UserBasic = {
          name: formDataRegister[FormInputNameRegister.name],
          cpf: formDataRegister[FormInputNameRegister.document],
          gender: formDataRegister[FormInputNameRegister.gender],
          email: formDataRegister[FormInputNameRegister.email],
          cellPhone: formDataRegister[FormInputNameRegister.phone],
          birthdate: dateString,
          password: formDataRegister[FormInputNameRegister.password],
          address: address,
          //   acceptedTerms:
          //     formDataRegister[FormInputNameRegister.terms] &&
          //     formDataRegister[FormInputNameRegister.terms] === 'true'
          //       ? true
          //       : false,
          image: formDataRegister[FormInputNameRegister.imageBase64],
        };
        const response = await api.post<Client>('/user', payload);

        if (response.status === 201) {
          resetFormRegister();
          toast.success('Dados salvos com sucesso!');
        } else if (response.status === 400) {
          const errorMessage =
            typeof response.data === 'string'
              ? response.data
              : JSON.stringify(response.data);
          toast.error(errorMessage);
        }
      }
      // eslint-disable-next-line
    } catch (error: any) {
      //   resetFormRegister();
      let errorMessage =
        'Falha ao realizar o cadastro, tente novamente mais tarde';
      if (error.response && error.response.data) {
        errorMessage =
          typeof error.response.data === 'string'
            ? error.response.data
            : JSON.stringify(error.response.data);
      }
      toast.error(errorMessage);
      setErrorRegister({
        document: [errorMessage],
      });
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleOnChangeCEP = async (value: string): Promise<void> => {
    if (value.length === 9) {
      const cepResponse = await cep(value);
      onChangeFormInputRegister(FormInputNameRegister.state)(cepResponse.state);
      onChangeFormInputRegister(FormInputNameRegister.city)(cepResponse.city);
      onChangeFormInputRegister(FormInputNameRegister.neighborhood)(
        cepResponse.neighborhood
      );
      onChangeFormInputRegister(FormInputNameRegister.street)(
        cepResponse.street
      );
    }
  };

  const handleOnSetAddress = (address: Address): void => {
    onChangeFormInputRegister(FormInputNameRegister.zipCode)(address.zipcode);
    onChangeFormInputRegister(FormInputNameRegister.state)(address.state);
    onChangeFormInputRegister(FormInputNameRegister.city)(address.city);
    onChangeFormInputRegister(FormInputNameRegister.neighborhood)(
      address.district
    );
    onChangeFormInputRegister(FormInputNameRegister.street)(address.street);
    onChangeFormInputRegister(FormInputNameRegister.complement)(
      address.complement
    );
    onChangeFormInputRegister(FormInputNameRegister.number)(address.number);
    onChangeFormInputRegister(FormInputNameRegister.country)(address.country);
  };

  return (
    <AddressManagerUI
      address={address}
      state={loading}
      formData={formDataRegister}
      formErrors={formErrorRegister}
      onChangeFormInput={onChangeFormInputRegister}
      onChangeCEP={handleOnChangeCEP}
      onSubmitRegister={handleOnSubmitRegister}
      onSetAddress={handleOnSetAddress}
      onEditAddress={handleEditAddress}
    />
  );
};
