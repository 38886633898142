'use client';

import type React from 'react';
import { InputFile } from 'src/components/InputFile';
import { X } from 'lucide-react';
// Utility function for conditional class names
const cn = (...classes: (string | boolean | undefined)[]) => {
  return classes.filter(Boolean).join(' ');
};

interface UploadImagensProps {
  filesImage: File[];
  previewFiles: string[];
  FileUpload: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onDeleteFile: (index: number) => void;
}

export const UploadImagens: React.FC<UploadImagensProps> = ({
  filesImage,
  previewFiles,
  FileUpload,
  onDeleteFile,
}) => {
  const hasError = filesImage.length > 5;

  return (
    <div className="space-y-6">
      <div className="space-y-3">
        <div className="space-y-1">
          <p className="text-sm font-medium text-muted-foreground">
            Upload de até{' '}
            <span className="text-primary font-semibold">
              4 imagens e 1 vídeo ou 5 imagens
            </span>
          </p>
          <p className="text-sm text-muted-foreground">
            Tamanho recomendado:{' '}
            <span className="text-primary font-medium">Min 800px x 800px</span>{' '}
            ou{' '}
            <span className="text-primary font-medium">
              Max 1600px x 1600px
            </span>
          </p>
        </div>

        <InputFile
          name="mediaFiles"
          label="Imagens ou vídeo"
          multiple
          onChange={FileUpload}
          error={hasError ? 'Máximo de 5 arquivos' : undefined}
        />
      </div>

      {/* Preview das imagens */}
      {previewFiles.length > 0 && (
        <div className="space-y-2">
          <p className="text-sm font-medium">
            Arquivos selecionados ({previewFiles.length}/5)
          </p>
          <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-3">
            {previewFiles.map((preview, index) => (
              <div
                key={index}
                className={cn(
                  'relative group rounded-md overflow-hidden border border-border',
                  'transition-all duration-200 hover:shadow-md'
                )}
              >
                <img
                  src={preview || '/placeholder.svg'}
                  alt={`Preview ${index + 1}`}
                  className="w-full aspect-square object-cover"
                />
                <button
                  type="button"
                  className={cn(
                    'absolute top-1 right-1 p-1 rounded-full',
                    'bg-black/60 text-white hover:bg-black/80',
                    'transition-opacity duration-200',
                    'focus:outline-none focus:ring-2 focus:ring-primary'
                  )}
                  onClick={() => onDeleteFile(index)}
                  aria-label={`Remover imagem ${index + 1}`}
                >
                  <X size={16} />
                </button>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
