import dayjs from 'dayjs';
import React, { useEffect, useRef, useState } from 'react';
import { Chat } from 'src/model/chat';
import { ChatMessage } from 'src/model/ChatMessage';
import Client from 'src/model/User';
import { UserType } from 'src/model/UserType';
import { api } from 'src/services/api';
import { ArrowLeft } from 'lucide-react';

interface ChatComponentProps {
  user: Client;
}

export const ChatComponent: React.FC<ChatComponentProps> = ({ user }) => {
  const [chats, setChats] = useState<Chat[]>([]);
  const [selectedChat, setSelectedChat] = useState<string | null>(null);
  const [messages, setMessages] = useState<ChatMessage[]>([]);
  const [newMessage, setNewMessage] = useState('');
  const [chatData, setChatData] = useState(chats);
  const messagesEndRef = useRef<HTMLDivElement | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    getChats();
  }, []);

  const getChats = async (): Promise<void> => {
    try {
      const response = await api.get<Chat[]>(`/chat/buyer/${user.id}`);
      const data = response.data;
      setChats(data);
      setChatData(data);
      if (user.type === UserType.SELLER) {
        const responseSeller = await api.get<Chat[]>(`/chat/seller/${user.id}`);
        setChatData((prevData) => [...prevData, ...responseSeller.data]);
      }
    } catch (error) {
      console.error('Erro ao buscar os chats:', error);
    }
  };

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages]);

  const handleSelectChat = (chatId: string) => {
    const updatedChats = chatData.map((chat) =>
      chat.id === chatId ? { ...chat, unread: 0 } : chat
    );
    setChatData(updatedChats);

    const selected = updatedChats.find((chat) => chat.id === chatId);
    if (selected) {
      setMessages(selected.chatMessage);
      setSelectedChat(chatId);
      if (window.innerWidth < 768) {
        document.body.classList.add('hide-chat-list'); // Esconde a lista em mobile
      }
    }
  };

  const handleSendMessage = () => {
    if (newMessage.trim() === '') return;

    const newMessageObject: ChatMessage = {
      id: '',
      user: user,
      message: newMessage,
      read: false,
    };

    setMessages([...messages, newMessageObject]);
    setNewMessage('');
    inputRef.current?.focus();
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSendMessage();
    }
  };

  const goBackToList = () => {
    setSelectedChat(null);
    document.body.classList.remove('hide-chat-list'); // Volta a mostrar a lista em mobile
  };

  return (
    <div className="flex w-full h-screen bg-white overflow-hidden">
      {/* Lista de Conversas */}
      <div
        className={`w-1/3 border-r border-red-200 p-4 transition-all duration-300 ease-in-out ${selectedChat ? 'hidden md:block' : 'block'}`}
      >
        <input
          type="text"
          placeholder="Pesquisar"
          className="w-full p-2 pl-4 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-red-300"
        />
        <div className="overflow-y-auto mt-4">
          {chatData.map((chat) => (
            <div
              key={chat.id}
              onClick={() => handleSelectChat(chat.id)}
              className={`p-4 cursor-pointer transition-all duration-200 border-b border-gray-100 hover:bg-red-100 ${selectedChat === chat.id ? 'bg-red-200' : ''}`}
            >
              <div className="flex justify-between items-center">
                <h3 className="font-semibold">{chat.userSeller.name}</h3>
                {chat.chatMessage.filter((msg) => !msg.read).length > 0 && (
                  <span className="text-xs bg-red-500 text-white px-2 py-1 rounded-full">
                    {chat.chatMessage.filter((msg) => !msg.read).length}
                  </span>
                )}
              </div>
              <p className="text-sm text-gray-600 truncate">
                {chat.chatMessage[0]?.message || 'Sem mensagens'}
              </p>
              <p className="text-xs text-gray-400">
                {dayjs(chat.createdAt).locale('pt-br').format('DD MMM')}
              </p>
            </div>
          ))}
        </div>
      </div>

      {/* Conteúdo do Chat */}
      <div
        className={`flex flex-col w-full md:w-2/3 transition-all duration-300 ease-in-out ${selectedChat ? 'block' : 'hidden md:flex'}`}
      >
        {selectedChat ? (
          <>
            <div className="p-4 border-b border-red-200 flex items-center">
              <button
                onClick={goBackToList}
                className="md:hidden p-2 rounded-full hover:bg-gray-100"
              >
                <ArrowLeft className="h-5 w-5 text-gray-600" />
              </button>
              <h2 className="ml-2 font-semibold">
                Conversa com{' '}
                {
                  chatData.find((chat) => chat.id === selectedChat)?.userSeller
                    .name
                }
              </h2>
            </div>
            <div className="flex-grow p-4 overflow-y-auto bg-gray-100">
              {messages.map((msg, index) => (
                <div
                  key={index}
                  className={`mb-4 flex ${msg.user.id === user.id ? 'justify-end' : 'justify-start'}`}
                >
                  <div
                    className={`p-3 rounded-2xl max-w-xs shadow-sm ${msg.user.id === user.id ? 'bg-red-500 text-white' : 'bg-white text-gray-800 border border-gray-300'}`}
                  >
                    <strong>{msg.user.name}</strong>: {msg.message}
                  </div>
                </div>
              ))}
              <div ref={messagesEndRef} />
            </div>
            <div className="p-4 border-t border-red-200 flex items-center">
              <input
                ref={inputRef}
                type="text"
                placeholder="Escreva sua mensagem..."
                className="flex-grow p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-red-300"
                value={newMessage}
                onChange={(e) => setNewMessage(e.target.value)}
                onKeyPress={handleKeyPress}
              />
              <button
                className="ml-2 px-4 py-2 bg-red-500 hover:bg-red-600 text-white rounded-lg"
                onClick={handleSendMessage}
              >
                Enviar
              </button>
            </div>
          </>
        ) : (
          <div className="flex flex-col items-center justify-center w-full h-full text-gray-500">
            <h1 className="text-lg">Selecione uma conversa</h1>
          </div>
        )}
      </div>
    </div>
  );
};
