import React, { FormEvent, useState } from 'react';
import { toast } from 'react-toastify';
import { Button } from 'src/components/Button';
import { CardVideo } from 'src/components/CardVideo';
import { InputText } from 'src/components/InputText';
import { FormInputVideo } from 'src/features/admin/types';
import { UseFormReturn } from 'src/hooks';
import { VideoData } from 'src/model/VideoTutorial';
import { api } from 'src/services/api';

interface VideoTutorialManagerProps
  extends Pick<UseFormReturn, 'formData' | 'formErrors' | 'onChangeFormInput'> {
  videos: VideoData[];
  onSubmitRegister: (e: FormEvent) => void;
  fetchVideos: () => void;
}

export const VideoTutorialManager: React.FC<VideoTutorialManagerProps> = ({
  videos,
  formData,
  formErrors,
  onChangeFormInput,
  onSubmitRegister,
  fetchVideos,
}) => {
  const [editingVideo, setEditingVideo] = useState<VideoData | null>(null);

  const handleEdit = async (video: VideoData) => {
    console.log('Iniciando atualização do vídeo:', video);
    try {
      const response = await api.put('/tutorial/update', video);
      console.log('Resposta da API (edição):', response.data);

      toast.success('Vídeo atualizado com sucesso!', { position: 'top-right' });
      setEditingVideo(null);
      fetchVideos();
    } catch (error) {
      console.error('Erro ao editar vídeo:', error);
      toast.error('Ocorreu um erro ao atualizar o vídeo.', {
        position: 'top-right',
      });
    }
  };

  const handleDelete = async (id: number) => {
    console.log(`Solicitando remoção do vídeo ID: ${id}`);

    if (!window.confirm('Tem certeza que deseja excluir este vídeo?')) return;

    try {
      const response = await api.delete(`/tutorial/delete/${id}`);
      console.log('Resposta da API (exclusão):', response.data);

      toast.success('Vídeo removido com sucesso!', { position: 'top-right' });
      fetchVideos();
    } catch (error) {
      console.error('Erro ao excluir vídeo:', error);
      toast.error('Ocorreu um erro ao excluir o vídeo.', {
        position: 'top-right',
      });
    }
  };

  return (
    <>
      <form onSubmit={onSubmitRegister}>
        <div className="mb-4 space-y-2">
          <InputText
            name="name"
            label="Link do vídeo"
            placeholder="URL do vídeo..."
            value={formData[FormInputVideo.videoUrl]}
            onChange={(e) =>
              onChangeFormInput(FormInputVideo.videoUrl)(e.target.value)
            }
            error={formErrors.videoUrl && formErrors.videoUrl[0]}
            inputClassName="rounded-lg p-2 w-full bg-[#fafafa] shadow-inner"
          />
          <InputText
            name="title"
            label="Nome do tutorial"
            placeholder="Digite aqui..."
            value={formData[FormInputVideo.title]}
            onChange={(e) =>
              onChangeFormInput(FormInputVideo.title)(e.target.value)
            }
            error={formErrors.title && formErrors.title[0]}
            inputClassName="rounded-lg p-2 w-full bg-[#fafafa] shadow-inner"
          />
          <InputText
            name="subtitle"
            label="Nome do subtítulo"
            placeholder="Digite aqui..."
            value={formData[FormInputVideo.subtitle]}
            onChange={(e) =>
              onChangeFormInput(FormInputVideo.subtitle)(e.target.value)
            }
            error={formErrors.subtitle && formErrors.subtitle[0]}
            inputClassName="rounded-lg p-2 w-full bg-[#fafafa] shadow-inner"
          />
          <InputText
            name="position"
            label="Posição"
            placeholder="Digite aqui..."
            value={formData[FormInputVideo.position]}
            onChange={(e) =>
              onChangeFormInput(FormInputVideo.position)(e.target.value)
            }
            error={formErrors.position && formErrors.position[0]}
            inputClassName="rounded-lg p-2 w-full bg-[#fafafa] shadow-inner"
          />
          <Button title="Cadastrar" buttonStyle="red" size="lg" type="submit" />
        </div>
      </form>

      <div className="grid grid-cols-[repeat(auto-fit,minmax(320px,1fr))] gap-x-10 gap-y-6 justify-items-center w-full flex-wrap">
        {[...videos]
          .sort(
            (a, b) =>
              parseInt(a.position ?? '0', 10) - parseInt(b.position ?? '0', 10)
          )
          .map((video) => (
            <div key={video.id} className="space-y-2">
              <CardVideo
                videoUrl={video.videoUrl}
                numberCard={parseInt(video.position ?? '0', 10)}
                titleCard={video.title}
                subtitle={video.subtitle}
              />
              <div className="flex items-center justify-center gap-2">
                <button
                  onClick={() => setEditingVideo(video)}
                  className="bg-yellow-500 text-white p-2 rounded"
                >
                  Editar
                </button>
                <button
                  onClick={() => handleDelete(video.id)}
                  className="bg-red-500 text-white p-2 rounded"
                >
                  Remover
                </button>
              </div>
            </div>
          ))}
      </div>

      {editingVideo && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-lg w-96">
            <h2 className="text-xl font-bold mb-4">Editar Vídeo</h2>
            <InputText
              name="title"
              label="Nome do tutorial"
              placeholder="Digite aqui..."
              value={editingVideo.title}
              onChange={(e) =>
                setEditingVideo({ ...editingVideo, title: e.target.value })
              }
            />
            <InputText
              name="subtitle"
              label="Nome do subtítulo"
              placeholder="Digite aqui..."
              value={editingVideo.subtitle}
              onChange={(e) =>
                setEditingVideo({ ...editingVideo, subtitle: e.target.value })
              }
            />
            <InputText
              name="position"
              label="Posição"
              placeholder="Digite aqui..."
              value={editingVideo.position}
              onChange={(e) =>
                setEditingVideo({ ...editingVideo, position: e.target.value })
              }
            />
            <div className="flex justify-end mt-4 gap-2">
              <button
                onClick={() => setEditingVideo(null)}
                className="bg-gray-500 text-white p-2 rounded"
              >
                Cancelar
              </button>
              <button
                onClick={() => handleEdit(editingVideo)}
                className="bg-blue-500 text-white p-2 rounded"
              >
                Salvar
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
