import React, { useEffect, useState } from 'react';
import { CardVideo } from 'src/components/CardVideo';
import { AuthWrapper } from 'src/features/core/auth/screen/AuthWrapper';
import { api } from 'src/services/api';

interface VideoData {
  id: string;
  link: string;
  description: string;
  name: string;
  position: string;
}

export const TutorialUI: React.FC = (): JSX.Element => {
  const [videoData, setVideoData] = useState<VideoData[]>([]);

  const getVideos = async () => {
    try {
      const response = await api.get<VideoData[]>('/tutorial/find-all');
      if (response.status === 200) {
        // prettier-ignore
        const sortedVideos = response.data.sort((a, b) => parseInt(a.position, 10) - parseInt(b.position, 10));
        setVideoData(sortedVideos);
      } else {
        console.error('Erro ao carregar vídeos:', response);
      }
    } catch (error) {
      console.error('Erro ao buscar vídeos:', error);
    }
  };

  useEffect(() => {
    getVideos();
  }, []);

  return (
    <AuthWrapper>
      <div className="flex flex-wrap items-center justify-center w-full sm:w-[80%] m-4 bg-white px-6 py-10 rounded-lg shadow-sm gap-6">
        {videoData.map((video) => (
          <CardVideo
            key={video.id}
            numberCard={parseInt(video.position, 10)}
            titleCard={video.description}
            subtitle={video.name}
            videoUrl={video.link}
          />
        ))}
      </div>
    </AuthWrapper>
  );
};
