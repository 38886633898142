import React, { useState } from 'react';

interface InputTagsProps {
  tags: string[];
  error?: string | null;
  inputClassName?: string; // Propriedade para estilo do input
  addTag: (tag: string) => void;
  removeTag: (tag: string) => void;
}

const InputTags: React.FC<InputTagsProps> = ({
  tags,
  error,
  inputClassName,
  addTag,
  removeTag,
}) => {
  const [inputValue, setInputValue] = useState('');

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && inputValue.trim() !== '') {
      e.preventDefault();
      addTag(inputValue.trim());
      setInputValue('');
    }
  };

  return (
    <div className="w-full">
      <label className="block text-gray-500 text-base font-dmsans font-regular mb-2">
        Tags *
      </label>
      <input
        type="text"
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        onKeyDown={handleKeyDown}
        placeholder="Digite uma tag e pressione Enter"
        className={`relative appearance-none rounded w-full text-input text-base leading-tight ${
          error ? 'border-red-500' : 'border-none'
        } ${inputClassName}`}
      />
      {error && <p className="text-red-500 text-xs mt-1">{error}</p>}

      <div className="flex flex-wrap mt-4">
        {tags.map((tag, index) => (
          <div
            key={tag + index}
            className="bg-gray-200 rounded-lg px-3 py-1 m-1 flex items-center space-x-2"
          >
            <span>{tag}</span>
            <button
              type="button"
              onClick={() => removeTag(tag)}
              className="text-red-500 hover:text-red-700"
            >
              ✕
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default InputTags;
