'use client';

import type React from 'react';
import { InputText } from 'src/components/InputText';
import { Checkbox } from 'src/components/Checkbox';
import type { Product } from 'src/model/Product';

interface WarrantyShippingDimensionsProps {
  formData: Product;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formErrors: any;
  onChangeFormInput: (inputName: string) => (value: string) => void;
  onShippingCalc: (
    height: string,
    width: string,
    depth: string,
    weight: string
  ) => void;
  shippingCost: string | null;
}

export const WarrantyShippingDimensions: React.FC<
  WarrantyShippingDimensionsProps
> = ({
  formData,
  formErrors,
  onChangeFormInput,
  onShippingCalc,
  shippingCost,
}) => {
  // Função para atualizar o estado do "freeShipping"
  function handleFreeShippingChange(value: string): void {
    // Usando a função onChangeFormInput para atualizar o valor no formData
    onChangeFormInput('freeShipping')(value === 'true' ? 'true' : 'false');
  }

  return (
    <div className="space-y-6">
      {/* Garantia */}
      <div className="bg-white p-5 rounded-lg border border-gray-100 shadow-sm">
        <h3 className="text-lg font-medium mb-4">Informações de Garantia</h3>
        <div className="space-y-2">
          <InputText
            name="warrantyValue"
            label="Garantia *"
            placeholder="Digite o tempo de garantia..."
            onChange={(e) => onChangeFormInput('warrantyValue')(e.target.value)}
            error={formErrors.warranty?.[0]}
            inputClassName="rounded-lg p-2.5 w-full bg-gray-50 border border-gray-200 focus:border-primary focus:ring-1 focus:ring-primary transition-colors"
          />
          {formData.warranty?.warranty && (
            <p className="text-sm text-gray-500 italic">
              Valor atual:{' '}
              <span className="font-medium">{formData.warranty.warranty}</span>
            </p>
          )}
        </div>
      </div>

      {/* Frete */}
      <div className="bg-white p-5 rounded-lg border border-gray-100 shadow-sm">
        <h3 className="text-lg font-medium mb-4">Informações de Frete</h3>

        <div className="space-y-4">
          {shippingCost && (
            <div className="bg-gray-50 rounded-lg p-4 border border-gray-200">
              <p className="text-sm text-gray-600 mb-2">
                A taxa de frete abaixo é um valor padrão. O valor exato será
                calculado no momento da compra com base no endereço.
              </p>
              <div className="flex justify-end items-center">
                <span className="text-sm font-medium mr-2">Frete Base:</span>
                <span className="text-primary font-semibold text-base">
                  {shippingCost}
                </span>
              </div>
            </div>
          )}

          <div className="py-2">
            <Checkbox
              name="freeShipping"
              value="true"
              checked={formData.freeShipping === true}
              label="Frete Grátis"
              onChange={handleFreeShippingChange}
              checkboxClassName="w-4 h-4 border-primary border-2 accent-primary cursor-pointer"
              labelClassName="text-gray-700 text-md font-medium cursor-pointer ml-2"
              error={formErrors.freeShipping?.[0]}
            />
          </div>
        </div>
      </div>

      {/* Dimensões */}
      <div className="bg-white p-5 rounded-lg border border-gray-100 shadow-sm">
        <h3 className="text-lg font-medium mb-4">Dimensões do Produto</h3>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
          <div className="space-y-2">
            <InputText
              name="dimensionsWidth"
              type="text"
              label="Largura (cm) *"
              placeholder="Digite a largura..."
              onChange={(e) =>
                onChangeFormInput('dimensionsWidth')(e.target.value)
              }
              onBlur={() =>
                onShippingCalc(
                  formData.dimensions.height,
                  formData.dimensions.width,
                  formData.dimensions.depth,
                  formData.dimensions.weight
                )
              }
              error={formErrors.dimensionsWidth?.[0]}
              inputClassName="rounded-lg p-2.5 w-full bg-gray-50 border border-gray-200 focus:border-primary focus:ring-1 focus:ring-primary transition-colors"
            />
            {formData.dimensions.width && (
              <p className="text-xs text-gray-500 italic">
                Valor atual:{' '}
                <span className="font-medium">{formData.dimensions.width}</span>
              </p>
            )}
          </div>

          <div className="space-y-2">
            <InputText
              name="dimensionsHeight"
              type="number"
              label="Altura (cm) *"
              placeholder="Digite a altura..."
              onChange={(e) =>
                onChangeFormInput('dimensionsHeight')(e.target.value)
              }
              onBlur={() =>
                onShippingCalc(
                  formData.dimensions.height,
                  formData.dimensions.width,
                  formData.dimensions.depth,
                  formData.dimensions.weight
                )
              }
              error={formErrors.dimensionsHeight?.[0]}
              inputClassName="rounded-lg p-2.5 w-full bg-gray-50 border border-gray-200 focus:border-primary focus:ring-1 focus:ring-primary transition-colors"
            />
            {formData.dimensions.height && (
              <p className="text-xs text-gray-500 italic">
                Valor atual:{' '}
                <span className="font-medium">
                  {formData.dimensions.height}
                </span>
              </p>
            )}
          </div>

          <div className="space-y-2">
            <InputText
              name="dimensionsDepth"
              type="number"
              label="Profundidade (cm) *"
              placeholder="Digite a profundidade..."
              onChange={(e) => {
                const value = e.target.value;
                if (
                  /^\d*\.?\d*$/.test(value) &&
                  Number.parseFloat(value) >= 0
                ) {
                  onChangeFormInput('dimensionsDepth')(value);
                } else {
                  onChangeFormInput('dimensionsDepth')('');
                }
              }}
              onBlur={() =>
                onShippingCalc(
                  formData.dimensions.height,
                  formData.dimensions.width,
                  formData.dimensions.depth,
                  formData.dimensions.weight
                )
              }
              error={formErrors.dimensionsDepth?.[0]}
              inputClassName="rounded-lg p-2.5 w-full bg-gray-50 border border-gray-200 focus:border-primary focus:ring-1 focus:ring-primary transition-colors"
            />
            {formData.dimensions.depth && (
              <p className="text-xs text-gray-500 italic">
                Valor atual:{' '}
                <span className="font-medium">{formData.dimensions.depth}</span>
              </p>
            )}
          </div>

          <div className="space-y-2">
            <InputText
              name="dimensionsWeight"
              type="number"
              label="Peso (kg) *"
              placeholder="Digite o peso..."
              onChange={(e) => {
                const value = e.target.value;
                if (
                  /^\d*\.?\d*$/.test(value) &&
                  Number.parseFloat(value) >= 0
                ) {
                  onChangeFormInput('dimensionsWeight')(value);
                } else {
                  onChangeFormInput('dimensionsWeight')('');
                }
              }}
              onBlur={() =>
                onShippingCalc(
                  formData.dimensions.height,
                  formData.dimensions.width,
                  formData.dimensions.depth,
                  formData.dimensions.weight
                )
              }
              error={formErrors.dimensionsWeight?.[0]}
              inputClassName="rounded-lg p-2.5 w-full bg-gray-50 border border-gray-200 focus:border-primary focus:ring-1 focus:ring-primary transition-colors"
            />
            {formData.dimensions.weight && (
              <p className="text-xs text-gray-500 italic">
                Valor atual:{' '}
                <span className="font-medium">
                  {formData.dimensions.weight}
                </span>
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
