import React from 'react';
import { ProductCard3 } from 'src/components/ProductCard3';
import { Category } from 'src/model/Category';
import { Product } from 'src/model/Product';

interface SidebarWithProductsUIProps {
  categories: {
    categories: Category[];
    activeCategoryId: string | null;
    onCategorySelect: (categoryId: string | null) => void;
  };
  products: { products: Product[] };
  valuePrice: (product: Product, discount: boolean) => string;
}

export const SidebarWithProductsUI: React.FC<SidebarWithProductsUIProps> = ({
  categories: { categories, activeCategoryId, onCategorySelect },
  products: { products },
  valuePrice,
}) => {
  return (
    <div className="flex flex-col md:flex-row bg-white rounded-lg shadow-md overflow-hidden">
      <aside className="md:w-64 bg-gray-50 border-r border-gray-100 p-5">
        <h3 className="text-lg font-semibold text-gray-800 mb-4 hidden md:block">
          Categorias
        </h3>
        <div className="md:hidden px-4 py-2 mb-4">
          <select
            className="w-full p-2 border border-gray-200 rounded-md text-gray-700 bg-white"
            value={activeCategoryId || ''}
            onChange={(e) =>
              onCategorySelect(e.target.value === '' ? null : e.target.value)
            }
          >
            <option value="">Todas as categorias</option>
            {categories.map((category) => (
              <option key={category.id} value={category.id}>
                {category.name}
              </option>
            ))}
          </select>
        </div>

        <ul className="hidden space-x-4 md:space-x-0 md:space-y-2 flex md:block overflow-x-auto pb-3 md:pb-0">
          <li
            className={`md:border-l-4 border-b-4 md:border-b-0 pl-2 md:pl-3 pb-2 md:pb-2 text-gray-700 cursor-pointer whitespace-nowrap transition-colors duration-200 ${
              activeCategoryId === null
                ? 'border-gray-800 font-medium'
                : 'border-transparent hover:border-gray-400'
            }`}
            onClick={() => onCategorySelect(null)}
          >
            Todos os produtos
          </li>
          {categories.map((category) => (
            <li
              key={category.id}
              className={`md:border-l-4 border-b-4 md:border-b-0 pl-2 md:pl-3 pb-2 md:pb-2 pt-3 md:pt-3 text-gray-700 cursor-pointer whitespace-nowrap transition-colors duration-200 ${
                activeCategoryId === category.id
                  ? 'border-gray-800 font-medium'
                  : 'border-transparent hover:border-gray-400'
              }`}
              style={{
                display: 'block', // Garante que o <li> ocupe toda a largura disponível
                minHeight: '40px', // Dá uma altura mínima para o item
                lineHeight: '40px', // Centraliza o texto verticalmente
              }}
              onClick={() => onCategorySelect(category.id)}
            >
              {category.name}
            </li>
          ))}
        </ul>
      </aside>

      <div className="w-full flex-1 p-6">
        <div className="flex items-center justify-between mb-6">
          <h1 className="text-2xl font-bold text-gray-800">
            {categories.find((cat) => cat.id === activeCategoryId)?.name ||
              'Todos os produtos'}
          </h1>
          <div className="text-sm text-gray-500">
            {products.length} {products.length === 1 ? 'produto' : 'produtos'}
          </div>
        </div>

        <div className="mt-4">
          {products.length > 0 ? (
            <ProductCard3 products={products} valuePrice={valuePrice} />
          ) : (
            <div className="bg-gray-50 rounded-lg p-8 text-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-12 w-12 mx-auto text-gray-400 mb-4"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M9.75 17L9 20l-1 1h8l-1-1-.75-3M3 13h18M5 17h14a2 2 0 002-2V5a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                />
              </svg>
              <p className="text-gray-600">
                Nenhum produto encontrado para esta categoria.
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
