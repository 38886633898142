import React, { FormEvent, useState } from 'react';
import { InputText } from 'src/components/InputText';
import { countryOptions } from 'src/components/Country';
import { Select } from 'src/components/Select';
import { UseFormReturn } from 'src/hooks';
import { Button } from 'src/components/Button';
import { FormInputNameRegister } from 'src/features/core/auth/types';
import { Address } from 'src/model/Address';

export interface OptionProps {
  value?: string | number;
  label: string;
  target?: HTMLInputElement;
}
export interface AddressManagerUIProps
  extends Pick<UseFormReturn, 'formData' | 'formErrors' | 'onChangeFormInput'> {
  state: boolean;
  address: Address[];
  onSubmitRegister: (e: FormEvent) => void;
  onEditAddress: (id: string, e: FormEvent) => void;
  onSetAddress: (address: Address) => void;
  onChangeCEP: (value: string) => void;
}

export const AddressManagerUI: React.FC<AddressManagerUIProps> = ({
  state,
  formData,
  formErrors,
  address,
  onChangeFormInput,
  onSubmitRegister,
  onEditAddress,
  onChangeCEP,
  onSetAddress,
}) => {
  const stateOptions: OptionProps[] = [
    { value: 'AC', label: 'Acre' },
    { value: 'AL', label: 'Alagoas' },
    { value: 'AP', label: 'Amapá' },
    { value: 'AM', label: 'Amazonas' },
    { value: 'BA', label: 'Bahia' },
    { value: 'CE', label: 'Ceará' },
    { value: 'DF', label: 'Distrito Federal' },
    { value: 'ES', label: 'Espírito Santo' },
    { value: 'GO', label: 'Goiás' },
    { value: 'MA', label: 'Maranhão' },
    { value: 'MT', label: 'Mato Grosso' },
    { value: 'MS', label: 'Mato Grosso do Sul' },
    { value: 'MG', label: 'Minas Gerais' },
    { value: 'PA', label: 'Pará' },
    { value: 'PB', label: 'Paraíba' },
    { value: 'PR', label: 'Paraná' },
    { value: 'PE', label: 'Pernambuco' },
    { value: 'PI', label: 'Piauí' },
    { value: 'RJ', label: 'Rio de Janeiro' },
    { value: 'RN', label: 'Rio Grande do Norte' },
    { value: 'RS', label: 'Rio Grande do Sul' },
    { value: 'RO', label: 'Rondônia' },
    { value: 'RR', label: 'Roraima' },
    { value: 'SC', label: 'Santa Catarina' },
    { value: 'SP', label: 'São Paulo' },
    { value: 'SE', label: 'Sergipe' },
    { value: 'TO', label: 'Tocantins' },
  ];

  // const [address, setAddresses] = useState<Address[]>([]);

  //   const [newAddress, setNewAddress] = useState<Address>({
  //     id: 0,
  //     name: '',
  //     phone: '',
  //     cep: '',
  //     street: '',
  //     number: '',
  //     complement: '', // Inicializando complemento
  //     neighborhood: '',
  //     city: '',
  //     state: '',
  //     isDefault: false,
  //   });

  const [editingAddressId, setEditingAddressId] = useState<string | null>(null);

  // Função para adicionar ou editar um endereço
  //   const handleSaveAddress = () => {
  //     if (
  //       newAddress.name &&
  //       newAddress.phone &&
  //       newAddress.cep &&
  //       newAddress.street &&
  //       newAddress.number &&
  //       newAddress.neighborhood &&
  //       newAddress.city &&
  //       newAddress.state
  //     ) {
  //       if (editingAddressId) {
  //         setAddresses((prev) =>
  //           prev.map((addr) =>
  //             addr.id === editingAddressId
  //               ? { ...newAddress, id: editingAddressId }
  //               : addr
  //           )
  //         );
  //         setEditingAddressId(null);
  //       } else {
  //         setAddresses([
  //           ...address,
  //           { ...newAddress, id: address.length + 1 },
  //         ]);
  //       }
  //       setNewAddress({
  //         id: 0,
  //         name: '',
  //         phone: '',
  //         cep: '',
  //         street: '',
  //         number: '',
  //         complement: '',
  //         neighborhood: '',
  //         city: '',
  //         state: '',
  //         isDefault: false,
  //       });
  //     }
  //   };

  // Função para remover um endereço
  const handleRemoveAddress = (id: string) => {
    console.log('Removendo endereço', id);
  };

  // Função para começar a edição de um endereço
  const handleEditAddress = (id: string) => {
    const addressToEdit = address.find((addr) => addr.id === id);
    if (addressToEdit) {
      //   setNewAddress(addressToEdit);
      onSetAddress(addressToEdit);
      setEditingAddressId(id);
    }
  };

  return (
    <div className="p-4 max-w-3xl mx-auto">
      <h2 className="text-lg font-semibold mb-4">Meus endereços</h2>

      {/* Lista de endereços */}
      <div>
        {address &&
          address.length > 0 &&
          address.map((address) => (
            <div
              key={address.id}
              className="border p-4 rounded mb-4 flex justify-between items-center"
            >
              <div>
                {/* <h4 className="text-md font-semibold">{address.}</h4>
                <p className="text-gray-600">{address.phone}</p> */}
                <p className="text-gray-600">
                  {address.street}, {address.number}, {address.complement},{' '}
                  {address.country}
                </p>
                <p className="text-gray-600">
                  {address.city} - {address.state}, CEP: {address.zipcode}
                </p>
                {/* {address.isDefault && (
                  <span className="text-red-500 text-xs border border-red-500 px-2 py-1 rounded">
                    Padrão
                  </span>
                )} */}
              </div>
              <div className="flex space-x-4">
                <button
                  onClick={() => handleEditAddress(address.id)}
                  className="text-blue-500 hover:underline"
                >
                  Editar
                </button>
                <button
                  onClick={() => handleRemoveAddress(address.id)}
                  className="text-red-500 hover:underline"
                >
                  Excluir
                </button>
                {/* {!address.isDefault && (
                  <button
                    onClick={() => handleSetDefault(address.id)}
                    className="text-gray-500 hover:text-black"
                  >
                    Definir como padrão
                  </button>
                )} */}
              </div>
            </div>
          ))}
      </div>

      {/* Formulário para adicionar novo endereço */}
      <form
        onSubmit={(e) => {
          if (editingAddressId) {
            onEditAddress(editingAddressId, e);
          } else {
            onSubmitRegister(e);
          }
        }}
        className="border p-4 rounded mb-4 space-y-4"
      >
        <h4 className="text-md font-semibold mb-2">
          {editingAddressId ? 'Editar Endereço' : 'Adicionar Novo Endereço'}
        </h4>
        {/* <InputText
          name="name"
          label="Nome"
          placeholder="Digite seu nome completo..."
          maxLength={100}
          value={formData[FormInputNameRegister.name]}
          onChange={(e) =>
            onChangeFormInput(FormInputNameRegister.name)(e.target.value)
          }
          error={formErrors.name && formErrors.name[0]}
          className=""
          inputClassName=" rounded-lg p-2 w-full bg-[#fafafa] shadow-inner"
        />

        <InputText
          name="phone"
          label="Telefone"
          placeholder="Digite seu telefone..."
          value={formData[FormInputNameRegister.phone]}
          onChange={(e) =>
            onChangeFormInput(FormInputNameRegister.phone)(e.target.value)
          }
          error={formErrors.phone && formErrors.phone[0]}
          className=""
          inputClassName=" rounded-lg p-2 w-full bg-[#fafafa] shadow-inner"
        /> */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <InputText
            name="zipCode"
            label="CEP"
            placeholder="00000-000"
            value={formData[FormInputNameRegister.zipCode]}
            onChange={(e) => {
              onChangeFormInput(FormInputNameRegister.zipCode)(e.target.value);
              onChangeCEP(e.target.value);
            }}
            error={formErrors.zipCode && formErrors.zipCode[0]}
            className=""
            inputClassName=" rounded-lg p-2 w-full bg-[#fafafa] shadow-inner"
          />
          <InputText
            name="street"
            label="Rua/Avenida/Conjunto"
            placeholder="Digite aqui..."
            value={formData[FormInputNameRegister.street]}
            onChange={(e) =>
              onChangeFormInput(FormInputNameRegister.street)(e.target.value)
            }
            error={formErrors.street && formErrors.street[0]}
            className=""
            inputClassName=" rounded-lg p-2 w-full bg-[#fafafa] shadow-inner"
          />
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <InputText
            name="number"
            label="Número"
            placeholder="Digite o número..."
            value={formData[FormInputNameRegister.number]}
            onChange={(e) =>
              onChangeFormInput(FormInputNameRegister.number)(e.target.value)
            }
            error={formErrors.number && formErrors.number[0]}
            className=""
            inputClassName=" rounded-lg p-2 w-full bg-[#fafafa] shadow-inner"
          />
          <InputText
            name="neighborhood"
            label="Bairro"
            placeholder="Digite o bairro..."
            value={formData[FormInputNameRegister.neighborhood]}
            onChange={(e) =>
              onChangeFormInput(FormInputNameRegister.neighborhood)(
                e.target.value
              )
            }
            error={formErrors.neighborhood && formErrors.neighborhood[0]}
            className=""
            inputClassName=" rounded-lg p-2 w-full bg-[#fafafa] shadow-inner"
          />
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <InputText
            name="city"
            label="Cidade"
            placeholder="Digite a cidade..."
            value={formData[FormInputNameRegister.city]}
            onChange={(e) =>
              onChangeFormInput(FormInputNameRegister.city)(e.target.value)
            }
            error={formErrors.city && formErrors.city[0]}
            className=""
            inputClassName=" rounded-lg p-2 w-full bg-[#fafafa] shadow-inner"
          />
          <Select
            name="state"
            label="Estado"
            value={formData[FormInputNameRegister.state]}
            onChange={(e) =>
              onChangeFormInput(FormInputNameRegister.state)(e.target.value)
            }
            error={formErrors.state && formErrors.state[0]}
            options={stateOptions}
            className=""
            selectClassName="rounded-lg p-2 w-full bg-[#fafafa] shadow-inner"
            optionClassName=""
          />
          <Select
            name="country"
            label="Pais"
            placeholder="Selecione..."
            value={formData[FormInputNameRegister.country]}
            onChange={(e) =>
              onChangeFormInput(FormInputNameRegister.country)(
                e?.target.value as string
              )
            }
            className="col-span-2"
            error={formErrors.country && formErrors.country[0]}
            options={countryOptions}
            selectClassName="rounded-lg p-2 w-full bg-[#fafafa] shadow-inner"
            optionClassName=""
          ></Select>
        </div>
        <div className="w-full flex justify-center">
          <Button
            type="submit"
            title={
              editingAddressId ? 'Salvar Alterações' : '+ Adicionar Endereço'
            }
            buttonStyle="red"
            size="lg"
            className="w-[50%]"
            disabled={state}
          />
        </div>
      </form>
    </div>
  );
};
