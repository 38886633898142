import { FormEvent } from 'react';
import { Button } from '../../../../../components/Button';
import { InputText } from '../../../../../components/InputText';
import { FormInputNameCupom } from '../../../../../features/userProfile/types';
import { UseFormReturn } from '../../../../../hooks';
import DiscountCoupon from 'src/model/DiscountCoupon';
import { Checkbox } from 'src/components/Checkbox';

interface CupomCreateUIProps
  extends Pick<UseFormReturn, 'formData' | 'formErrors' | 'onChangeFormInput'> {
  cupons: DiscountCoupon[];
  onSubmitRegister: (e: FormEvent) => void;
  onDeleteCupom: (id: string) => void;
}

export const CupomCreateUI = ({
  formData,
  formErrors,
  cupons,
  onChangeFormInput,
  onSubmitRegister,
  onDeleteCupom,
}: CupomCreateUIProps) => {
  return (
    <div className="w-full mt-2">
      <form
        onSubmit={onSubmitRegister}
        className="flex-col items-center w-full space-y-4"
      >
        <InputText
          name="name"
          label="Nome do Cupom"
          placeholder="Digite o nome do cupom..."
          value={formData[FormInputNameCupom.name]}
          onChange={(e) =>
            onChangeFormInput(FormInputNameCupom.name)(e.target.value)
          }
          error={formErrors.number && formErrors.number[0]}
          inputClassName="rounded-lg p-2 w-full bg-[#fafafa] shadow-inner"
        />

        <InputText
          name="code"
          label="Codigo do cupom"
          placeholder="Digite o codigo do cupom..."
          value={formData[FormInputNameCupom.code]}
          onChange={(e) =>
            onChangeFormInput(FormInputNameCupom.code)(e.target.value)
          }
          error={formErrors.number && formErrors.number[0]}
          inputClassName="rounded-lg p-2 w-full bg-[#fafafa] shadow-inner"
        />

        <InputText
          name="amount"
          label="Quantidade"
          placeholder="Digite ..."
          value={formData[FormInputNameCupom.amount]}
          onChange={(e) =>
            onChangeFormInput(FormInputNameCupom.amount)(e.target.value)
          }
          error={formErrors.number && formErrors.number[0]}
          inputClassName="rounded-lg p-2 w-full bg-[#fafafa] shadow-inner"
        />

        <div className="w-full flex justify-evenly">
          <Checkbox
            name="discountTypeValue"
            label="Valor Fixo"
            value="VALUE"
            checked={formData[FormInputNameCupom.discountType] === 'VALUE'}
            onChange={(value) => {
              onChangeFormInput(FormInputNameCupom.discountType)(
                value === 'true' ? 'VALUE' : ''
              );
            }}
            error={formErrors.discountType && formErrors.discountType[0]}
            checkboxClassName="w-[1rem] h-[1rem] border-red-400 border-[.1rem] accent-red-500 cursor-pointer"
            labelClassName="text-gray-500 text-md font-normal cursor-pointer"
          />
          <Checkbox
            name="discountTypePercentage"
            label="Porcentagem"
            value="PERCENTAGE"
            checked={formData[FormInputNameCupom.discountType] === 'PERCENTAGE'}
            onChange={(value) => {
              onChangeFormInput(FormInputNameCupom.discountType)(
                value === 'true' ? 'PERCENTAGE' : ''
              );
            }}
            error={formErrors.discountType && formErrors.discountType[0]}
            checkboxClassName="w-[1rem] h-[1rem] border-red-400 border-[.1rem] accent-red-500 cursor-pointer"
            labelClassName="text-gray-500 text-md font-normal cursor-pointer"
          />
        </div>

        <InputText
          name="discount"
          label="Desconto"
          placeholder="Digite ..."
          value={formData[FormInputNameCupom.discount]}
          onChange={(e) =>
            onChangeFormInput(FormInputNameCupom.discount)(e.target.value)
          }
          error={formErrors.number && formErrors.number[0]}
          inputClassName="rounded-lg p-2 w-full bg-[#fafafa] shadow-inner"
        />

        <Checkbox
          name="status"
          label="Status Ativo"
          value="status"
          checked={formData[FormInputNameCupom.status] === 'true'}
          onChange={(value) =>
            onChangeFormInput(FormInputNameCupom.status)(value)
          }
          error={formErrors.status && formErrors.status[0]}
          checkboxClassName="w-[1rem] h-[1rem] border-red-400 border-[.1rem] accent-red-500 cursor-pointer"
          labelClassName="text-gray-500 text-md font-normal cursor-pointer"
        />

        <div className="w-full flex justify-center">
          <Button
            type="submit"
            title="Adicionar Cupom"
            buttonStyle="red"
            size="lg"
            className="w-2/4"
            disabled={
              formData.name === '' ||
              formData.code === '' ||
              formData.amount === '' ||
              formData.discountType === '' ||
              formData.discount === '' ||
              formData.amountSold === '' ||
              formData.status === ''
            }
          />
        </div>
      </form>
      <div className="w-full space-y-4 mt-4 border-top-2 border-gray-200 ">
        {cupons.length > 0 ? (
          cupons.map((cupom) => (
            <div
              key={cupom.id}
              className="flex items-center justify-between border-b-2 border-gray-200"
            >
              <div>
                <p className="font-semibold">{cupom.name}</p>
                <p className="text-sm text-gray-600">{`Codigo: ${cupom.code}`}</p>
                <p className="text-sm text-gray-500">{`Desconto: ${cupom.discount}`}</p>
                <p className="text-sm text-gray-500">{`Quantidade: ${cupom.amount}`}</p>
                <p className="text-sm text-gray-500">{`Usados: ${cupom.amountSold}`}</p>
                <p className="text-sm text-gray-500">{`Tipo de Desconto: ${cupom.discountType}`}</p>
                <p className="text-sm text-gray-500">{`Status: ${cupom.status}`}</p>
              </div>
              <div className="flex space-x-2">
                <Button
                  title="Excluir"
                  buttonStyle="red"
                  size="sm"
                  onClick={() => onDeleteCupom(cupom.id)}
                />
              </div>
            </div>
          ))
        ) : (
          <p className="text-center text-gray-500">Nenhum cupom cadastrado.</p>
        )}
      </div>
    </div>
  );
};
