import React from 'react';
import { Route } from 'src/navigation/Route';
import { StoreProfileScreen } from './screens';
//import { StoreProfileDetailsScreen } from './screens/StoreProfileDetailsScreen';

export const STOREPROFILE_ROUTES = {
  itself: '/store-profile',
  details: '/store/:id',
};

export const StoreProfileNavigation = (): JSX.Element => (
  <React.Fragment>
    {/* Rota original */}
    <Route
      exact
      path={STOREPROFILE_ROUTES.itself}
      component={StoreProfileScreen}
      //   isPrivateRoute={true}
    />

    <Route
      exact
      path={STOREPROFILE_ROUTES.details}
      component={StoreProfileScreen}
      //   isPrivateRoute={true}
    />
  </React.Fragment>
);
