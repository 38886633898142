import React, { useState, useEffect } from 'react';
import { ShowModal } from '../Modal';
import { ReviewForm } from '../ReviewForm';
import { StatusDeliveryStep } from 'src/model/StatusDeliveryTypes';
import { StatusTracker } from '../StatusTracker';
import { api } from 'src/services/api';

// Interface para os pedidos de uma loja
interface Order {
  id: string;
  productName: string;
  variation: string;
  price: string;
  status:
    | 'A Pagar'
    | 'Preparando'
    | 'A Caminho'
    | 'Finalizado'
    | 'Cancelado'
    | 'Devolução/Reembolso';
  delivered: boolean;
  imageUrl: string;
  orderDate: string; // Data em que o pedido foi feito
  deliveryDate: string; // Data prevista de entrega
}

interface Store {
  id: string;
  name: string;
  orders: Order[];
}

interface Tab {
  title: string;
  key: string;
}

const tabs: Tab[] = [
  { title: 'Tudo', key: 'all' },
  { title: 'A Pagar', key: 'A Pagar' },
  { title: 'Preparando', key: 'Preparando' },
  { title: 'A Caminho', key: 'A Caminho' },
  { title: 'Finalizado', key: 'Finalizado' },
  { title: 'Cancelado', key: 'Cancelado' },
  { title: 'Devolução/Reembolso', key: 'Devolução/Reembolso' },
];

const mapOrderStatus = (apiStatus: string): Order['status'] => {
  const statusMap: Record<string, Order['status']> = {
    PENDING_PAYMENT: 'A Pagar',
    PROCESSING: 'Preparando',
    SHIPPED: 'A Caminho',
    DELIVERED: 'Finalizado',
    CANCELED: 'Cancelado',
    REFUND: 'Devolução/Reembolso',
  };
  return statusMap[apiStatus] || 'A Pagar';
};

// const stores: Store[] = [
//   {
//     id: '1',
//     name: 'Loja Naturis',
//     orders: [
//       {
//         id: '1-1',
//         productName: 'Proteína Texturizada de Soja Vegana 1kg',
//         variation: '1Kg',
//         price: 'R$ 34,90',
//         status: 'A Caminho',
//         delivered: false,
//         imageUrl: 'https://via.placeholder.com/100',
//         orderDate: '2024-10-10',
//         deliveryDate: '2024-10-15',
//       },
//       {
//         id: '1-2',
//         productName: 'Gengibre Moído Importado 250g',
//         variation: '250g',
//         price: 'R$ 12,90',
//         status: 'Cancelado',
//         delivered: false,
//         imageUrl: 'https://via.placeholder.com/100',
//         orderDate: '2024-10-08',
//         deliveryDate: '2024-10-12',
//       },
//     ],
//   },
//   {
//     id: '2',
//     name: 'TerraVitta',
//     orders: [
//       {
//         id: '2-1',
//         productName: 'Proteína de Soja Sabor Carne 1Kg',
//         variation: '1Kg',
//         price: 'R$ 51,90',
//         status: 'Finalizado',
//         delivered: true,
//         imageUrl: 'https://via.placeholder.com/100',
//         orderDate: '2024-09-30',
//         deliveryDate: '2024-10-05',
//       },
//     ],
//   },
//   // Adicione mais lojas e pedidos conforme necessário
// ];

export const OrderTracking: React.FC = () => {
  const [activeTab, setActiveTab] = useState<string>('all');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isStatusModalOpen, setIsStatusModalOpen] = useState(false);
  const [trackingSteps, setTrackingSteps] = useState<StatusDeliveryStep[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [orders, setOrders] = useState<Order[]>([]);
  const [stores, setStores] = useState<Store[]>([]);
  const [selectedProduct, setSelectedProduct] = useState<string | null>(null);
  const [formData, setFormData] = useState({ description: '' });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [formErrors, setFormErrors] = useState<{ [key: string]: string[] }>({});
  const [rating, setRating] = useState(0);

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await api.get('/order/allbyclient/');
        const data = response.data;
        console.log(data);
        const storesMap = new Map<string, Store>();

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        data.forEach((order: any) => {
          const storeName =
            order.orderItems[0]?.productDTO?.brand?.name || 'Loja Desconhecida';
          const storeId = order.id;

          if (!storesMap.has(storeId)) {
            storesMap.set(storeId, {
              id: storeId,
              name: storeName,
              orders: [],
            });
          }
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          order.orderItems.forEach((item: any) => {
            const formattedOrder: Order = {
              id: item.id,
              productName: item.itemName,
              variation: item.gradeSize?.size || 'Único',
              price: `R$ ${item.totalValue / 100}`,
              status: mapOrderStatus(order.orderStatus),
              delivered: order.orderStatus === 'DELIVERED',
              imageUrl:
                item.productDTO?.imagens?.[0]?.url ||
                'https://via.placeholder.com/100',
              orderDate: new Date(order.orderDate).toLocaleDateString('pt-BR'),
              deliveryDate: '2025-03-10', // TROCAR ISSO, NAO VEM NO RETORNO DA API
            };
            console.log(formattedOrder);
            storesMap.get(storeId)!.orders.push(formattedOrder);
          });
        });

        setStores(Array.from(storesMap.values()));
      } catch (error) {
        console.error('Erro ao buscar os pedidos:', error);
      }
    };

    fetchOrders();
  }, []);
  const handleInputChange = (field: string) => (value: string) => {
    setFormData((prev) => ({ ...prev, [field]: value }));
  };

  const handleRatingChange = (value: number) => {
    setRating(value);
  };

  const filteredStores = stores.map((store) => ({
    ...store,
    orders: store.orders.filter((order) => {
      if (activeTab === 'all') return true;
      return order.status === activeTab;
    }),
  }));

  const hasOrders = filteredStores.some((store) => store.orders.length > 0);

  const openModal = (productName: string) => {
    setSelectedProduct(productName);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedProduct(null);
  };

  const openStatusModal = (steps: StatusDeliveryStep[]) => {
    setTrackingSteps(steps);
    setIsStatusModalOpen(true);
  };

  const handleSubmitReview = (e: React.FormEvent) => {
    e.preventDefault();
    console.log('Avaliação enviada');
    closeModal();
  };

  return (
    <div className="w-full pt-2">
      {/* Tabs no topo */}
      <div className="flex space-x-4 border-b overflow-x-auto whitespace-nowrap scrollbar-hide">
        {tabs.map((tab) => (
          <button
            key={tab.key}
            onClick={() => setActiveTab(tab.key)}
            className={`pb-2 px-4 text-nowrap  ${
              activeTab === tab.key ? 'border-b-2 border-red-500' : ''
            }`}
          >
            {tab.title}
          </button>
        ))}
      </div>

      {/* Conteúdo das lojas e seus pedidos */}
      <div className="mt-6">
        {hasOrders ? (
          filteredStores.map((store) =>
            store.orders.length > 0 ? (
              <div
                key={store.id}
                className="mb-6 border p-4 rounded-sm shadow-sm"
              >
                <h2 className="font-semibold text-lg mb-4">{store.name}</h2>
                {store.orders.map((order) => (
                  <div
                    key={order.id}
                    className="border-b border-red-200 mb-4 p-4"
                  >
                    <div className="flex justify-between items-center flex-wrap">
                      <div className="flex items-center">
                        <img
                          src={order.imageUrl}
                          alt={order.productName}
                          className="w-20 h-20 rounded-lg mr-4"
                        />
                        <div>
                          <h3 className="font-semibold">{order.productName}</h3>
                          <p className="text-sm text-gray-600">
                            Variação: {order.variation}
                          </p>
                          <p className="text-sm text-gray-600">{order.price}</p>
                          {/* Adicionando datas do pedido e previsão de entrega */}
                          <p className="text-sm text-gray-500">
                            Pedido em:{' '}
                            <span className="text-black font-semibold">
                              {order.orderDate}
                            </span>
                          </p>
                          <p className="text-sm text-gray-500">
                            Previsão de entrega:{' '}
                            <span className="text-green-500 font-semibold">
                              {order.deliveryDate}
                            </span>
                          </p>
                        </div>
                      </div>
                      <div>
                        <p
                          className={`text-sm ${
                            order.delivered ? 'text-green-500' : 'text-red-500'
                          }`}
                        >
                          {order.delivered ? 'Pedido entregue' : order.status}
                        </p>
                        <div className="flex space-x-2">
                          <button
                            className="mt-2 bg-red-500 text-white px-4 py-1 rounded"
                            onClick={() =>
                              openStatusModal([
                                {
                                  statusType: 'preparation',
                                  label: 'Preparando',
                                  isCurrent: false, // Indica o passo atual
                                  isCompleted: true, // Indica se o passo foi concluído
                                },
                                {
                                  statusType: 'shipped',
                                  label: 'Enviado',
                                  isCurrent: false, // Indica o passo atual
                                  isCompleted: true, // Indica se o passo foi concluído
                                },
                                {
                                  statusType: 'inTransit',
                                  label: 'Em trânsito',
                                  isCurrent: true, // Indica o passo atual
                                  isCompleted: false, // Indica se o passo foi concluído
                                },
                                {
                                  statusType: 'outForDelivery',
                                  label: 'Saiu para entrega',
                                  isCurrent: false, // Indica o passo atual
                                  isCompleted: false, // Indica se o passo foi concluído
                                },
                                {
                                  statusType: 'delivered',
                                  label: 'Entregue',
                                  isCurrent: false, // Indica o passo atual
                                  isCompleted: false, // Indica se o passo foi concluído
                                },
                              ])
                            }
                          >
                            {order.delivered ? 'Ver detalhes' : 'Acompanhar'}
                          </button>

                          <button
                            className="mt-2 px-4 py-1 rounded bg-[#fff1f1] text-[#F14A3E] hover:bg-[#f3cfcf]  border-red-400 border transition-all duration-300 ease-in-out"
                            onClick={() => openModal(order.productName)}
                          >
                            Avaliar
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
                {/* Botões ao final do grupo de pedidos */}
                <div className="mt-4 flex flex-col items-end">
                  <span className="text-sm text-gray-500 mb-2">
                    Só confirme o recebimento após verificar os itens recebidos.
                  </span>
                  <div className="flex space-x-2">
                    <button className="px-2 py-1 md:px-4 md:py-2 bg-red-500 hover:bg-red-600 text-white rounded-sm md:text-sm ">
                      Pedido Recebido
                    </button>
                    <button className="px-2 py-1 md:px-4 md:py-2 text-red-500 hover:text-red-600 rounded-sm md:text-sm ">
                      Pedido de Reembolso
                    </button>
                    <button className="px-2 py-1 md:px-4 md:py-2  text-red-500 hover:text-red-600 rounded-sm md:text-sm ">
                      Falar com Vendedor
                    </button>
                  </div>
                </div>
              </div>
            ) : null
          )
        ) : (
          <p className="text-center text-gray-500">
            Nenhum pedido para esta aba.
          </p>
        )}
      </div>

      {/* Modal com o ReviewForm */}
      <ShowModal
        visible={isModalOpen}
        onSetVisible={setIsModalOpen}
        title={`Avaliar ${selectedProduct}`}
        showCloseButton
      >
        <ReviewForm
          formData={formData}
          formErrors={formErrors}
          onChangeFormInput={handleInputChange}
          rating={rating}
          onChangeRating={handleRatingChange}
          onSubmitRegister={handleSubmitReview}
        />
      </ShowModal>

      <ShowModal
        visible={isStatusModalOpen}
        onSetVisible={setIsStatusModalOpen}
        title="Status do Pedido"
        showCloseButton
      >
        <StatusTracker steps={trackingSteps} />
      </ShowModal>
    </div>
  );
};
