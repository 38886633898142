import React from 'react';
import { AuthWrapper } from 'src/features/core/auth/screen/AuthWrapper';
import { SidebarProductScreen } from 'src/features/storeProfile/components/StoreProfileSideMenu';
import { Product } from 'src/model/Product';

interface StoreProfileUIProps {
  storeName: string;
  productCount: number;
  products: Product[];
  valuePrice: (product: Product, discount: boolean) => string;
  storeDescription: string;
  storeEmail: string;
  storePhone: string;
  storeImage: string;
  imageDetail: string;
  imageBanner: string;
}

export const StoreProfileUI: React.FC<StoreProfileUIProps> = ({
  storeName,
  products,
  valuePrice,
  storeDescription,
  imageDetail,
  imageBanner,
}) => {
  return (
    <AuthWrapper>
      <div className="w-full">
        {/* Full-width banner with store info overlay */}
        <div className="relative w-full">
          {/* Dark background with pattern */}
          <div className="w-full h-28 bg-gray-900 overflow-hidden">
            {/* Circular pattern background */}
            <img
              src={
                imageBanner
                  ? `https://sandbox.buunpsgpsystem.com.br/uploads/${imageBanner}`
                  : 'https://via.placeholder.com/100'
              }
              alt="Banner da loja"
              className="w-full h-full object-cover object-center opacity-30"
            />
          </div>

          <div className="absolute top-4 left-8 z-20 flex items-center gap-3 bg-white rounded-lg p-2 shadow-md">
            <img
              src={
                imageDetail
                  ? `https://sandbox.buunpsgpsystem.com.br/uploads/${imageDetail}`
                  : 'https://via.placeholder.com/100'
              }
              alt={`${storeName} Logo`}
              className="w-12 h-12 rounded-full object-cover"
            />
            <div>
              <div className="flex items-center gap-2">
                <span className="text-xs text-blue-600 font-medium flex items-center">
                  <svg
                    className="w-3 h-3 mr-0.5"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                  >
                    <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41L9 16.17z" />
                  </svg>
                  Loja oficial
                </span>
              </div>
              <h1 className="text-lg font-bold text-gray-900">{storeName}</h1>
            </div>
          </div>
        </div>

        {/* Content */}
        <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-6">
          {/* Store description */}
          <section className="mb-8" id="sobre">
            <div className="bg-white shadow-sm rounded-lg p-6">
              <h2 className="text-lg font-semibold text-gray-900 mb-4">
                Sobre a loja
              </h2>
              <p className="text-gray-700 leading-relaxed">
                {storeDescription}
              </p>

              {/* <div className="mt-6 grid grid-cols-1 md:grid-cols-2 gap-4">
                <div className="flex items-center text-gray-700">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5 mr-2 text-gray-500"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                    />
                  </svg>
                  <span>
                    Email:{' '}
                    <a
                      href={`mailto:${storeEmail}`}
                      className="text-gray-900 hover:underline"
                    >
                      {storeEmail}
                    </a>
                  </span>
                </div>
                <div className="flex items-center text-gray-700">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5 mr-2 text-gray-500"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                    />
                  </svg>
                  <span>
                    Telefone:{' '}
                    <a
                      href={`tel:${storePhone}`}
                      className="text-gray-900 hover:underline"
                    >
                      {storePhone}
                    </a>
                  </span>
                </div>
              </div> */}
            </div>
          </section>

          {/* Products section */}
          <div id="produtos">
            <SidebarProductScreen products={products} valuePrice={valuePrice} />
          </div>
        </div>
      </div>
    </AuthWrapper>
  );
};
