import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Product } from 'src/model/Product';
import { setLoading } from 'src/redux/loading/loadingSlice';
import { api } from 'src/services/api';
import { ProductUI } from './ui';
import Client from 'src/model/User';
import { toCurrency } from 'src/helpers/currency/toCurrency';
import { CartGrade, CartProduct } from 'src/model/CartProduct';
import { Grade } from 'src/model/Grade';

interface Params {
  id: string;
}

interface ProductAddCart {
  id: string;
  product: CartProduct;
  quantity: number;
  totalValue: number;
}

export interface SelectGrade {
  color: string;
  id: string;
}

export interface SelectSize {
  id: string;
  size: string;
}

export const ProductScreen: React.FC = (): JSX.Element => {
  const { id } = useParams<Params>();
  const [products, setProdutos] = useState<Product>();
  const [quantity, setQuantity] = useState<number>(1);
  const dispatch = useDispatch();
  const [users, setUsers] = useState<Client>({} as Client);
  const [shippingCost, setShippingCost] = useState<string>('');
  const [selectedImage, setSelectedImage] = useState<string>('');
  const [selectedColor, setSelectedColor] = useState<SelectGrade>(
    {} as SelectGrade
  );
  const [selectedSize, setSelectedSize] = useState<SelectSize>(
    {} as SelectSize
  );
  const [zoomStyle, setZoomStyle] = useState<React.CSSProperties>({});

  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const rect = e.currentTarget.getBoundingClientRect();
    const x = ((e.clientX - rect.left) / rect.width) * 100;
    const y = ((e.clientY - rect.top) / rect.height) * 100;
    setZoomStyle({
      backgroundPosition: `${x}% ${y}%`,
      backgroundSize: '200%', // Ajuste o nível de zoom aqui
    });
  };

  const handleMouseLeave = () => {
    setZoomStyle({});
  };

  const getProduct = async (): Promise<void> => {
    dispatch(setLoading(true));
    try {
      const response = await api.get<Product>(`/product/one/${id}`);
      console.log(response.data);
      if (response.status === 200) {
        const data = response.data;
        const addImagesGrade: string[] = [];
        data.grade.forEach((grade) => {
          if (grade.image) {
            addImagesGrade.push(
              'https://sandbox.buunpsgpsystem.com.br/uploads/' + grade.image
            );
          }
        });
        data.imagens = [...data.imagens];
        setProdutos(data);
        setSelectedImage(data.imagens[0]);
      }
    } catch (error) {
      console.error('Erro ao buscar categoria:', error);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleColorSelect = (color?: string, image?: string, id?: string) => {
    if (color && id) {
      setSelectedColor({ color, id });
    }
    setSelectedSize({} as SelectSize);
    if (image) {
      setSelectedImage(image);
    }
  };

  const handleSizeSelect = (size: string, id: string): void => {
    setSelectedSize({ id, size });
  };
  const handleQuantityChange = (increment: boolean) => {
    setQuantity((prev) => Math.max(1, prev + (increment ? 1 : -1)));
  };

  const getPrice = (
    grade: Grade[],
    selectedColor: string,
    selectedSize: string
  ): number => {
    let price = 0;
    for (let i = 0; i < grade.length; i++) {
      for (let s = 0; s < grade[i].gradeSizes.length; s++) {
        if (
          selectedColor === grade[i].color &&
          selectedSize === grade[i].gradeSizes[s].size
        ) {
          price = grade[i].gradeSizes[s].price.price;
        }
      }
    }
    return price;
  };

  const handleAddCart = async (): Promise<void> => {
    console.log('Iniciando o processo de adicionar ao carrinho...');

    if (products && quantity > 0) {
      try {
        dispatch(setLoading(true));
        let isGradeSelect = true;

        // Verificação das grades
        console.log('Verificando grades...');
        products.grade.forEach((grade) => {
          console.log(`Verificando grade: ${grade.name}, cor: ${grade.color}`);
          if (grade.name === 'UNICO' && grade.color === 'UNICO') {
            isGradeSelect = false;
          }
        });
        console.log(`isGradeSelect após a verificação: ${isGradeSelect}`);

        if (isGradeSelect && !selectedColor.color) {
          console.log('Cor não selecionada, mostrando mensagem de erro.');
          toast.error('Selecione uma cor');
          return;
        }

        if (isGradeSelect && !selectedSize.size) {
          console.log('Tamanho não selecionado, mostrando mensagem de erro.');
          toast.error('Selecione um tamanho');
          return;
        }

        // Obtendo o preço
        const price = getPrice(
          products.grade,
          selectedColor.color,
          selectedSize.size
        );
        console.log(`Preço obtido: ${price}`);

        // Montando o CartGrade
        const CartGrade: CartGrade = {
          id: selectedColor.id,
          gradeSizeId: selectedSize.id,
          name: isGradeSelect ? '' : 'UNICO',
          color: isGradeSelect ? selectedColor.color : 'UNICO',
          size: isGradeSelect ? selectedSize.size : 'unico',
          price,
        };
        console.log('CartGrade:', CartGrade);

        // Montando o CartProduct
        const CartPoduct: CartProduct = {
          id: products.id,
          name: products.name,
          quantity,
          totalValue: price * quantity,
          grade: CartGrade,
        };
        console.log('CartProduct:', CartPoduct);

        // Montando o payload
        const payload: ProductAddCart = {
          id: isGradeSelect ? selectedColor.id : '',
          product: CartPoduct,
          quantity: quantity,
          totalValue: price * quantity,
        };
        console.log('Payload:', payload);

        // Enviando o pedido para a API
        const response = await api.post('/order/add-carts', payload);
        console.log(response);
        console.log(response.status);
        if (response.status === 201 || response.status === 200) {
          console.log('Produto adicionado com sucesso ao carrinho.');
          toast.success('Produto adicionado ao carrinho');
        } else {
          console.log(
            'Erro ao adicionar produto ao carrinho. Status:',
            response.status
          );
          toast.error('Erro ao adicionar produto ao carrinho');
        }
      } catch (error) {
        console.error('Erro ao adicionar produto ao carrinho:', error);
      } finally {
        dispatch(setLoading(false));
      }
    } else {
      console.log('Produto ou quantidade inválidos');
    }
  };

  const getUser = async (): Promise<void> => {
    dispatch(setLoading(true));
    try {
      const response = await api.get<Client>('/user/findeone');
      if (response.status === 200) {
        setUsers(response.data);
      }
    } catch (error) {
      console.error('Erro ao buscar usuarios:', error);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleShippingCalc = async (
    altura: string,
    largura: string,
    profundidade: string,
    peso: string
  ) => {
    try {
      console.log('Entrou handleShippingCalc');
      const payload = { altura, largura, profundidade, peso };

      const response = await api.post('/product/calc-frete', payload);

      setShippingCost(toCurrency(response.data.frete));
    } catch (error) {
      console.error('Erro ao calcular frete:', error);
      setShippingCost('Erro ao calcular o frete.');
    }
  };

  useEffect(() => {
    const userAddress = users.address?.map(
      (user) => user.city === products?.seller.city
    );

    if (userAddress) {
      handleShippingCalc(
        products?.dimensions.height ?? '',
        products?.dimensions.width ?? '',
        products?.dimensions.depth ?? '',
        products?.dimensions.weight ?? ''
      );
    }
  }, [users.address, products?.dimensions, products?.seller.city]);

  useEffect(() => {
    getProduct();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getUser();
  }, []);

  return (
    <>
      {products ? (
        <ProductUI
          products={products}
          onAddCart={handleAddCart}
          quantity={quantity}
          quantityChange={handleQuantityChange}
          user={users}
          shippingCost={shippingCost}
          selectedImage={selectedImage}
          selectedColor={selectedColor}
          selectedSize={selectedSize}
          handleColorSelect={handleColorSelect}
          handleSizeSelect={handleSizeSelect}
          zoomStyle={zoomStyle}
          handleMouseMove={handleMouseMove}
          handleMouseLeave={handleMouseLeave}
        />
      ) : (
        <p>Carregando produto...</p>
      )}
    </>
  );
};
