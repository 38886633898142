import React from 'react';
// import { Button } from 'src/components/Button';
// import { InputText } from 'src/components/InputText';
import { AuthWrapper } from 'src/features/core/auth/screen/AuthWrapper';
import { toCurrency } from 'src/helpers/currency/toCurrency';
import { CartView } from '..';
import { Link } from 'react-router-dom';

interface CartUIProps {
  cart?: CartView[];
  cartTotal: number;
  onRemoveItem: (id: string) => void;
  goCheckout: () => void;
  onIncreaseQuantity: (id: string) => void;
  onDecreaseQuantity: (id: string) => void;
  auth?: boolean;
  title?: string;
  showTitle?: boolean;
}

export const CartUI: React.FC<CartUIProps> = ({
  cartTotal,
  onRemoveItem,
  goCheckout,
  onIncreaseQuantity,
  onDecreaseQuantity,
  cart,
  auth = true,
  title = 'Meu Carrinho',
  showTitle = true,
}): JSX.Element => {
  return auth ? (
    <AuthWrapper>
      <div className="container px-2 py-4 m-4 bg-white rounded-lg  w-full">
        {showTitle && (
          <h2 className="text-3xl font-bold text-gray-800 mb-6 text-center">
            {title}
          </h2>
        )}
        <div>
          <div className="mb-6 border border-red-100 p-4 rounded-sm shadow-sm">
            {/* <h2 className="font-semibold text-lg mb-4">
                 {storeName || 'Loja Desconhecida'}
               </h2> */}
            {cart &&
              cart.length > 0 &&
              cart.map((item, index) => (
                <div key={index} className="border-b border-red-200 mb-4 p-4">
                  <div className="flex justify-between items-center flex-wrap">
                    <Link
                      to={`/product/${item.productId}`}
                      className="flex flex-col gap-3"
                    >
                      <div className="flex items-center">
                        <img
                          src={item.image}
                          alt={item.name}
                          className="w-20 h-20 rounded-lg object-cover mr-4"
                        />
                        <div>
                          <h3 className="font-semibold text-lg text-gray-800">
                            {item.name}
                          </h3>
                          <p className="text-sm text-gray-600">
                            Vendido por:{' '}
                            <span className="font-semibold text-gray-800">
                              {item.seller?.name || 'Loja Desconhecida'}
                            </span>
                          </p>

                          <p className="text-sm text-gray-600">
                            <span className="font-medium">Total:</span>{' '}
                            <span className="text-green-600 font-bold">
                              R$ {toCurrency(item.price * item.quantity)}
                            </span>
                          </p>
                        </div>
                      </div>
                    </Link>
                    <div className="flex items-center space-x-2">
                      {/* Botão de diminuir quantidade */}
                      {item.quantity > 1 && (
                        <button
                          onClick={() => onDecreaseQuantity(item.cartItemId)}
                          className="bg-gray-300 text-gray-700 px-3 py-1 rounded hover:bg-gray-400 transition duration-200"
                        >
                          -
                        </button>
                      )}
                      <p className="text-sm text-gray-600">
                        <span className="font-medium">Qtde:</span>{' '}
                        <span className="text-black ">{item.quantity}</span>
                      </p>
                      {/* Botão de aumentar quantidade */}
                      <button
                        onClick={() => onIncreaseQuantity(item.cartItemId)}
                        className="bg-green-500 text-white px-3 py-1 rounded hover:bg-green-600 transition duration-200"
                      >
                        +
                      </button>
                      {/* Botão de remover */}
                      <button
                        onClick={() => onRemoveItem(item.cartItemId)}
                        className="bg-red-500 text-white px-4 py-1 rounded shadow-md hover:bg-red-600 transition duration-200"
                      >
                        Remover
                      </button>
                    </div>
                  </div>
                </div>
              ))}
          </div>
          <div className="flex flex-col sm:flex-row justify-between items-center mt-6 border-t border-gray-200 pt-4">
            <h3 className="text-xl font-bold text-gray-800">
              Total do Carrinho:{' '}
              <span className="text-green-600">R$ {toCurrency(cartTotal)}</span>
            </h3>
            <button
              onClick={() => goCheckout()}
              className="bg-red-500 text-white py-2 px-6 rounded-lg shadow-lg hover:bg-red-600 transition duration-200 mt-4 sm:mt-0"
            >
              Finalizar Compra
            </button>
          </div>
        </div>
      </div>
    </AuthWrapper>
  ) : (
    <div className="text-center py-10">
      <p className="text-gray-500 text-lg">
        Seu carrinho está vazio. Explore nossos produtos e adicione ao carrinho!
      </p>
    </div>
  );
};
