import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { onlyNumbers } from 'src/helpers/common';
import { getBoolean } from 'src/helpers/common/localStorage';
import { encrypt } from 'src/helpers/crypt/crypt';
import {
  getCreditCardLabel,
  updateHolderName,
  updateMaskCVV,
  updateMaskCard,
  validateCVV,
} from 'src/helpers/masks/cardNumber';
import { updateMask as cpfCnpjMask } from 'src/helpers/masks/cpfCnpj';
import { updateMask as expirationDateMask } from 'src/helpers/masks/expirationDate';
import validators from 'src/helpers/validators';
import useForm from 'src/hooks/useForm';
import { Address } from 'src/model/Address';
import Card from 'src/model/Card';
import CardClient from 'src/model/CardClient';
import CardClientCrypt from 'src/model/CardClientCrypt';
import { Cart } from 'src/model/Cart';
import { CartGrade, CartProduct } from 'src/model/CartProduct';
import { CorreiosCode } from 'src/model/CorreiosCode';
import { DiscountType } from 'src/model/DiscountType';
import OrderPayment from 'src/model/OrderPayment';
import PaymentType from 'src/model/PaymentType';
import StatusType from 'src/model/StatusType';
import Client from 'src/model/User';
import { setLoading } from 'src/redux/loading/loadingSlice';
import { api } from 'src/services/api';
import { REACT_APP_AUTH } from 'src/utils/config';
import { FormInputNameCreditCard } from '../../types';
import DiscountCoupon from './../../../../model/DiscountCoupon';
import { PaymentBasic } from './../../../../model/PaymentBasic';
import { CheckoutUI } from './ui';
import { CartView } from '../Cart';

export interface Summary {
  originalPrice: number;
  savings: number;
  storePickup: number;
  tax: number;
  total: number;
}

interface TotalValueCart {
  totalValue: number;
  totalDiscount: number;
  total: number;
}

export const CheckoutScreen: React.FC = (): JSX.Element => {
  const [signed, setSigned] = useState<boolean>(
    getBoolean(String(REACT_APP_AUTH))
  );
  const dispatch = useDispatch();
  const [addres, setAddress] = useState<Address[]>([]);
  const [selectedAddress, setSelectedAddress] = useState<string | undefined>();
  const [carts, setCarts] = useState<Cart[]>([]);
  const [user, setUser] = useState<Client>();
  const [selectedCard, setSelectedCard] = useState<number>();
  const [addNewCard, setAddNewCard] = useState<boolean>(false);
  const [summary, setSummary] = useState<Summary>({
    originalPrice: 0,
    savings: 0,
    storePickup: 0,
    tax: 0,
    total: 0,
  });
  const [cart, setCart] = useState<CartView[]>([]);
  const [card, setCard] = useState<CardClient>();
  const [discountCoupon, setDiscountCoupon] = useState<DiscountCoupon>();
  const [paymentType, setPaymentType] = useState<PaymentType>();
  const [selectedCreditCard, setSelectedCreditCard] = useState<boolean>(false);
  const [selectedPix, setSelectedPix] = useState<boolean>(false);
  const [discount, setDiscount] = useState<number>(0);
  //   const [cartTotal, setCartTotal] = useState<number>(0);
  const [totalAfterDiscount, setTotalAfterDiscount] = useState<number>();

  const history = useHistory();
  const isAuth = async (): Promise<void> => {
    setTimeout(async () => {
      if (getBoolean(String(REACT_APP_AUTH)) !== signed) {
        setSigned(getBoolean(String(REACT_APP_AUTH)));
        return;
      } else {
        isAuth();
      }
    }, 500);
  };
  const handleSelectedCard = (id: number, cardSelected: CardClient): void => {
    if (cardSelected && cardSelected) {
      setSelectedCard(id);
      setCard(cardSelected);
    }
  };

  //   const handleCalculateItemTotal = (cart: CartView[]): number => {
  //     let result = 0;
  //     for (let i = 0; i < cart.length; i++) {
  //       result += cart[i].price * cart[i].quantity;
  //     }
  //     return result;
  //   };

  const getUser = async (): Promise<void> => {
    if (signed) {
      dispatch(setLoading(true));
      try {
        const response = await api.get<Client>(`/user/findeone`);
        if (response.status === 200) {
          setUser(response.data);
          if (response.data.cart && response.data.cart.length > 0) {
            const cart = response.data.cart;
            setCarts(cart);
            const cartView: CartView[] = [];

            cart.forEach((item) => {
              const id = item.products.id;
              const cartItemId = item.cartItemId;
              const productId = item.products.id;
              const image =
                'https://sandbox.buunpsgpsystem.com.br/uploads/' + item.image;
              const name = item.cartProduct.name;
              const nameGrade = item.cartProduct.grade.color
                ? item.cartProduct.grade.color
                : item.cartProduct.grade.name;
              const size = item.cartProduct.grade.size;
              const price = item.cartProduct.grade.price;
              const quantity = item.cartProduct.quantity;
              const store = item.products.seller.name;
              const seller = item.products.seller;
              cartView.push({
                id,
                cartItemId,
                productId,
                image,
                name,
                nameGrade,
                size,
                price,
                quantity,
                store,
                seller,
              });
            });
            // setCartTotal(handleCalculateItemTotal(cartView));
            setCart(cartView);
          }
          if (response.data.address) {
            setAddress(response.data.address);
          }
        }
      } catch (error) {
        console.error('Erro ao buscar o usuario:', error);
      } finally {
        dispatch(setLoading(false));
      }
    } else {
      isAuth();
    }
  };

  const onSubmitAddCreditCard = async (e: React.FormEvent): Promise<void> => {
    e.preventDefault();
    if (isFormValidAddCreditCard()) {
      if (
        validateCVV(
          formDataAddCreditCard[FormInputNameCreditCard.number],
          formDataAddCreditCard[FormInputNameCreditCard.cvv]
        )
      ) {
        try {
          dispatch(setLoading(true));
          const expDate =
            formDataAddCreditCard[FormInputNameCreditCard.date].split('/');

          const card: Card = {
            holder: formDataAddCreditCard[FormInputNameCreditCard.name],
            number: onlyNumbers(
              formDataAddCreditCard[FormInputNameCreditCard.number]
            ),
            expMonth: expDate[0],
            expYear: `20${expDate[1]}`,
            securityCode: formDataAddCreditCard[FormInputNameCreditCard.cvv],
            document: onlyNumbers(
              formDataAddCreditCard[FormInputNameCreditCard.document]
            ),
            cardType: getCreditCardLabel(
              formDataAddCreditCard[FormInputNameCreditCard.number]
            ),
          };
          const encrypted = encrypt(JSON.stringify(card));

          const cardClientCrypt: CardClientCrypt = {
            data: encrypted,
          };

          const responseCardClient = await api.post<CardClient>(
            '/client-card',
            cardClientCrypt
          );
          await getUser();
          // const responseEncrypted = await api.get(
          //   `/client-card/find-one/${responseCardClient.data.id}`
          // );
          // const cardDB = JSON.parse(
          //   decrypt(responseEncrypted.data.data)
          // ) as CardDb;
          setTimeout(() => {
            setSelectedCard(parseInt(String(responseCardClient.data.id)));
          }, 500);
          resetFormAddCreditCard();
          // onSetVisible(false);
          dispatch(setLoading(false));
          toast.success('Cartão adicionado com sucesso!');
          //eslint-disable-next-line
        } catch (error: any) {
          console.error('Erro ao adicionar o cartão:', error);
          setErrorsAddCreditCard({
            ['document']: ['Algo deu errado'],
          });
        } finally {
          dispatch(setLoading(false));
        }
      } else {
        toast.error('Código de segurança inválido!');
        resetFormAddCreditCard();
      }
    }
  };

  const handleSelectAddress = (id: string): void => {
    setSelectedAddress(id);
  };

  const {
    formData: formDataAddCreditCard,
    formErrors: formErrorsAddCreditCard,
    setErrors: setErrorsAddCreditCard,
    onChangeFormInput: onChangeFormInputAddCreditCard,
    isFormValid: isFormValidAddCreditCard,
    resetForm: resetFormAddCreditCard,
  } = useForm({
    initialData: {
      number: '',
      date: '',
      cvv: '',
      name: '',
      document: '',
    },
    validators: {
      number: [validators.required, validators.cardNumber],
      date: [validators.required, validators.cardExpirationDate],
      cvv: [validators.required, validators.cvv],
      name: [validators.required],
      document: [validators.required, validators.cpforcnpj],
    },
    formatters: {
      number: updateMaskCard,
      date: expirationDateMask,
      cvv: updateMaskCVV,
      name: updateHolderName,
      document: cpfCnpjMask,
    },
  });

  const {
    formData: formDataCoupon,
    formErrors: formDataCouponErrors,
    onChangeFormInput: onChangeFormInputCouponCreditCard,
    isFormValid: isFormValidCouponCreditCard,
  } = useForm({
    initialData: {
      code: '',
    },
    validators: {
      code: [validators.required, validators.minLength(6)],
    },
  });

  const onCouponInputChange = async (code: string): Promise<void> => {
    dispatch(setLoading(true));
    try {
      if (isFormValidCouponCreditCard()) {
        const response = await api.get<DiscountCoupon>(
          `/discount-coupon/find/${code}`
        );
        if (response.status === 200) {
          if (response.data.discount) {
            setDiscountCoupon(response.data);
            setDiscount(response.data.discount);
          } else {
            setDiscount(0);
          }
        }
      }
      // eslint-disable-next-line
    } catch (error: any) {
      if (error.response && error.response.status === 404) {
        const message = error.response.data.message || 'Cupom não encontrado';
        toast.error(message);
      } else {
        toast.error('Erro ao buscar o cupom de desconto. Tente novamente.');
      }
      console.error('Erro ao buscar o cupom de desconto:', error);
    } finally {
      dispatch(setLoading(false));
    }
  };

  let debounceTimeout: NodeJS.Timeout;
  const handleDiscountCoupon = (code: string): void => {
    clearTimeout(debounceTimeout);
    debounceTimeout = setTimeout(() => {
      onCouponInputChange(code);
    }, 500);
  };

  const handleOnAddCard = (): void => {
    if (addNewCard === true) {
      setAddNewCard(false);
    } else {
      setAddNewCard(true);
    }
  };

  //   const handleCalculateItemTotal = (
  //     price: number,
  //     quantity: number
  //   ): number => {
  //     if (!price || !quantity) return 0;
  //     return price * quantity;
  //   };

  const calculetotalBuy = (cart: Cart[]): TotalValueCart => {
    let totalValue: number = 0;
    let totalDiscount: number = 0;
    let total: number = 0;
    cart.forEach((item) => {
      total += item.cartProduct.totalValue;
      for (let i = 0; i < item.products.grade.length; i++) {
        for (let j = 0; j < item.products.grade[i].gradeSizes.length; j++) {
          if (
            item.products.grade[i].gradeSizes[j].size ===
              item.cartProduct.grade.size &&
            item.products.grade[i].color === item.cartProduct.grade.color
          ) {
            totalValue += item.products.grade[i].gradeSizes[j].price.discount;
            if (item.products.grade[i].gradeSizes[j].price.discount) {
              totalDiscount +=
                item.products.grade[i].gradeSizes[j].price.discount -
                item.products.grade[i].gradeSizes[j].price.price;
            }
          }
        }
      }
    });
    return { totalValue, totalDiscount, total };
  };

  //   const getPriceAndDiscountByColorAndSize = (
  //     color: string,
  //     size: string,
  //     item: Cart
  //   ) => {
  //     const foundGrade = item.products.grade.find(
  //       (grade) =>
  //         grade.color === color &&
  //         grade.gradeSizes.some((gradeSize) => gradeSize.size === size)
  //     );

  //     if (foundGrade) {
  //       const matchingSize = foundGrade.gradeSizes.find(
  //         (gradeSize) => gradeSize.size === size
  //       );

  //       if (matchingSize) {
  //         return {
  //           price: matchingSize.price,
  //           discount: matchingSize.price.discount,
  //         };
  //       }
  //     }

  //     return null;
  //   };

  const handleCalculateCartTotal = (): void => {
    const { totalValue, totalDiscount, total } = calculetotalBuy(carts);
    const summary: Summary = {
      originalPrice: total,
      savings: totalDiscount,
      storePickup: 0,
      tax: 0,
      total: totalValue,
    };
    let showTotal: number = totalValue;
    if (
      discountCoupon &&
      discountCoupon.discount &&
      discountCoupon.discount > 0 &&
      discountCoupon.status === StatusType.ACTIVE
    ) {
      const discountValue =
        discountCoupon.discountType === DiscountType.VALUE
          ? discountCoupon.discount
          : (totalValue * discountCoupon.discount) / 100;
      showTotal = Math.max(totalValue - discountValue, 0);
    }

    setTotalAfterDiscount(showTotal);
    setSummary(summary);
  };

  const handlePayment = async (): Promise<void> => {
    try {
      dispatch(setLoading(true));
      if (carts && carts.length > 0) {
        // if (!selectedCard && !card) {
        //   toast.error('Selecione um cartão de crédito');
        //   return;
        // }
        const productsCart: CartProduct[] = [];
        for (const cart of carts) {
          if (!cart.products || !cart.cartProduct) continue;
          const cartAddGrade: CartGrade = {
            id: cart.cartProduct.grade.id,
            gradeSizeId: cart.cartProduct.grade.gradeSizeId,
            name: cart.cartProduct.grade.color,
            color: cart.cartProduct.grade.color,
            size: cart.cartProduct.grade.size,
            price: cart.cartProduct.grade.price,
          };
          const cartAddProduct: CartProduct = {
            id: cart.products.id,
            name: cart.products.name,
            quantity: cart.cartProduct.quantity,
            totalValue: cart.cartProduct.totalValue,
            grade: cartAddGrade,
          };
          productsCart.push(cartAddProduct);
        }

        const { totalValue, totalDiscount } = calculetotalBuy(carts);
        const amountCart =
          totalDiscount > 0 ? totalValue - totalDiscount : totalValue;
        if (card && paymentType === PaymentType.CREDIT_CARD) {
          // const { REACT_APP_PAGSEGURO_TOKEN } = process.env;
          const expirationDate = card.cardExpirationDate.split('/');
          const cardPagSeguro = PagSeguro.encryptCard({
            // publicKey: REACT_APP_PAGSEGURO_TOKEN,
            holder: card?.cardholderName,
            number: onlyNumbers(card.cardNumber),
            expMonth: expirationDate[0],
            expYear: expirationDate[1],
            securityCode: card.cardCvv,
          });
          const paymentBasic: PaymentBasic = {
            paymentType: paymentType,
            installments: 1,
            paymentValue: amountCart,
            clientCardId: card.id,
            encrypted: cardPagSeguro.encryptedCard,
          };
          if (totalAfterDiscount && totalAfterDiscount > 0) {
            const payload: OrderPayment = {
              product: productsCart,
              quantity: productsCart.length,
              discopuntCoupon: discountCoupon ? discountCoupon : undefined,
              payments: paymentBasic,
              paymentMethod: paymentType,
              correiosService: CorreiosCode.SEDEX_CONTRATO,
              totalValue: totalAfterDiscount,
            };

            console.log(payload);

            const response = await api.post('/order', payload);
            if (response.status === 201 || response.status === 200) {
              toast.success('Pedido realizado com sucesso');
              // TODO
              // Definir para onde navegar após o pedido ser realizado
              history.push('/user-profile');
            }
          } else {
            toast.error('Carrinho não definido!');
          }
        }
      }
      // eslint-disable-next-line
    } catch (error: any) {
      if (error.response && error.response.status === 404) {
        const message = error.response.data.message || 'Cupom não encontrado';
        toast.error(message);
      } else {
        toast.error(
          'Ocorreu um erro ao finalizar sua compra. Tente novamente mais tarde'
        );
      }
      console.error(error.response);
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handlePaymentType = (type: PaymentType): void => {
    setPaymentType(type);

    if (type === PaymentType.CREDIT_CARD) {
      setSelectedCreditCard(true);
      setSelectedPix(false);
    } else if (type === PaymentType.PIX) {
      setSelectedPix(true);
      setSelectedCreditCard(false);
    } else {
      setSelectedCreditCard(false);
      setSelectedPix(false);
    }
  };

  useEffect(() => {
    isAuth();
    // eslint-disable-next-line
  }, [signed]);

  useEffect(() => {
    getUser();
    // eslint-disable-next-line
  }, [signed]);

  useEffect(() => {
    handleCalculateCartTotal();
    // eslint-disable-next-line
  }, [carts]);

  return user ? (
    <CheckoutUI
      formData={formDataAddCreditCard}
      formErrors={formErrorsAddCreditCard}
      onChangeFormInput={onChangeFormInputAddCreditCard}
      onAddCard={handleOnAddCard}
      addNewCard={addNewCard}
      selectedCard={selectedCard}
      onSelectCard={handleSelectedCard}
      onSubmitRegister={onSubmitAddCreditCard}
      user={user}
      cartView={cart}
      carts={carts}
      addresses={addres}
      selectedAddress={selectedAddress}
      onSelectAddress={handleSelectAddress}
      summary={summary}
      onPayment={handlePayment}
      onApplyCoupon={handleDiscountCoupon}
      onSelectType={handlePaymentType}
      selectedCreditCard={selectedCreditCard}
      selectedPix={selectedPix}
      discount={discount}
      totalAfterDiscount={totalAfterDiscount}
      formDataCoupon={formDataCoupon}
      formDataCouponErrors={formDataCouponErrors}
      onChangeFormCouponInput={onChangeFormInputCouponCreditCard}
    />
  ) : (
    <p>sem produtos</p>
  );
};
