import React from 'react';
import { Link } from 'react-router-dom';

interface MenuLink {
  text: string;
  href: string;
  iconLeft?: React.ReactNode;
  iconRight?: React.ReactNode;
}

interface SubmenuProps {
  links: MenuLink[];
  onLinkClick?: (href: string) => void;
  className?: string;
  liClassName?: string;
}

export const Submenu: React.FC<SubmenuProps> = ({
  links = [],
  onLinkClick,
  className = '',
  liClassName = '',
}) => (
  <ul className={`${className}`}>
    {links.map((link, index) => {
      const isExternal = link.href.startsWith('http');

      return (
        <li key={index} className={`${liClassName}`}>
          {isExternal ? (
            <a
              href={link.href}
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center"
              onClick={(e) => e.stopPropagation()}
            >
              {link.iconLeft && <span className="mr-1">{link.iconLeft}</span>}
              {link.text}
              {link.iconRight && <span className="ml-1">{link.iconRight}</span>}
            </a>
          ) : (
            <Link
              to={link.href}
              className="flex items-center"
              onClick={(e) => {
                e.preventDefault();
                if (onLinkClick) {
                  onLinkClick(link.href);
                }
              }}
            >
              {link.iconLeft && <span className="mr-1">{link.iconLeft}</span>}
              {link.text}
              {link.iconRight && <span className="ml-1">{link.iconRight}</span>}
            </Link>
          )}
        </li>
      );
    })}
  </ul>
);
