export enum FormInputStore {
  name = 'name',
  email = 'email',
  phone = 'phone',
  description = 'description',
  imageDetail = 'imageDetail',
  imageBanner = 'imageBanner',
  zipCode = 'zipCode',
  state = 'state',
  city = 'city',
  street = 'street',
  complement = 'complement',
  country = 'country',
  number = 'number',
  neighborhood = 'neighborhood',
}

export interface StoreBasic {
  name: string;
  email: string;
  phone: string;
  description: string;
  imageDetail: string;
  imageBanner: string;
  //   address: Address;
}
