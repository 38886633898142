'use client';

import type React from 'react';
import { useState } from 'react';
import { Checkbox } from 'src/components/Checkbox';
import { InputText } from 'src/components/InputText';
import { GradeForm } from 'src/features/createProduct/screens/CreateProduct/components/GradeForm';
import {
  FormInputGradeSize,
  FormRegisterProduct,
} from 'src/features/createProduct/types/types';
import useForm from 'src/hooks/useForm';
import type { Grade } from 'src/model/Grade';
import type { GradeSize } from 'src/model/GradeSize';
import { EditGradeSize } from './editGradeSize';
import { api } from 'src/services/api';

interface ValuesVariationsProps {
  // eslint-disable-next-line
  product: any;
  // eslint-disable-next-line
  formErrors: any;
  isGrade: boolean;
  // eslint-disable-next-line
  grade: Grade[];
  onChangeFormInput: (inputName: string) => (value: string) => void;
  addQuantiValue: (e: React.FormEvent) => void;
  removeGrade: (index: number) => void;
  addSize: (e: React.FormEvent) => void;
  onGrade: (inputName: string) => (value: string) => void;
  onChangeFormFileInputGradeSize: (
    e: React.ChangeEvent<HTMLInputElement>
  ) => void;
  filesImageGrade: File[];
  previewFilesGrade: string;
  // eslint-disable-next-line
  previewGradesSizes: any[];
}

const ValuesVariations: React.FC<ValuesVariationsProps> = ({
  product,
  formErrors,
  isGrade,
  grade,
  onChangeFormInput,
  addQuantiValue,
  removeGrade,
  addSize,
  onGrade,
  onChangeFormFileInputGradeSize,
  filesImageGrade,
  previewFilesGrade,
  previewGradesSizes,
}) => {
  const [isEditGradeSize, setIsEditGradeSize] = useState<boolean>(false);
  const [gradeId, setGradeId] = useState<string>('');

  const handleEditGradeSize = (gradeSize: GradeSize, id: string) => {
    setGradeId(id);
    setIsEditGradeSize(true);
    onChangeFormInputEditGradeSize(FormInputGradeSize.size)(gradeSize.size);
    onChangeFormInputEditGradeSize(FormInputGradeSize.stock)(gradeSize.stock);
    onChangeFormInputEditGradeSize(FormInputGradeSize.price)(
      gradeSize.price.price.toString()
    );
    onChangeFormInputEditGradeSize(FormInputGradeSize.discount)(
      gradeSize.price.discount.toString()
    );
  };

  const handleOnclose = () => {
    setIsEditGradeSize(false);
    resetFormEditGradeSize();
  };

  const {
    formData: formDataEditGradeSize,
    formErrors: formErrorsEditGradeSize,
    onChangeFormInput: onChangeFormInputEditGradeSize,
    resetForm: resetFormEditGradeSize,
  } = useForm({
    initialData: {
      size: '',
      stock: '',
      price: '',
      discount: '',
    },
  });

  const handleOnSubimit = async (e: React.FormEvent): Promise<void> => {
    e.preventDefault();
    if (gradeId) {
      const payload = {
        productID: product.id,
        gradeSizeID: gradeId,
        size: formDataEditGradeSize[FormInputGradeSize.size],
        stock: formDataEditGradeSize[FormInputGradeSize.stock],
        price: {
          price: formDataEditGradeSize[FormInputGradeSize.price],
          discount: formDataEditGradeSize[FormInputGradeSize.discount],
        },
      };
      const response = await api.put('/product/update-gradesize', payload);
      console.log(response);
    }
  };

  return (
    <div className="bg-white p-6 rounded-lg border border-gray-100 shadow-sm">
      <h2 className="text-xl font-semibold mb-6 text-gray-800 border-b pb-3">
        Valores e Variações
      </h2>

      <div className="mb-6">
        <div className="flex items-center mb-2">
          <Checkbox
            name="isGrade"
            value="Sim"
            checked={product[FormRegisterProduct.isGrade] === 'true'}
            label="Produto terá grade?"
            error={formErrors.isGrade && formErrors.isGrade[0]}
            onChange={(value) => {
              const newValue = value === 'true' ? 'true' : 'false';
              onChangeFormInput(FormRegisterProduct.isGrade)(newValue);
              onGrade(FormRegisterProduct.isGrade)(newValue);
            }}
            checkboxClassName="w-4 h-4 border-primary border-2 accent-primary cursor-pointer"
            labelClassName="text-gray-700 text-md font-medium ml-2"
          />
        </div>
        {formErrors.isGrade && (
          <p className="text-red-500 text-sm mt-1">{formErrors.isGrade[0]}</p>
        )}
      </div>

      <div className="mt-4">
        {isGrade ? (
          <div className="bg-gray-50 p-4 rounded-lg border border-gray-200">
            <GradeForm
              formData={product}
              formErrors={formErrors}
              variants={grade}
              onChangeFormInput={onChangeFormInput}
              addVariant={addQuantiValue}
              removeVariant={removeGrade}
              addSize={addSize}
              onChangeFormFileInput={onChangeFormFileInputGradeSize}
              filesImageGrade={filesImageGrade}
              previewFilesGrade={previewFilesGrade}
              previewGradesSizes={previewGradesSizes}
              onEditGradeSize={handleEditGradeSize}
              isEdit={true}
            />
          </div>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            <div>
              <InputText
                label="Valor unitário"
                name="price"
                type="number"
                id="price"
                value={product[FormInputGradeSize.price]}
                onChange={(e) =>
                  onChangeFormInput(FormInputGradeSize.price)(e.target.value)
                }
                error={formErrors.price?.[0]}
                inputClassName="rounded-lg p-2.5 w-full bg-gray-50 border border-gray-200 focus:border-primary focus:ring-1 focus:ring-primary transition-colors"
                placeholder="Digite o preço"
              />
            </div>

            <div>
              <InputText
                label="Valor + Desconto"
                name="discount"
                type="number"
                id="discount"
                value={product[FormInputGradeSize.discount]}
                onChange={(e) =>
                  onChangeFormInput(FormInputGradeSize.discount)(e.target.value)
                }
                error={formErrors.discount?.[0]}
                inputClassName="rounded-lg p-2.5 w-full bg-gray-50 border border-gray-200 focus:border-primary focus:ring-1 focus:ring-primary transition-colors"
                placeholder="Digite o valor com desconto"
              />
            </div>

            <div>
              <InputText
                label="Estoque"
                name="stock"
                type="number"
                id="stock"
                value={product[FormInputGradeSize.stock]}
                onChange={(e) =>
                  onChangeFormInput(FormInputGradeSize.stock)(e.target.value)
                }
                error={formErrors.stock?.[0]}
                inputClassName="rounded-lg p-2.5 w-full bg-gray-50 border border-gray-200 focus:border-primary focus:ring-1 focus:ring-primary transition-colors"
                placeholder="Digite a quantidade em estoque"
              />
            </div>
          </div>
        )}
      </div>

      {isEditGradeSize && (
        <div className="mt-8 border-t pt-6">
          <h3 className="text-lg font-medium mb-4 text-gray-700">
            Editar Tamanho
          </h3>
          <EditGradeSize
            submitGradesize={handleOnSubimit}
            formData={formDataEditGradeSize}
            formErrors={formErrorsEditGradeSize}
            onChangeFormInput={onChangeFormInputEditGradeSize}
            onClose={handleOnclose}
          />
        </div>
      )}
    </div>
  );
};

export default ValuesVariations;
