import React from 'react';
import { Accordion, AccordionItem } from 'src/components/Accordion';
import { Button } from 'src/components/Button';
import { Product } from 'src/model/Product';
import { FaInfoCircle, FaTags, FaBoxOpen, FaImages } from 'react-icons/fa';

interface ProductModalProps {
  isOpen: boolean;
  product: Product | null;
  onClose: () => void;
}

const ProductModal: React.FC<ProductModalProps> = ({
  isOpen,
  product,
  onClose,
}) => {
  if (!isOpen || !product) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white p-6 rounded-lg shadow-lg max-w-2xl w-full">
        <h1 className="text-2xl font-bold text-center mb-4">{product.name}</h1>

        <Accordion type="single">
          <AccordionItem
            value="basicInfo"
            title="Informações Básicas"
            icon={<FaInfoCircle />}
          >
            <p>
              <strong>Descrição:</strong> {product.description}
            </p>
            <p>
              <strong>SKU:</strong> {product.sku}
            </p>
            <p>
              <strong>Marca:</strong> {product.brand?.name || 'Não informada'}
            </p>
            <p>
              <strong>Preço:</strong> R${' '}
              {product.grade[0]?.gradeSizes[0]?.price?.price ||
                'Não disponível'}
            </p>
          </AccordionItem>

          <AccordionItem
            value="categoriesTags"
            title="Categorias e Tags"
            icon={<FaTags />}
          >
            <p>
              <strong>Categorias:</strong>{' '}
              {product.category?.map((cat) => cat.name).join(', ') || 'Nenhuma'}
            </p>
            <p>
              <strong>Tags:</strong> {product.tags?.join(', ') || 'Nenhuma'}
            </p>
          </AccordionItem>

          <AccordionItem value="stock" title="Estoque" icon={<FaBoxOpen />}>
            <p>
              <strong>Quantidade:</strong> {product.stock}
            </p>
          </AccordionItem>

          {/* <AccordionItem value="shipping" title="Garantia e Frete" icon={<FaTruck />}>
            <WarrantyShippingDimensions formData={product} formErrors={{}} onChangeFormInput={() => {}} onShippingCalc={() => {}} shippingCost={null} />
          </AccordionItem> */}

          <AccordionItem value="images" title="Imagens" icon={<FaImages />}>
            <div className="flex gap-2">
              {product.imagens?.length ? (
                product.imagens.map((img, index) => (
                  <img
                    key={index}
                    src={img}
                    alt={`Imagem ${index + 1}`}
                    className="w-24 h-24 object-cover rounded-md"
                  />
                ))
              ) : (
                <p>Sem imagens</p>
              )}
            </div>
          </AccordionItem>
        </Accordion>

        <div className="mt-6 flex justify-end">
          <Button onClick={onClose} buttonStyle="red" title="Fechar" />
        </div>
      </div>
    </div>
  );
};

export default ProductModal;
