import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AuthWrapper } from 'src/features/core/auth/screen/AuthWrapper';
import { getBoolean, removeItem } from 'src/helpers/common/localStorage';
import Client from 'src/model/User';
import { api } from 'src/services/api';
import { REACT_APP_AUTH, REACT_APP_USER } from 'src/utils/config';

export const ProfilePage = () => {
  const [signed, setSigned] = useState<boolean>(
    getBoolean(String(REACT_APP_AUTH))
  );
  const [, setUser] = useState<Client>();
  const history = useHistory();

  const isAuth = async (): Promise<void> => {
    setTimeout(async () => {
      if (getBoolean(String(REACT_APP_AUTH)) !== signed) {
        setSigned(getBoolean(String(REACT_APP_AUTH)));
        return;
      } else {
        isAuth();
      }
    }, 500);
  };

  const getUser = async (): Promise<void> => {
    if (signed) {
      try {
        const response = await api.get<Client>(`/user/findeone`);
        if (response.status === 200) {
          setUser(response.data);
        }
      } catch (error) {
        console.error('Erro ao buscar o usuario:', error);
      }
    } else {
      isAuth();
    }
  };

  useEffect(() => {
    isAuth();
    // eslint-disable-next-line
  }, [signed]);

  useEffect(() => {
    getUser();
    // eslint-disable-next-line
  }, []);

  const handleOnSubmitLogout = () => {
    removeItem(String(REACT_APP_AUTH));
    removeItem(String(REACT_APP_USER));
    history.replace('/');
  };

  return (
    <AuthWrapper>
      {signed ? (
        <div className="w-full px-5 my-2">
          <div className="flex justify-between items-center">
            <h1 className="text-2xl">Gerencie sua Conta</h1>
            <a href="/" className="underline">
              Voltar
            </a>
          </div>
          <div className="flex flex-col w-full gap-3 mt-5">
            <a
              href="/user-profile"
              className="w-full bg-[#E5E7EB] rounded p-2 hover:bg-[#c8cace] border border-[#c8cace] transition"
            >
              Meu perfil
            </a>
            <a
              href="/store-profile"
              className="w-full bg-[#E5E7EB] rounded p-2 hover:bg-[#c8cace] border border-[#c8cace] transition"
            >
              Perfil da Loja
            </a>
            <a
              href="/notifications"
              className="w-full bg-[#E5E7EB] rounded p-2 hover:bg-[#c8cace] border border-[#c8cace] transition"
            >
              Notificações
            </a>
            <a
              href="/tutorial"
              className="w-full bg-[#E5E7EB] rounded p-2 hover:bg-[#c8cace] border border-[#c8cace] transition"
            >
              Tutorial
            </a>
            <a
              href="/privacy-policy"
              className="w-full bg-[#E5E7EB] rounded p-2 hover:bg-[#c8cace] border border-[#c8cace] transition"
            >
              Política de Privacidade
            </a>
            <a
              href="/advertising"
              className="w-full bg-[#E5E7EB] rounded p-2 hover:bg-[#c8cace] border border-[#c8cace] transition"
            >
              Política de Anúncio
            </a>
            <button
              onClick={handleOnSubmitLogout}
              className="border border-[#F14A3E] hover:bg-[#F14A3E] rounded p-2 transition"
            >
              Sair da conta
            </button>
          </div>
          <div className="h-44 flex-none" />
        </div>
      ) : (
        <div className="w-full px-5 mt-2 mb-20">
          <h1 className="text-2xl text-start">Gerencie sua Conta</h1>
          <div className="flex flex-col w-full gap-3 mt-5">
            <a
              href="/login"
              className="w-full bg-[#E5E7EB] rounded p-2 hover:bg-[#c8cace] border border-[#c8cace] transition"
            >
              Fazer Login
            </a>
            <a
              href="/create-account"
              className="w-full bg-[#E5E7EB] rounded p-2 hover:bg-[#c8cace] border border-[#c8cace] transition"
            >
              Registre-se
            </a>
          </div>
          <div className="h-60 flex-none" />
        </div>
      )}
    </AuthWrapper>
  );
};
