import React, { FormEvent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios'; // Importe AxiosError para tipagem segura
import { NameFiles } from 'src/features/admin/components/Categories';
import { getBoolean } from 'src/helpers/common/localStorage';
import validators from 'src/helpers/validators';
import useForm from 'src/hooks/useForm';
import { Store } from 'src/model/Store';
import { setLoading } from 'src/redux/loading/loadingSlice';
import { api } from 'src/services/api';
import { REACT_APP_AUTH } from 'src/utils/config';
import { FormInputStore, StoreBasic } from './types';
import { StoreProfileManagerUI } from './ui';

export const StoreProfileManager: React.FC = () => {
  const dispatch = useDispatch();
  const [formNameFiles, setFormNameFiles] = useState<NameFiles>({});
  const [selectedStoreImage, setSelectedStoreImage] = useState<string>();
  const [selectedCoverImage, setSelectedCoverImage] = useState<string>();
  const [signed, setSigned] = useState<boolean>(
    getBoolean(String(REACT_APP_AUTH))
  );
  const [isEditingStoreImage, setIsEditingStoreImage] = useState(false);
  const [isEditingCoverImage, setIsEditingCoverImage] = useState(false);
  const [isStore, setIsStore] = useState(false);

  const handleToggleStoreEdit = () =>
    setIsEditingStoreImage(!isEditingStoreImage);
  const handleToggleCoverEdit = () =>
    setIsEditingCoverImage(!isEditingCoverImage);

  const isAuth = async (): Promise<void> => {
    setTimeout(async () => {
      if (getBoolean(String(REACT_APP_AUTH)) !== signed) {
        setSigned(getBoolean(String(REACT_APP_AUTH)));
        return;
      } else {
        isAuth();
      }
    }, 500);
  };

  const getStore = async (): Promise<void> => {
    if (signed) {
      dispatch(setLoading(true));
      try {
        const response = await api.get<Store>(`/store/find-store`);
        if (response.status === 200 && response.data) {
          const store = response.data;
          console.log(store);
          onChangeFormInputStore(FormInputStore.name)(store.name);
          onChangeFormInputStore(FormInputStore.email)(store.email);
          onChangeFormInputStore(FormInputStore.phone)(store.phone);
          onChangeFormInputStore(FormInputStore.description)(store.description);

          if (store.imageDetail) {
            setSelectedStoreImage(store.imageDetail);
            onChangeFormInputStore(FormInputStore.imageDetail)(
              store.imageDetail
            );
          }

          if (store.imageBanner) {
            setSelectedCoverImage(store.imageBanner);
            onChangeFormInputStore(FormInputStore.imageBanner)(
              store.imageBanner
            );
          }

          setIsStore(true);
        }
      } catch (error) {
        console.error('Erro ao buscar a loja:', error);
        toast.error('Erro ao carregar os dados da loja');
      } finally {
        dispatch(setLoading(false));
      }
    }
  };

  const {
    formData: formDataStore,
    formErrors: formErrorStore,
    setErrors: setErrorStore,
    onChangeFormInput: onChangeFormInputStore,
    isFormValid: isFormValidStore,
    resetForm: resetFormStore,
  } = useForm({
    initialData: {
      name: '',
      email: '',
      phone: '',
      description: '',
      imageBanner: '',
      imageDetail: '',
    },
    validators: {
      name: [validators.required],
      email: [validators.required, validators.email],
      phone: [validators.required, validators.mobilePhone],
      description: [validators.required],
    },
  });

  const handleOnSubmitRegister = async (e: FormEvent): Promise<void> => {
    try {
      e.preventDefault();

      dispatch(setLoading(true));

      const payload: StoreBasic = {
        name: formDataStore[FormInputStore.name],
        email: formDataStore[FormInputStore.email],
        phone: formDataStore[FormInputStore.phone],
        description: formDataStore[FormInputStore.description],
        imageDetail: formDataStore[FormInputStore.imageDetail],
        imageBanner: formDataStore[FormInputStore.imageBanner],
      };

      console.log('Payload enviado:', payload);

      if (isFormValidStore()) {
        const response = await api.post<Store>('/store/update', payload);

        console.log(response.status);

        if (response.status === 201 || response.status === 200) {
          resetFormStore();
          getStore();
          toast.success('Dados salvos com sucesso!');
        } else if (response.status === 400) {
          const errorMessage =
            typeof response.data === 'string'
              ? response.data
              : JSON.stringify(response.data);
          toast.error(errorMessage);
        }
      }
    } catch (error: unknown) {
      let errorMessage =
        'Falha ao realizar o cadastro, tente novamente mais tarde';

      if (error instanceof AxiosError) {
        // Erro específico do Axios
        errorMessage =
          error.response && error.response.data
            ? typeof error.response.data === 'string'
              ? error.response.data
              : JSON.stringify(error.response.data)
            : error.message;
      } else if (error instanceof Error) {
        // Erro genérico
        errorMessage = error.message;
      }

      toast.error(errorMessage);
      setErrorStore({
        document: [errorMessage],
      });
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleOnChangeFileInput =
    (inputName: string) => (file: File | undefined) => {
      if (!file) return;

      // Valida o tipo de arquivo
      if (!file.type.match(/image\/(jpg|jpeg|png)/)) {
        setErrorStore({
          [inputName]: ['O formato deve ser .jpg, .jpeg ou .png'],
        });
        return;
      }

      // Valida o tamanho do arquivo
      const maxSize =
        inputName === FormInputStore.imageDetail
          ? 1024 * 1024
          : 2 * 1024 * 1024;
      if (file.size > maxSize) {
        setErrorStore({
          [inputName]: [
            `O tamanho do arquivo deve ser menor que ${maxSize / 1024 / 1024} MB`,
          ],
        });
        return;
      }

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const base64 = reader.result?.toString();
        if (base64) {
          console.log('Imagem convertida para base64:', base64); // Debug
          setFormNameFiles((prev) => ({ ...prev, [inputName]: file.name }));

          if (inputName === FormInputStore.imageDetail) {
            setSelectedStoreImage(base64);
          } else if (inputName === FormInputStore.imageBanner) {
            setSelectedCoverImage(base64);
          }

          onChangeFormInputStore(inputName)('');
          onChangeFormInputStore(inputName)(base64);
        }
      };
    };

  useEffect(() => {
    isAuth();
    getStore();
    // eslint-disable-next-line
  }, []);

  return (
    <StoreProfileManagerUI
      isStore={isStore}
      formData={formDataStore}
      formErrors={formErrorStore}
      selectedStoreImage={selectedStoreImage}
      formNameFiles={formNameFiles}
      selectedCoverImage={selectedCoverImage}
      onSubmitRegister={handleOnSubmitRegister}
      onChangeFormInput={onChangeFormInputStore}
      onChangeFormFileInput={handleOnChangeFileInput}
      isEditingStoreImage={isEditingStoreImage}
      isEditingCoverImage={isEditingCoverImage}
      onToggleStoreEdit={handleToggleStoreEdit}
      onToggleCoverEdit={handleToggleCoverEdit}
    />
  );
};
