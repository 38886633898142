import React from 'react';
import * as CheckboxPrimitive from '@radix-ui/react-checkbox';
import { Check } from 'src/assets/icons';

interface CheckProps {
  name?: string;
  value: string;
  checked?: boolean;
  label?: string;
  error?: string | null | undefined;
  className?: string;
  checkboxClassName?: string;
  labelClassName?: string;
  renderForward?: React.ReactNode;
  onChange: (e: string) => void;
}

export const Checkbox: React.FC<CheckProps> = ({
  name,
  value,
  checked,
  error,
  label,
  className,
  checkboxClassName,
  labelClassName,
  onChange,
  renderForward,
}) => (
  <>
    <div className={`flex items-center ${className ? className : ''}`}>
      <CheckboxPrimitive.Root
        aria-invalid={error ? 'true' : 'false'}
        value={value}
        checked={checked}
        onCheckedChange={(e) => onChange(e ? 'true' : 'false')}
        className={`w-6 h-6 border-2 rounded ${checkboxClassName}`}
        id={name}
      >
        <CheckboxPrimitive.Indicator className="flex items-center justify-center bg-red-500 w-full h-full">
          <img src={Check} alt="Checked" className="w-3 h-3" />
        </CheckboxPrimitive.Indicator>
      </CheckboxPrimitive.Root>
      {label && (
        <label
          htmlFor={name}
          className={`ml-2 text-lg font-medium cursor-pointer ${labelClassName}`}
        >
          {label}
        </label>
      )}
      {renderForward && <div className="ml-[27px]">{renderForward}</div>}
    </div>
    {error && <p className="text-sm text-red-500 mt-1">{error}</p>}
  </>
);
