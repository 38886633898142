import type React from 'react';
import * as AccordionPrimitive from '@radix-ui/react-accordion';
import cx from 'classnames';
import { IconArrow } from 'src/assets/icons/IconArrow';

interface AccordionProps {
  type: 'single' | 'multiple';
  children: React.ReactNode;
}

export const Accordion = ({ type, children }: AccordionProps) => {
  return (
    <AccordionPrimitive.Root type={type} className="space-y-3">
      {children}
    </AccordionPrimitive.Root>
  );
};

interface AccordionItemProps {
  value: string;
  icon: React.ReactNode;
  title: string;
  children: React.ReactNode;
}

export const AccordionItem: React.FC<AccordionItemProps> = ({
  value,
  icon,
  title,
  children,
}: AccordionItemProps) => {
  return (
    <AccordionPrimitive.Item
      value={value}
      className="font-dmsans border border-gray-200 rounded-lg overflow-hidden focus:outline-none focus-within:ring-2 focus-within:ring-primary focus-within:ring-opacity-50"
    >
      <AccordionPrimitive.Header className="w-full">
        <AccordionPrimitive.Trigger
          className={cx(
            'group',
            'inline-flex w-full items-center justify-between bg-white px-5 py-4 text-left',
            'hover:bg-gray-50 transition-colors duration-200',
            'focus:outline-none'
          )}
        >
          <div className="flex items-center">
            <div className="flex justify-center items-center w-6 h-6 text-primary">
              {icon}
            </div>
            <span className="text-base font-medium text-gray-800 ml-3">
              {title}
            </span>
          </div>
          <IconArrow
            className={cx(
              'h-3 w-4 text-gray-500',
              'transition-transform duration-200',
              'group-radix-state-open:rotate-180'
            )}
          />
        </AccordionPrimitive.Trigger>
      </AccordionPrimitive.Header>
      <AccordionPrimitive.Content className="overflow-hidden transition-all data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down">
        <div className="bg-white p-5 border-t border-gray-200">{children}</div>
      </AccordionPrimitive.Content>
    </AccordionPrimitive.Item>
  );
};
