import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getBoolean } from 'src/helpers/common/localStorage';
import { Cart } from 'src/model/Cart';
import Client from 'src/model/User';
import { setLoading } from 'src/redux/loading/loadingSlice';
import { api } from 'src/services/api';
import { REACT_APP_AUTH } from 'src/utils/config';
import { CartUI } from './ui';

interface CartScreenProps {
  auth?: boolean;
  showTitle?: boolean;
}

export interface CartView {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  seller: any;
  id: string;
  cartItemId: string;
  productId: string;
  image: string;
  name: string;
  nameGrade: string;
  size: string;
  price: number;
  quantity: number;
  store?: string;
}
export const CartScreen: React.FC<CartScreenProps> = ({
  auth = true,
  showTitle = true,
}): JSX.Element => {
  const [cart, setCart] = useState<CartView[]>([]);
  const [signed, setSigned] = useState<boolean>(
    getBoolean(String(REACT_APP_AUTH))
  );
  const [cartTotal, setCartTotal] = useState<number>(0);
  const dispatch = useDispatch();
  const history = useHistory();
  const isAuth = async (): Promise<void> => {
    setTimeout(async () => {
      if (getBoolean(String(REACT_APP_AUTH)) !== signed) {
        setSigned(getBoolean(String(REACT_APP_AUTH)));
        return;
      } else {
        isAuth();
      }
    }, 500);
  };

  const getUser = async (): Promise<void> => {
    if (signed) {
      dispatch(setLoading(true));
      try {
        const response = await api.get<Client>(`/user/findeone`);
        if (response.status === 200) {
          if (response.data.cart && response.data.cart.length > 0) {
            const cart = response.data.cart;
            const cartView: CartView[] = [];

            cart.forEach((item) => {
              const id = item.products.id;
              const cartItemId = item.cartItemId;
              const productId = item.products.id;
              const image =
                'https://sandbox.buunpsgpsystem.com.br/uploads/' + item.image;
              const name = item.cartProduct.name;
              const nameGrade = item.cartProduct.grade.color
                ? item.cartProduct.grade.color
                : item.cartProduct.grade.name;
              const size = item.cartProduct.grade.size;
              const price = item.cartProduct.grade.price;
              const quantity = item.cartProduct.quantity;
              const store = item.products.seller.name;
              const seller = item.products.seller;
              cartView.push({
                id,
                cartItemId,
                productId,
                image,
                name,
                nameGrade,
                size,
                price,
                quantity,
                store,
                seller,
              });
            });
            setCartTotal(handleCalculateItemTotal(cartView));
            setCart(cartView);
            console.log(response.data.cart);
          } else {
            setCart([]);
          }
        }
      } catch (error) {
        console.error('Erro ao buscar o usuario:', error);
      } finally {
        dispatch(setLoading(false));
      }
    } else {
      isAuth();
    }
  };

  const handleCalculateItemTotal = (cart: CartView[]): number => {
    let result = 0;
    for (let i = 0; i < cart.length; i++) {
      result += cart[i].price * cart[i].quantity;
    }
    return result;
  };

  // const handleCalculateCartTotal = (): number => {
  //   if (!cart || cart.length === 0) return 0;

  //   return cart.reduce((total, item) => {
  //     const price =
  //       item.price

  //     return total + handleCalculateItemTotal(, item.quantity);
  //   }, 0);
  // };

  const handleOnRemoveItem = async (cartItemId: string): Promise<void> => {
    if (signed) {
      dispatch(setLoading(true));
      console.log(cartItemId);
      try {
        const response = await api.delete<Cart>(
          `/order/delete-cart/${cartItemId}`
        );
        if (response.status === 200) {
          getUser();
          toast.success('Item removido com sucesso');
        }
      } catch (error) {
        console.error('Erro ao remover o item:', error);
      } finally {
        dispatch(setLoading(false));
      }
    } else {
      isAuth();
    }
  };

  const handleCheckout = (): void => {
    if (signed) {
      history.replace(`/checkout`);
    }
  };

  const handleOnIncreaseQuantity = async (id: string): Promise<void> => {
    if (signed) {
      dispatch(setLoading(true));
      try {
        const response = await api.get(`/order/increase-quantity/${id}`);
        if (response.status === 200) {
          getUser();
          toast.success('Quantidade aumentada com sucesso');
        }
      } catch (error) {
        console.error('Erro ao buscar o usuario:', error);
      } finally {
        dispatch(setLoading(false));
      }
    } else {
      isAuth();
    }
  };

  const handleOnDecreaseQuantity = async (id: string): Promise<void> => {
    if (signed) {
      dispatch(setLoading(true));
      try {
        const response = await api.get(`/order/decrease-quantity/${id}`);
        if (response.status === 200) {
          getUser();
          toast.success('Quantidade diminuida com sucesso');
        }
      } catch (error) {
        console.error('Erro ao buscar o usuario:', error);
      } finally {
        dispatch(setLoading(false));
      }
    } else {
      isAuth();
    }
  };

  useEffect(() => {
    getUser();
    // eslint-disable-next-line
  }, [signed]);

  return (
    <CartUI
      cart={cart}
      cartTotal={cartTotal}
      onRemoveItem={handleOnRemoveItem}
      goCheckout={handleCheckout}
      auth={auth}
      showTitle={showTitle}
      onIncreaseQuantity={handleOnIncreaseQuantity}
      onDecreaseQuantity={handleOnDecreaseQuantity}
    />
  );
};
