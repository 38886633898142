import DOMPurify from 'dompurify';
import React from 'react';
import { AuthWrapper } from 'src/features/core/auth/screen/AuthWrapper';

export const PrivacyPolicyUI: React.FC = (): JSX.Element => {
  const privacyPolicyHTML = `
    <div class="w-full max-w-screen-lg mx-auto p-4">      
      <h1 class="text-center text-3xl font-bold text-gray-800 mb-6">
        POLÍTICA DE PRIVACIDADE
      </h1>
      <div class="bg-white w-full max-w-4xl mx-auto">
        <h2 class="text-xl font-semibold text-gray-700 mb-4">Quem somos nós?</h2>
        <p>
          Somos uma empresa de anúncios e venda de produtos e serviços (Marketplace). Nossa sede está localizada no estado do Espírito Santo, mas
          atuamos em todo território nacional.
        </p>
        <p>
          Nossa missão é reunir os melhores anúncios e melhores produtos para que você encontre aquilo que você procura!
          Queremos conectar pessoas e empresas, daí nasceu o Buunpo, plataforma que você conhece e ama!
        </p>
        <h2 class="text-xl font-semibold text-gray-700 mt-6 mb-4">Escopo da Política de Privacidade</h2>
        <p>
          Esta Política de Privacidade e Proteção de Dados tem o objetivo de informar aos titulares como nós tratamos seus dados.
        </p>
        <p>
          A Política abrange as ati8vidades de tratamento de dados online e off-line, coletadas através de todos os nossos canais de atendimento incluindo contato
          telefônico, redes sociais, website, aplicativo, evento, serviço de atendimento ao cliente, entre outros.
        </p>
        <p>
        Esta Política também informa como será o tratamento de dados
        coletados e fornecidos por pela matriz ou filial da empresa, bem como os parceiros
        comerciais.
        O titular também poderá optar por não nos fornecer dados pessoais, no
        entanto, isso poderá acarretar na impossibilidade de fornecermos nossos produtos
        e serviços.
        </p>
        <h2 class="text-xl font-semibold text-gray-700 mt-6 mb-4">Glossário</h2>
        <p><strong>Dados Pessoais:</strong> todas as informações relacionadas à pessoa natural, identificada ou identificável, tais como nome, RG, CPF, telefone, entre outros.</p>
        <p><strong>Dados Pessoais Sensíveis:</strong> são informações relativas à origem racial ou étnica, convicção religiosa, opinião política, filiação a sindicato ou organização de caráter religioso, filosófico ou político, dado referente à saúde ou à vida sexual, dado genético ou biométrico, quando vinculado a uma pessoa natural.</p>
        <p><strong>Banco de Dados:</strong> conjunto estruturado de dados pessoais, estabelecido em um ou vários locais, em suporte eletrônico ou físico;</p>
        <p><strong>Titular:</strong> pessoa natural a quem se referem os dados pessoais que são objetos de tratamento;</p>
        <p><strong>Controlador:</strong> pessoa natural ou jurídica, de direito público ou privado, a quem competem as decisões referentes ao tratamento de dados pessoais;</p>
        <p><strong>Operador:</strong> pessoa natural ou jurídica, de direito público ou privado, que realiza o tratamento de dados pessoais em nome do controlador;</p>
        <p><strong>Agentes de Tratamento:</strong> o controlador e o operador;</p>
        <p><strong>Tratamento de Dados Pessoais:</strong> toda operação realizada com dados pessoais, como as que se referem a coleta, produção, recepção, classificação, utilização, acesso, reprodução, transmissão, distribuição, processamento, arquivamento, armazenamento, eliminação, avaliação ou controle da informação, modificação, comunicação, transferência, difusão ou extração.</p>

        <h2 class="text-xl font-semibold text-gray-700 mt-6 mb-4">Quando coletaremos seus dados?</h2>
        <p>Seus dados serão coletados quando você realizar o seu cadastro em
        nossa plataforma. Para realização de seu cadastro, coletaremos seu nome
        completo, RG, CPF, endereço, telefone e e-mail. </p>
        <p>Também podemos coletar seus dados nas seguintes situações:</p>
        <ul class="list-disc pl-6">
          <li>Caso você faça alguma sugestão ou reclamação através de nossos canais
          de atendimento;</li>
          <li>Quando você utiliza nossos serviços através de nossas plataformas; </li>
          <li>Quando você aceita nossos cookies; </li>
          <li>Quando você faz transações através de nossos sites;</li>
          <li>Quando você envia seus dados por motivos diversos.</li>
        </ul>
        <h2 class="text-xl font-semibold text-gray-700 mt-6 mb-4">Quais dados pessoais coletamos e com qual finalidade?</h2>
        <p><strong>Dados Relativos aos Contratos:</strong> Nome Completo, CPF, RG, endereço, Telefone, E-mail. Finalidade: Elaboração de contrato, contato com o cliente, cadastro do usuário e verificação de identidade.</p>
        <p><strong>Dados de Pagamento:</strong> Dados bancários ou de cartões de crédito. Finalidade: Cobrança pelo serviço prestado.</p>
        <p><strong>Dados Relativos à Localização:</strong> Geolocalização. Finalidade: Prestação do Serviço Contratado.</p>
        <h2 class="text-xl font-semibold text-gray-700 mt-6 mb-4">Como nós utilizamos seus dados?</h2>
        <p>Podemos tratar seus dados pessoais para as seguintes finalidades:</p>
        <ul class="list-disc pl-6">
          <li>Realizar a prestação de serviço.</li>
          <li>Entrar em contato com você.</li>
          <li>Proteger sua segurança.</li>
          <li>Moderar o seu perfil.</li>
          <li>Analisar como você utiliza nossos serviços para que possamos melhorar cada
          vez mais sua experiência em nosso site;</li>
          <li>Para anúncios, publicidade e marketing, poderemos te enviar e-mail marketing, informações e materiais promocionais de nossos parceiros comerciais caso você solicite;</li>
          <li>Para cumprimento de obrigações legais;</li>
          <li>Para registro interno das operações;</li>
          <li>Para prevenir ou investigar quaisquer violações de nossas Políticas ou termos de uso;</li>
          <li>Para hospedar e/ou fazer backup dos seus dados em servidores diversos para que possamos manter a integridade dos seus dados.</li>
        </ul>
        <h2 class="text-xl font-semibold text-gray-700 mt-6 mb-4">Por quanto tempo manteremos os seus dados?</h2>
        <p>Seus dados serão mantidos em nossos servidores enquanto você mantiver seu perfil ativo conosco.</p>
        <p>Os dados que poderão ser mantidos, são aqueles que a legislação pátria prevê a necessidade de manutenção por tempo determinado.</p>
        
        <h3 class="text-lg font-semibold text-gray-700 mt-4 mb-2">Serão respeitados os seguintes prazos:</h3>
        <ul class="list-disc pl-6">
            <li><strong>6 meses:</strong> funcionalidades do próprio aplicativo;</li>
            <li><strong>1 ano:</strong> Gravação entre consumidor e atendente realizado através do SAC;</li>
            <li><strong>10 anos:</strong> Dados cadastrais e de faturamento;</li>
        </ul>

        <p class="mt-4">Caso procure informações mais específicas, poderá entrar em contato conosco para saber mais informações.</p>

        <h2 class="text-xl font-semibold text-gray-700 mt-6 mb-4">Dados Pessoais Sensíveis e Dados de Menores</h2>
        <p>Nós não tratamos dados pessoais quanto a origem racial ou étnica, convicção religiosa, opinião política, filiação a sindicato ou a organização de caráter religioso, filosófico ou político, dado referente à saúde ou à vida sexual.</p>
        <p>Neste mesmo sentido, não serão tratados dados de menores sem a autorização dos responsáveis.</p>

        <h2 class="text-xl font-semibold text-gray-700 mt-6 mb-4">Com quem compartilhamos seus dados?</h2>
        <p>Enquanto você mantiver seu perfil ativo conosco, compartilharemos seus dados com nossos parceiros comerciais, incluindo possíveis prestadores de serviços que utilizamos para operacionalizar nossos processos.</p>
        <p>No entanto, apenas alguns membros de nosso time terão acesso aos seus dados para realizar ações específicas e determinadas, seguindo nossa política interna. Essas medidas visam garantir a máxima proteção de seus dados durante o período de tratamento.</p>
        <p>Os dados fornecidos também serão compartilhados com instituições financeiras com o propósito de efetuar cobranças e auxiliar na resolução de quaisquer questões relacionadas a débitos pendentes e vencidos.</p>
        <p>É possível que seus dados sejam transferidos internacionalmente para serem armazenados em um banco de dados localizado fora do Brasil. É importante notar que outros países podem ter leis diferentes que regulam o tratamento de dados pessoais, mas tomaremos todas as medidas necessárias para proteger os dados que compartilhamos.</p>
        <p>Por fim, seus dados poderão ser compartilhados com as autoridades, a depender da legislação específica, bem como, em caso de solicitação judicial.</p>

        <h2 class="text-xl font-semibold text-gray-700 mt-6 mb-4">Enquanto titular, você possui direitos!</h2>
        <p>Enquanto titular, você poderá:</p>
        <ul class="list-disc pl-6">
            <li>Acessar seus Dados.</li>
            <li>Solicitar a correção de seus dados.</li>
            <li>Solicitar a anonimização, bloqueio ou eliminação de dados pessoais.</li>
            <li>Solicitar a portabilidade de dados pessoais.</li>
            <li>Revogar o consentimento dos dados que coletamos com base no consentimento.</li>
            <li>Obter informações detalhadas sobre o não fornecimento do consentimento e suas consequências negativas.</li>
        </ul>
        <p class="mt-4">Caso você deseje exercer algum dos direitos acima mencionados, você poderá entrar em contato conosco através do e-mail <a href="mailto:entreemcontatoconosco@buunpo.com" class="text-blue-600 font-semibold">entreemcontatoconosco@buunpo.com</a>.</p>

        <h2 class="text-xl font-semibold text-gray-700 mt-6 mb-4">Como protegemos seus dados?</h2>
        <p>Nós adotamos medidas técnicas de segurança apropriadas e seguimos boas práticas no tratamento visando a preservação dos dados pessoais. Nossas medidas de segurança são constantemente testadas, monitoradas e revisadas de acordo com as necessidades e os avanços tecnológicos.</p>

        <p>Nós não nos responsabilizamos por dados que você fornece a terceiros!</p>
        <p>Caso durante o uso de nossos serviços, você forneça quaisquer dados além dos dados solicitados por nós, nós não poderemos garantir a sua segurança.</p>
        <p>Caso você suspeite de qualquer violação de segurança ou precise relatar uma preocupação, estamos à disposição para ajudar. Entre em contato diretamente com nossa equipe através do seguinte endereço de e-mail: <a href="mailto:entreemcontatoconosco@buunpo.com" class="text-blue-600 font-semibold">entreemcontatoconosco@buunpo.com</a>.</p>

        <h2 class="text-xl font-semibold text-gray-700 mt-6 mb-4">Tem dúvidas? Entre em contato conosco!</h2>
        <p>Ficou com alguma dúvida a respeito de nossa política de privacidade? Entre em contato conosco pelo e-mail <a href="mailto:entreemcontatoconosco@buunpo.com" class="text-blue-600 font-semibold">entreemcontatoconosco@buunpo.com</a> para falar diretamente com nossa equipe.</p>

        <h2 class="text-xl font-semibold text-gray-700 mt-6 mb-4">Histórico de Versões:</h2>
        <table class="w-full border-collapse border border-gray-400">
            <tr class="bg-gray-200">
                <th class="border border-gray-400 px-4 py-2">Versão</th>
                <th class="border border-gray-400 px-4 py-2">Data</th>
            </tr>
            <tr>
                <td class="border border-gray-400 px-4 py-2">1.0</td>
                <td class="border border-gray-400 px-4 py-2">25/02/2025</td>
            </tr>
        </table>
      </div>
    </div>
  `;

  return (
    <AuthWrapper>
      <div className="bg-white mt-4 rounded-md border border-gray-200">
        <div className="bg-white p-4 rounded-lg shadow-sm">
          <div
            className="privacy-policy-content"
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(privacyPolicyHTML),
            }}
          />
        </div>
      </div>
    </AuthWrapper>
  );
};
