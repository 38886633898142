import React, { useState } from 'react';
import { FaCheck, FaTimes, FaEye } from 'react-icons/fa';
import { Grade } from 'src/model/Grade';
import { Product, ProductStatus } from 'src/model/Product';
import { api } from 'src/services/api';
import { Carousel } from 'src/components/Carousel/index';

interface ProductApprovalUIProps {
  products: Product[];
  onActiveProduct: (id: string) => void;
  onExcludedProduct: (id: string) => void;
}

const ProductApprovalUI: React.FC<ProductApprovalUIProps> = ({
  products,
  onActiveProduct,
  onExcludedProduct,
}) => {
  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleStock = (stock: Grade[]): number => {
    let total = 0;
    if (stock.length > 0) {
      for (let i = 0; i < stock.length; i++) {
        if (stock[i].gradeSizes.length > 0) {
          for (let j = 0; j < stock[i].gradeSizes.length; j++) {
            if (stock[i].gradeSizes[j].stock) {
              total += parseInt(stock[i].gradeSizes[j].stock);
            }
          }
        }
      }
    }
    return total;
  };

  const handleViewProduct = async (productId: string) => {
    try {
      const response = await api.get(`/product/inactive`);
      console.log('Resposta da API:', response.data);

      const selected = response.data.find(
        (prod: { id: string }) => prod.id === productId
      );

      if (!selected) {
        console.warn('Produto não encontrado na lista de inativos!');
        return;
      }

      setSelectedProduct(selected);
      setIsModalOpen(true);
    } catch (error) {
      console.error('Erro ao buscar produto:', error);
    }
  };

  return (
    <>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {products.map((product) => (
          <div
            key={product.id}
            className="border border-gray-100 rounded-sm shadow-sm p-4 bg-white relative"
          >
            <div
              className={`absolute top-2 right-2 w-4 h-4 rounded-full ${
                product.status === ProductStatus.ACTIVE
                  ? 'bg-green-500'
                  : 'bg-red-500'
              }`}
              title={
                product.status === ProductStatus.ACTIVE
                  ? 'Aprovado'
                  : 'Desaprovado'
              }
            ></div>
            {product.imagens && product.imagens[0] ? (
              <img
                src={product.imagens[0]}
                alt={product.name}
                className="w-full h-40 object-cover mb-4"
              />
            ) : (
              <div className="w-full h-40 bg-gray-200 flex items-center justify-center mb-4">
                <span className="text-gray-500">Sem imagem</span>
              </div>
            )}
            <h2 className="font-semibold text-lg mb-1 text-gray-800">
              {product.name}
            </h2>
            <h2 className="font-bold text-lg mb-1 text-gray-800">
              {product.promotion
                ? product.grade[0]?.gradeSizes[0]?.price?.discount || 'N/A'
                : product.grade[0]?.gradeSizes[0]?.price?.price || 'N/A'}
            </h2>
            <p className="text-sm text-gray-500 mb-1">
              Estoque:{' '}
              <span className="text-red-500 font-semibold">
                {handleStock(product.grade)}
              </span>
            </p>
            <div className="w-full flex flex-col items-center space-y-2">
              <div className="w-full flex justify-around items-center space-x-2">
                <button
                  onClick={() => onActiveProduct(product.id)}
                  className="flex items-center text-md transition-transform duration-300 transform hover:scale-105 text-green-500 hover:text-green-600"
                >
                  <FaCheck className="mr-1" /> Aprovar
                </button>
                <button
                  onClick={() => onExcludedProduct(product.id)}
                  className="flex items-center text-md transition-transform duration-300 transform hover:scale-105 text-red-500 hover:text-red-600"
                >
                  <FaTimes className="mr-1" /> Desaprovar
                </button>
              </div>

              <button
                onClick={() => handleViewProduct(product.id)}
                className="flex items-center text-md transition-transform duration-300 transform hover:scale-105 text-blue-500 hover:text-blue-600"
              >
                <FaEye className="mr-1" /> Visualizar
              </button>
            </div>
          </div>
        ))}
      </div>

      {isModalOpen && selectedProduct && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white rounded-2xl shadow-lg w-full max-w-2xl p-6 relative">
            <button
              onClick={() => setIsModalOpen(false)}
              className="absolute top-3 right-3 text-gray-600 hover:text-red-500 text-xl"
            >
              ✖
            </button>

            {selectedProduct.imagens?.length > 0 ? (
              <Carousel
                carousel={selectedProduct.imagens.map((imagem, index) => ({
                  id: index,
                  imageUrl: imagem,
                  altText: `${selectedProduct.name} - ${index + 1}`,
                }))}
              />
            ) : (
              <div className="text-center text-gray-500">
                Sem imagens disponíveis
              </div>
            )}

            <h2 className="text-2xl font-bold mt-4 mb-2">
              {selectedProduct.name}
            </h2>
            <p className="text-gray-600">
              <strong>Descrição:</strong>
              {selectedProduct.description}
            </p>

            <div className="grid grid-cols-2 gap-4 mt-4">
              <div>
                <p>
                  <strong>Estoque:</strong>{' '}
                  {selectedProduct.grade[0]?.gradeSizes[0]?.stock || 'N/A'}
                </p>
                <p>
                  <strong>Preço:</strong> R${' '}
                  {selectedProduct.grade[0]?.gradeSizes[0]?.price.price ||
                    'N/A'}
                </p>
                <p>
                  <strong>Promoção:</strong>{' '}
                  {selectedProduct.promotion ? 'Sim' : 'Não'}
                </p>
                <p>
                  <strong>Marca:</strong>{' '}
                  {selectedProduct.brand?.name || 'Sem marca'}
                </p>
                <p>
                  <strong>SKU:</strong> {selectedProduct.sku}
                </p>
              </div>

              <div>
                <p>
                  <strong>Frete grátis:</strong>{' '}
                  {selectedProduct.freeShipping ? 'Sim' : 'Não'}
                </p>
                <p>
                  <strong>Categoria:</strong>{' '}
                  {selectedProduct.category[0]?.name || 'Sem categoria'}
                </p>
                <p>
                  <strong>Dimensões:</strong>{' '}
                  {`${selectedProduct.dimensions?.width} x ${selectedProduct.dimensions?.height} x ${selectedProduct.dimensions?.depth} cm`}
                </p>
                <p>
                  <strong>Peso:</strong>{' '}
                  {selectedProduct.dimensions?.weight || 'N/A'} kg
                </p>
                <p>
                  <strong>Vendedor:</strong>{' '}
                  {selectedProduct.seller?.name || 'Não informado'}
                </p>
              </div>
            </div>

            {selectedProduct.tags?.length > 0 && (
              <div className="mt-4">
                <h3 className="font-semibold">Tags:</h3>
                <div className="flex flex-wrap gap-2">
                  {selectedProduct.tags.map((tag, index) => (
                    <span
                      key={index}
                      className="px-2 py-1 bg-gray-200 text-sm rounded-md"
                    >
                      {tag}
                    </span>
                  ))}
                </div>
              </div>
            )}

            {selectedProduct.warranty?.warranty && (
              <p className="mt-4">
                <strong>Garantia:</strong> {selectedProduct.warranty.warranty}
              </p>
            )}

            <div className="mt-6 text-right">
              <button
                onClick={() => setIsModalOpen(false)}
                className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
              >
                Fechar
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ProductApprovalUI;
