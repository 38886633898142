import React from 'react';
import { NotificationsUI } from './ui';
import { AlertMessage } from 'src/model/AlertMessage';

interface NotificationsProps {
  alertMessage: AlertMessage[];
}
export const NotificationsScreen: React.FC<NotificationsProps> = ({
  alertMessage,
}): JSX.Element => {
  return <NotificationsUI alertMessage={alertMessage} />;
};
