import React, { FormEvent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { FormInputNameRegister } from 'src/features/core/auth/types';
import { updateMask as updateMaskCEP } from 'src/helpers/masks/cep';
import { updateMask as updateMaskCPF } from 'src/helpers/masks/cpf';
import {
  format,
  unmask,
  updateMask as updateMaskDate,
} from 'src/helpers/masks/generalDate';
import { updateMask as updateMaskPhone } from 'src/helpers/masks/mobilePhone';
import validators from 'src/helpers/validators';
import useForm from 'src/hooks/useForm';
import { Address } from 'src/model/Address';
import Client from 'src/model/User';
import { UserBasic } from 'src/model/UserBasic';
import { setLoading } from 'src/redux/loading/loadingSlice';
import { api } from 'src/services/api';
import { UsersManager } from './ui';

export const UsersManagerScreen: React.FC = () => {
  const [users, setUsers] = useState<Client[]>([]);
  const [filteredUsers, setFilteredUsers] = useState<Client[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [editingUser, setEditingUser] = useState<boolean>(false);
  const dispatch = useDispatch();

  const getUser = async (): Promise<void> => {
    dispatch(setLoading(true));
    try {
      const response = await api.get<Client[]>('/user/all');
      console.log(response);
      if (response.status === 200) {
        setUsers(response.data);
        setFilteredUsers(response.data);
      }
    } catch (error) {
      console.error('Erro ao buscar usuarios:', error);
    } finally {
      dispatch(setLoading(false));
    }
  };
  const {
    formData: formDataRegister,
    formErrors: formErrorRegister,
    setErrors: setErrorRegister,
    onChangeFormInput: onChangeFormInputRegister,
    isFormValid: isFormValidRegister,
    resetForm: resetFormRegister,
  } = useForm({
    initialData: {
      name: '',
      document: '',
      email: '',
      gender: '',
      date: '',
      phone: '',
      password: '',
      confirmPassword: '',
      terms: '',
      zipCode: '',
      state: '',
      city: '',
      street: '',
      complement: '',
      number: '',
      neighborhood: '',
      imageBase64: '',
      country: '',
    },
    validators: {
      name: [validators.required],
      document: [validators.required, validators.cpf],
      email: [validators.required, validators.email],
      gender: [validators.required],
      date: [
        validators.required,
        validators.birthday,
        validators.maxLength(10),
      ],
      phone: [validators.required, validators.mobilePhone],
      password: [
        validators.required,
        validators.minLength(8),
        validators.maxLength(15),
        validators.hasPasswordOnlyNumberCharacteres,
      ],
      confirmPassword: [
        validators.required,
        validators.minLength(8),
        validators.maxLength(15),
        validators.hasPasswordOnlyNumberCharacteres,
      ],
      terms: [validators.required],
      zipCode: [validators.required],
      state: [validators.required],
      city: [validators.required],
      street: [validators.required],
      number: [validators.required],
      country: [validators.required],
      neighborhood: [validators.required],
    },
    formatters: {
      document: updateMaskCPF,
      phone: updateMaskPhone,
      date: updateMaskDate,
      zipCode: updateMaskCEP,
    },
  });

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.toLowerCase();
    setSearchTerm(value);
    const filtered = users.filter(
      (user) =>
        user.name.toLowerCase().includes(value) ||
        user.email.toLowerCase().includes(value) ||
        user.cpf.toLowerCase().includes(value) ||
        user.cellPhone.toLowerCase().includes(value)
    );
    setFilteredUsers(filtered);
  };

  const handleEditUser = (user: Client) => {
    if (user) {
      onChangeFormInputRegister(FormInputNameRegister.name)(user.name);
      onChangeFormInputRegister(FormInputNameRegister.document)(user.cpf);
      onChangeFormInputRegister(FormInputNameRegister.date)(
        user.birthDate ? (format(user.birthDate) ?? '') : ''
      );
      onChangeFormInputRegister(FormInputNameRegister.gender)(user.gender);
      onChangeFormInputRegister(FormInputNameRegister.email)(user.email);
      onChangeFormInputRegister(FormInputNameRegister.phone)(user.cellPhone);
      onChangeFormInputRegister(FormInputNameRegister.password)('***********');
      setEditingUser(true);
    } else {
      toast.error('Usuário não selecionado');
    }
  };
  const handleSaveEdit = async (e: FormEvent): Promise<void> => {
    try {
      e.preventDefault();
      if (isFormValidRegister()) {
        dispatch(setLoading(true));
        const dateString = unmask(formDataRegister[FormInputNameRegister.date]);

        if (!dateString) {
          const fielName = FormInputNameRegister.date.toString();
          setErrorRegister({ [fielName]: ['Data de nascimento Inválida'] });
          return;
        }
        if (
          formDataRegister[FormInputNameRegister.password] !==
          formDataRegister[FormInputNameRegister.confirmPassword]
        ) {
          setErrorRegister({
            [FormInputNameRegister.password]: ['Senhas Diferentes'],
            [FormInputNameRegister.confirmPassword]: ['Senhas Diferentes'],
          });
          return;
        }
        const address = {
          zipcode: formDataRegister[FormInputNameRegister.zipCode],
          state: formDataRegister[FormInputNameRegister.state],
          city: formDataRegister[FormInputNameRegister.city],
          district: formDataRegister[FormInputNameRegister.neighborhood],
          street: formDataRegister[FormInputNameRegister.street],
          complement: formDataRegister[FormInputNameRegister.complement],
          number: formDataRegister[FormInputNameRegister.number],
          country: formDataRegister[FormInputNameRegister.country],
        } as Address;
        const payload: UserBasic = {
          name: formDataRegister[FormInputNameRegister.name],
          cpf: formDataRegister[FormInputNameRegister.document],
          gender: formDataRegister[FormInputNameRegister.gender],
          email: formDataRegister[FormInputNameRegister.email],
          cellPhone: formDataRegister[FormInputNameRegister.phone],
          birthdate: dateString,
          password: formDataRegister[FormInputNameRegister.password],
          address: address,
          //   acceptedTerms:
          //     formDataRegister[FormInputNameRegister.terms] &&
          //     formDataRegister[FormInputNameRegister.terms] === 'true'
          //       ? true
          //       : false,
          image: formDataRegister[FormInputNameRegister.imageBase64],
        };
        const response = await api.post<Client>('/user', payload);
        if (response.status === 201) {
          resetFormRegister();
          toast.success('Dados salvos com sucesso!');
        } else if (response.status === 400) {
          const errorMessage =
            typeof response.data === 'string'
              ? response.data
              : JSON.stringify(response.data);
          toast.error(errorMessage);
        }
      }
      // eslint-disable-next-line
    } catch (error: any) {
      //   resetFormRegister();
      let errorMessage =
        'Falha ao realizar o cadastro, tente novamente mais tarde';
      if (error.response && error.response.data) {
        errorMessage =
          typeof error.response.data === 'string'
            ? error.response.data
            : JSON.stringify(error.response.data);
      }
      toast.error(errorMessage);
      setErrorRegister({
        document: [errorMessage],
      });
    } finally {
      dispatch(setLoading(false));
    }
  };

  const handleDeleteUser = async (id: string) => {
    if (window.confirm('Tem certeza que deseja deletar este usuário?')) {
      dispatch(setLoading(true));
      try {
        const response = await api.delete(`/user/${id}`);
        if (response.status === 200) {
          toast.success('Usuário deletado com sucesso!');
          // Atualiza a lista de usuários
          const updatedUsers = users.filter((user) => user.id !== id);
          setUsers(updatedUsers);
          setFilteredUsers(updatedUsers);
        } else {
          toast.error('Erro ao deletar usuário.');
        }
      } catch (error) {
        console.error('Erro ao deletar usuário:', error);
        toast.error('Erro ao deletar usuário.');
      } finally {
        dispatch(setLoading(false));
      }
    }
  };

  useEffect(() => {
    getUser();
    // eslint-disable-next-line
  }, []);

  return (
    <UsersManager
      users={filteredUsers}
      editingUser={editingUser}
      searchTerm={searchTerm}
      onSearchChange={handleSearchChange}
      onEditUser={handleEditUser}
      onDeleteUser={handleDeleteUser}
      onSaveEdit={handleSaveEdit}
      formData={formDataRegister}
      formErrors={formErrorRegister}
      onChangeFormInput={onChangeFormInputRegister}
    />
  );
};

export default UsersManagerScreen;
