import React from 'react';
import { AddressManagerScreen } from 'src/components/AddressManager';
import { ChatComponent } from 'src/components/Chat';
import { NotificationComponentScreen } from 'src/components/NotificationComponent';
import OrderPreparation from 'src/components/OrderPreparation';
import { OrderTracking } from 'src/components/OrderTracking';
import { PrivacySettings } from 'src/components/PrivacySettings';
// import { HorizontalProductCard } from 'src/components/ProductHorizontalCard';
import { ProfileSideMenu } from 'src/components/ProfileSideMenu';
import { StoreProfileManager } from 'src/components/StoreProfileManager';
import { UserProfileManagerScreen } from 'src/components/UserProfileManager';
// import { CartScreen } from 'src/features/checkout/screens/Cart';
import { AuthWrapper } from 'src/features/core/auth/screen/AuthWrapper';
import { CreateProductScreen } from 'src/features/createProduct/screens/CreateProduct';
import { CreditCardPaymentAddScreen } from 'src/features/userProfile/components/CreditCards';
import { CupomCreateScreen } from 'src/features/userProfile/components/Cupom';
import { ProductManagerScreen } from 'src/features/userProfile/components/ProductManager';
import { AlertMessage } from 'src/model/AlertMessage';
// import { Product } from 'src/model/Product';
import Client from 'src/model/User';

interface UserProfileUIProps {
  user: Client;
  notifications: AlertMessage[];
}

// const productList: Product[] = [];

export const UserProfileUI: React.FC<UserProfileUIProps> = ({
  user,
  notifications,
}) => {
  const menuItems = [
    {
      title: 'Chat',
      key: 'chat',
      content: <ChatComponent user={user} />,
    },
    {
      title: 'Alterar Dados Pessoais',
      key: 'dados-pessoais',
      content: <UserProfileManagerScreen userData={user} />,
    },
    {
      title: 'Dados Loja',
      key: 'dados-loja',
      content: <StoreProfileManager />,
    },
    {
      title: 'Criar Produto',
      key: 'criar-produto',
      content: <CreateProductScreen />,
    },
    {
      title: 'Criar Cupons',
      key: 'criar-cupons',
      content: <CupomCreateScreen />,
    },
    {
      title: 'Gerenciar Produtos',
      key: 'gerenciar-produtos',
      content: <ProductManagerScreen />,
    },
    {
      title: 'Acompanhar Pedidos',
      key: 'acompanhar-pedidos',
      content: <OrderTracking />,
    },
    {
      title: 'Cartões',
      key: 'cartoes',
      content: <CreditCardPaymentAddScreen />,
    },
    {
      title: 'Endereços',
      key: 'enderecos',
      content: <AddressManagerScreen address={user.address || []} />,
    },
    {
      title: 'Notificações',
      key: 'notificacoes',
      content: (
        <NotificationComponentScreen
          alertMessage={notifications}
          variant="page"
        />
      ),
    },
    {
      title: 'Vendas',
      key: 'vendas',
      content: <OrderPreparation />,
    },
    {
      title: 'Config de Privacidade',
      key: 'config-privacidade',
      content: <PrivacySettings />,
    },
  ];

  return (
    <AuthWrapper>
      <div className="container p-0 m-4 bg-white rounded-lg shadow-sm w-full">
        {/* Banner */}
        <div className="relative bg-white h-36 sm:h-64 flex items-center justify-center rounded-t-lg">
          <img
            src={
              user.image
                ? `https://sandbox.buunpsgpsystem.com.br/uploads/${user.image}`
                : 'https://via.placeholder.com/100'
            }
            alt={`${user.name} Logo`}
            className="w-32 h-32 rounded border-4 border-white shadow-lg absolute top-[45%] sm:top-[65%]"
          />
        </div>

        {/* User Info */}
        <div className="flex flex-col items-center justify-center align-middle mt-16 text-center ">
          <div className="w-[90%] sm:w-[60%] flex justify-center items-center  rounded  bg-[#eeeeee86]  px-2 py-4">
            {/* <div>87.0% Avaliação</div> */}
            <div className="text-center font-bold">{user.name}</div>
            {/* <div>5.1K Vendas</div> */}
          </div>
          <div className=" mt-6 ">{/* <span>descrição</span> */}</div>
        </div>

        <div className="m-0">
          <ProfileSideMenu user={user} menuItems={menuItems} />
        </div>
      </div>
    </AuthWrapper>
  );
};
