import DOMPurify from 'dompurify';
import React from 'react';
import { AuthWrapper } from 'src/features/core/auth/screen/AuthWrapper';
import { AdvertisingPolicies } from 'src/model/AdvertisingPolicies';

interface AdvertisingUIProps {
  advertising?: AdvertisingPolicies;
}

export const AdvertisingUI: React.FC<AdvertisingUIProps> = ({
  advertising,
}): JSX.Element => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const sanitizedDescription = DOMPurify.sanitize(
    advertising ? advertising.description : 'carregando'
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const advertisingPolicyHTML = `
  <div class="w-full max-w-screen-lg mx-auto p-4">      
    <h1 class="text-center text-3xl font-bold text-gray-800 mb-6">
      Nossa Política de Anúncios: Um Espaço de União e Respeito
    </h1>
    <div class="bg-white w-full max-w-4xl mx-auto">
      <h2 class="text-xl font-semibold text-gray-700 mb-4">Quem somos nós?</h2>
      <p>
        Acreditamos que a melhor experiência acontece quando todas as pessoas se sentem
        bem-vindas e respeitadas. Por isso, criamos nossa política de anúncios com o objetivo
        de garantir um ambiente seguro, inclusivo e harmonioso para todos.
      </p>
      <p>
        Nosso foco é a união, não a divisão. Queremos que este seja um espaço onde as pessoas
        possam interagir sem receio de constrangimento ou desconforto. Para isso, evitamos
        anúncios com temáticas que possam gerar conflitos ou polarização, como conteúdos
        religiosos ou eróticos. Essa decisão não se trata de censura, mas sim de preservar um
        ambiente onde todos possam participar sem se sentirem excluídos ou intimidados por
        diferenças de opinião.
      </p>
      <p>
        Nosso compromisso é criar um espaço de convivência saudável, onde a diversidade seja
        respeitada e valorizada. Contamos com você para manter esse ambiente acolhedor, onde
        o respeito e a empatia estão sempre em primeiro lugar!
      </p>

      <h2 class="text-xl font-semibold text-gray-700 mt-6 mb-4">Tópicos Proibidos para Anúncios:</h2>
        <ul class="list-disc space-y-3 text-gray-700 pl-5">
            <li><strong>Drogas Ilícitas:</strong> É estritamente proibido anunciar qualquer tipo de substância considerada ilegal
ou controlada pela legislação vigente.</li>
            <li><strong>Medicamentos:</strong> A venda de medicamentos é permitida apenas para comerciantes locais devidamente
autorizados, como farmácias, de acordo com a legislação vigente. Segundo a lei, a
entrega dos medicamentos deve ser feita exclusivamente pelo próprio
estabelecimento autorizado, garantindo o cumprimento das normas sanitárias e de
segurança. A comercialização de medicamentos fora desses parâmetros não é
permitida na plataforma.</li>
            <li><strong>Suplementos Alimentares:</strong> Produtos como óleo de peixe (ômega-3), vitaminas e
minerais são considerados suplementos e devem seguir as regras da RDC 243/2018.</li>
            <li><strong>Venda de Animais:</strong> Anúncios que envolvam a comercialização de animais vivos ou partes de
animais são vetados.</li>
            <li><strong>Aparelhos ou Acessórios Sádicos:</strong> A comercialização de qualquer aparelho ou acessório que tenha natureza sádica,
violenta ou que promova dor é proibida.</li>
            <li><strong>Filmes e Livros Eróticos:</strong> Filmes, livros ou qualquer tipo de mídia com conteúdo erótico ou pornográfico
são estritamente proibidos.</li>
            <li><strong>Objetos de Estimulação Sexual:</strong> Não é permitido anunciar produtos voltados para estimulação ou manipulação de
órgãos sexuais, tais como próteses penianas, vaginas artificiais e outros
similares.</li>
            <li><strong>Fotos de Anúncio:</strong> Os anuncios não podem conter imagens sexualmente
sugestível, sugestivamente violento, ou sugestivamente preconceituoso.</li>
            <li><strong>Assinaturas Sem Autorização:</strong> A venda de assinaturas, produtos ou serviços que exijam assinatura sem a devida
autorização ou licenciamento prévio é proibida.</li>
            <li><strong>Produtos Desconhecidos:</strong> Itens cuja natureza seja desconhecida ou que não possam ser adequadamente
analisados para garantir sua segurança e legalidade não podem ser anunciados.</li>
            <li><strong>Material Explosivo:</strong>A comercialização de qualquer tipo de material explosivo é expressamente
proibida, incluindo fogos de artifício que não estejam devidamente
regulamentados (nesse caso só será permitida a venda apenas a comerciante
local, onde o mesmo possa fazer entregas).</li>
            <li><strong>Venenos Poderosos:</strong> Venenos altamente perigosos, como raticidas ou qualquer substância que possa
causar danos a animais domésticos ou seres humanos, não são permitidos.
1. Exceção: Produtos de baixa periculosidade, como inseticidas para
formigas, mosquitos e baratas, podem ser anunciados desde que sigam as
normas de segurança.</li>
            <li><strong>Aparelhos que Roubam Dados:</strong>Dispositivos projetados para interceptar ou roubar dados, ou que interfiram no
sinal de telecomunicações, estão proibidos.</li>
            <li><strong>Armas de Fogo e Munição:</strong>É proibido anunciar qualquer tipo de arma de fogo, independentemente do tipo
ou calibre. A venda de munição de qualquer tipo, incluindo projéteis e cartuchos, não é
permitida na plataforma.</li>
            <li><strong>Pedras e Metais Preciosos:</strong> A comercialização de pedras preciosas, metais preciosos (como ouro, prata e
platina) ou qualquer material relacionado é proibida, a menos que o vendedor
tenha certificação e autorização oficial para atuar nesse mercado.</li>
            <li><strong>Produtos Perigosos para Transporte:</strong>Produtos que representem risco significativo para o transporte, como mercúrio,
formol, nitrato de sódio, nitrato de potássio, entre outros químicos perigosos, são
proibidos.</li>
            <li><strong>Ossos, Órgãos e Membros Humanos:</strong> A venda de ossos, órgãos ou quaisquer partes do corpo humano é estritamente
proibida, em conformidade com as leis de saúde e ética.</li>
            <li><strong>Máquinas Sem Equipamentos de Segurança:</strong> Máquinas e equipamentos industriais ou de qualquer natureza que não possuam
os dispositivos de segurança necessários e regulamentados não podem ser
anunciados</li>
            <li><strong>Produtos Fora do Comércio:</strong>Produtos que estão fora do comércio, seja por determinação legal, regulatória ou
por qualquer outro motivo, não podem ser vendidos na plataforma.</li>
            <li><strong>Violência e Discriminação:</strong>Produtos ou anúncios que incitem ou promovam violência, discriminação racial,
de gênero, orientação sexual, religiosa ou de qualquer outro tipo são proibidos.</li>
            <li><strong>Documentos Legais e Pessoais:</strong> A venda de documentos legais, pessoais ou qualquer tipo de identificação (como
passaportes, carteiras de identidade, CNH, etc.) é proibida.</li>
            <li><strong>Patrimônio Histórico, Cultural, Arqueológico e Paleontológico:</strong> A comercialização de itens que pertencem ao patrimônio histórico, cultural,
arqueológico ou paleontológico sem a devida autorização das autoridades
competentes é proibida.</li>
            <li><strong>Programas ou Serviços para Hackear Dispositivos Eletrônicos:</strong> Anúncios que promovam programas, serviços ou dispositivos voltados para
hackear ou interferir em dispositivos eletrônicos e redes de comunicação são
estritamente proibidos.</li>
            <li><strong>Loterias e Jogos de Azar:</strong> A venda ou promoção de bilhetes de loteria, jogos de azar ou qualquer forma de
apostas ilegais não é permitida.</li>
            <li><strong>Conteúdos com Finalidade Diferente da Venda de Produtos ou Serviços:</strong>Anúncios cujo objetivo não seja a venda direta de um produto ou serviço são
proibidos, incluindo conteúdos promocionais sem vínculo com a atividade
comercial.</li>
            <li><strong>Produtos que Dependem de Homologação e/ou Aprovação de Órgãos
Governamentais:</strong> Produtos que exigem homologação, aprovação ou certificação de órgãos
governamentais não podem ser comercializados sem a devida comprovação de
que estão autorizados.</li>
            <li><strong>Veículos Sem Documentação e Produtos para Veículos que Infrinjam
Leis Vigentes</strong>A venda de veículos sem documentação regularizada ou produtos para veículos que
estejam em desacordo com a legislação vigente é proibida.</li>
            <li><strong>Livros Religiosos:</strong>A venda de livros religiosos, de qualquer doutrina ou fé, é proibida na
plataforma.</li>
            <li><strong>Livros com Rituais Religiosos ou Desconhecidos:</strong>Livros contendo rituais de natureza religiosa ou desconhecida não podem ser
anunciados.</li>
            <li><strong>Arte Sacra, Itens Culturais e Itens Supersticiosos:</strong> Não é permitido anunciar itens de arte sacra relacionados a qualquer religião ou
crença. Isso inclui, mas não se limita a, imagens de santos, crucifixos, estátuas
religiosas, velas rituais, e qualquer outro artigo com função espiritual ou
litúrgica.</li>
        </ul>
      <p>
      Além disso, são proibidos produtos que façam referência a crenças populares ou
      tradições culturais associadas a datas comemorativas religiosas ou supersticiosas.
      Exemplos incluem :   
      </p>
      
      <ul class="list-disc space-y-3 text-gray-700 pl-5">
                  <li><strong>Decorações e objetos temáticos:</strong>Papai Noel, ovos de Páscoa, fantasias e
      acessórios de Halloween.</li>
                  <li><strong>Objetos utilizados em rituais ou cerimônias supersticiosas:</strong> como ferraduras,
      amuletos, patuás, e outros itens de superstição.</li>
      </ul>

      <p>
      Somente são permitidos produtos de natureza neutra, que não estejam diretamente
      ligados a qualquer tipo de crença religiosa ou supersticiosa, preservando a neutralidade 
      da plataforma. Produtos que incitem crenças ou promovam devoção, como itens de
      astrologia, cartomancia ou talismãs, também são proibidos. A plataforma visa garantir
      que todos os produtos vendidos estejam desvinculados de temas que possam gerar
      controvérsias religiosas ou espirituais.
      </p>
      <p>
      <strong>Exceções:</strong> Produtos de natureza histórica, como livros ou materiais que tratem de
      política de forma educativa e neutra, podem ser permitidos, desde que não promovam
      direta ou indiretamente campanhas eleitorais, partidos ou figuras políticas.
      A plataforma busca manter uma postura neutra e evitar que seu espaço seja utilizado
      para fins políticos ou eleitorais. O foco deve permanecer na comercialização de
      produtos e serviços, sem viés ou influência política.
      </p>

      <p>
      Os produtos proibidos incluem aqueles que possam incentivar discussões sobre temas como:
      </p>
      <ul class="list-disc space-y-3 text-gray-700 pl-5">
        <li>
        <strong>Orientação Sexual e Identidade de Gênero:</strong>
      Produtos que promovam ou
      critiquem questões relacionadas à orientação sexual ou identidade de gênero,
      como acessórios ou slogans que incentivem debates polêmicos sobre o tema.
      Movimentos de Gênero: Produtos que apoiem ou critiquem movimentos
      relacionados aos direitos de gênero, como feminismo ou machismo, que possam
      incitar divisões ou controvérsias.
        </li>

        <li>
        <strong>Questões Raciais e Étnicas:</strong>
       Produtos que incentivem discussões sobre raça ou
      etnia, incluindo aqueles que promovam ou critiquem movimentos raciais ou
      culturais, símbolos, frases ou imagens que possam gerar divisões.

        </li>
        <li>
              Ambientalismo Radical e Ativismo Climático: Itens que promovam visões
      extremas sobre questões ambientais ou ativismo climático que possam polarizar
      opiniões.
        </li>
        <li>
        <strong>Movimentos Antissistema e Conspirações:</strong>
               Produtos que incentivem
      ideologias antissistema, teorias da conspiração ou que promovam desconfiança
      em relação a instituições públicas e privadas
        </li>
      </ul>

      <p>
      A plataforma não está restringindo o direito de qualquer grupo defender suas causas ou
pontos de vista, mas nosso foco é garantir que o ambiente de venda permaneça
neutro e livre de polêmicas sociais. Produtos que incitem discussões sobre ideologias
sociais são proibidos para preservar a harmonia e a imparcialidade dentro da nossa
comunidade.
      </p>
      <p>
        Nosso compromisso é manter o espaço comercial centrado em produtos e serviços,
        evitando o uso da plataforma para promover mensagens ideológicas. Dessa forma, todos
        os usuários poderão interagir em um ambiente seguro, neutro e acolhedor, livre de
        conflitos ideológicos ou sociais.
      </p>

      <h2 class="text-xl font-semibold text-gray-700 mt-6 mb-4">Concorrência Desleal</h2>


      <p>
        É expressamente proibido que fornecedores utilizem a plataforma para vender
        diretamente ao consumidor final. De acordo com a legislação vigente, a prática de
        concorrência desleal caracteriza-se por ações que visam prejudicar direta ou
        indiretamente os demais parceiros comerciais de forma anticompetitiva. A concorrência
        desleal infringe o art. 195 da Lei nº 9.279/96, conhecida como Lei da Propriedade
        Industrial, que estabelece que “comete crime de concorrência desleal quem, entre
        outros, emprega meios fraudulentos para desviar, em lucro próprio ou alheio, clientela
        de outrem”.
      </p>
      <p>
        A atuação dos fornecedores deve limitar-se à venda por meio de atacado ou em
        condições que preservem a distinção entre o canal de distribuição e o consumidor final,
        com o objetivo de garantir uma cadeia de fornecimento justa e equitativa. Além disso, o
        Código de Defesa do Consumidor (Lei nº 8.078/90) reforça a proteção ao consumidor e
        a necessidade de práticas leais no mercado, evitando que intermediários sejam
        prejudicados por ações diretas de fornecedores que ignoram a cadeia de distribuição
        previamente estabelecida.
      </p>
      <p>
        <strong>Penalidades:</strong> O não cumprimento desta norma será considerado como violação grave
        da política de anúncio da plataforma. As cláusulas incluem a suspensão imediata do
        fornecedor, e em casos reincidentes, o banimento definitivo da plataforma, além de
        responsabilização judicial, caso seja comprovado o uso de práticas fraudulentas para
        desviar clientela.
      </p>
      <p>
        <strong>Atenção:</strong> Anúncios que desrespeitem estas diretrizes estão sujeitos à exclusão imediata,
        sem aviso prévio, e podem resultar em penalidades adicionais, como a suspensão da
        conta do anunciante.
      </p>

      <p>
        A plataforma se reserva o direito de atualizar esta política conforme necessário para
        manter um ambiente seguro e em conformidade com as leis.
      </p>

    </div>
  </div>
`;

  return (
    <AuthWrapper>
      <>
        <div className="flex flex-col items-center justify-center w-full container m-4 space-y-8 bg-white p-4 rounded-lg shadow-sm">
          <div
            className="space-y-3"
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(advertisingPolicyHTML),
            }}
          ></div>
        </div>
      </>
    </AuthWrapper>
  );
};
