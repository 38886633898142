'use client';

import type React from 'react';
import { useState, useEffect, useRef } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import type Client from 'src/model/User';

interface MenuItem {
  title: string;
  key: string; // Identificador para URL
  content: JSX.Element;
}

interface ProfileSideMenuProps {
  user?: Client;
  menuItems: MenuItem[];
  subTitle?: string;
}

export const ProfileSideMenu: React.FC<ProfileSideMenuProps> = ({
  user,
  menuItems,
  subTitle,
}) => {
  const location = useLocation();
  const history = useHistory();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement | null>(null);
  const searchParams = new URLSearchParams(location.search);
  const activeTabKey = searchParams.get('tab') || menuItems[0].key;

  const activeItem =
    menuItems.find((item) => item.key === activeTabKey) || menuItems[0];

  // Controla a rolagem do corpo da página
  useEffect(() => {
    if (isMenuOpen) {
      document.body.style.overflow = 'hidden'; // Desativa a rolagem
    } else {
      document.body.style.overflow = 'auto'; // Restaura a rolagem
    }
    return () => {
      document.body.style.overflow = 'auto'; // Restaura ao sair do componente
    };
  }, [isMenuOpen]);

  // Fecha o menu se clicar fora dele
  const handleOutsideClick = (e: MouseEvent) => {
    if (menuRef.current && !menuRef.current.contains(e.target as Node)) {
      setIsMenuOpen(false); // Fecha o menu ao clicar fora
    }
  };

  useEffect(() => {
    if (isMenuOpen) {
      document.addEventListener('mousedown', handleOutsideClick);
    } else {
      document.removeEventListener('mousedown', handleOutsideClick);
    }
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [isMenuOpen]);

  const handleTabChange = (key: string) => {
    history.push(`${location.pathname}?tab=${key}`);
    setIsMenuOpen(false); // Fecha o menu após a seleção
  };

  return (
    <div className="flex flex-col md:flex-row">
      {/* Sidebar para Desktop */}
      <aside className="md:w-52 border-r border-red-200 p-4 min-h-max md:block hidden">
        <h2 className="text-lg font-semibold mb-6">
          {user ? `Olá ${user.name.split(' ')[0]}` : subTitle}
        </h2>
        <ul className="space-y-4">
          {menuItems.map((item) => (
            <li
              key={item.key}
              className={`relative transition-all duration-200 pl-3 py-2 rounded-md cursor-pointer ${
                activeItem.key === item.key
                  ? 'bg-red-50 text-red-600 font-semibold border-l-4 border-red-500'
                  : 'hover:bg-gray-50 text-gray-700 hover:text-red-600 hover:pl-4'
              }`}
              onClick={() => handleTabChange(item.key)}
            >
              {item.title}
            </li>
          ))}
        </ul>
      </aside>

      {/* Botão de menu para Mobile */}
      <div className="md:hidden sticky top-0 z-40 bg-white shadow-sm p-3 flex justify-between items-center">
        <h2 className="text-lg font-semibold">
          {user ? `Olá ${user.name.split(' ')[0]}` : subTitle}
        </h2>
        <button
          onClick={() => setIsMenuOpen(!isMenuOpen)}
          className="flex items-center justify-center bg-red-500 hover:bg-red-600 text-white p-2 rounded-md shadow-md transition-all duration-200"
          aria-label="Menu"
        >
          <div className="flex flex-col justify-center items-center w-6 h-6 space-y-1.5">
            <span
              className={`block w-6 h-0.5 bg-white rounded transition-all duration-300 ${isMenuOpen ? 'transform rotate-45 translate-y-2' : ''}`}
            ></span>
            <span
              className={`block w-6 h-0.5 bg-white rounded transition-all duration-300 ${isMenuOpen ? 'opacity-0' : 'opacity-100'}`}
            ></span>
            <span
              className={`block w-6 h-0.5 bg-white rounded transition-all duration-300 ${isMenuOpen ? 'transform -rotate-45 -translate-y-2' : ''}`}
            ></span>
          </div>
          <span className="ml-2">Menu</span>
        </button>
      </div>

      {/* Menu Lateral (Mobile) */}
      <div
        className={`fixed top-0 left-0 right-0 bottom-0 bg-gray-900 transition-opacity duration-300 z-[100] md:hidden ${
          isMenuOpen
            ? 'bg-opacity-50 opacity-100 visible'
            : 'bg-opacity-0 opacity-0 invisible'
        }`}
      >
        <div
          ref={menuRef}
          className={`fixed top-0 left-0 bg-white w-4/5 h-full shadow-xl z-[101] transition-transform duration-300 ease-in-out ${
            isMenuOpen
              ? 'transform translate-x-0'
              : 'transform -translate-x-full'
          }`}
        >
          <div className="flex justify-between items-center p-4 border-b border-gray-200">
            <h2 className="text-lg font-semibold">
              {user ? `Olá ${user.name.split(' ')[0]}` : subTitle}
            </h2>
            <button
              onClick={() => setIsMenuOpen(false)}
              className="bg-red-500 hover:bg-red-600 text-white p-2 rounded-full w-8 h-8 flex items-center justify-center transition-colors duration-200"
              aria-label="Fechar menu"
            >
              <span className="text-xl">&times;</span>
            </button>
          </div>
          <ul className="p-4 space-y-1">
            {menuItems.map((item) => (
              <li
                key={item.key}
                className={`p-3 rounded-lg cursor-pointer transition-all duration-200 ${
                  activeItem.key === item.key
                    ? 'bg-red-50 text-red-600 font-semibold border-l-4 border-red-500'
                    : 'text-gray-700 hover:bg-gray-50 hover:text-red-600'
                }`}
                onClick={() => handleTabChange(item.key)}
              >
                {item.title}
              </li>
            ))}
          </ul>
        </div>
      </div>

      <div className="w-full flex-1 px-4 md:px-6 py-4 flex justify-center">
        <div className="w-full max-w-4xl">
          <h1 className="text-xl md:text-2xl font-bold mb-4">
            {activeItem.title}
          </h1>
          <div className="flex w-full overflow-x-auto">
            {activeItem.content}
          </div>
        </div>
      </div>
    </div>
  );
};
