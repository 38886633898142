import React, { FormEvent } from 'react';
import { Button } from 'src/components/Button';
import { InputFile } from 'src/components/InputFile';
import { InputText } from 'src/components/InputText';
import { Select } from 'src/components/Select';
import { NameFiles } from 'src/features/createAccount/screens/CreateAccount/ui';
import {
  FormInputGradeSize,
  FormRegisterProduct,
} from 'src/features/createProduct/types/types';
import { UseFormReturn } from 'src/hooks';
import { Category } from 'src/model/Category';
// import TagSelector from '../components/TagSelector';
import TextArea from 'src/components/TextArea';
import { Grade } from 'src/model/Grade';
import { GradeForm } from '../components/GradeForm';
import InputTags from '../components/InputsTags';
import CategorySelector from './../components/CategorySelect';
import { GradeSize } from 'src/model/GradeSize';
import { Checkbox } from 'src/components/Checkbox';

interface CreateProductUIProps
  extends Pick<UseFormReturn, 'formData' | 'formErrors' | 'onChangeFormInput'> {
  state?: boolean;
  formNameFiles?: NameFiles;
  categoryOptions: Category[];
  selectedCategories: Category[];
  filesImage: File[];
  filesImageGrade: File[];
  previewFiles: string[];
  previewFilesGrade: string;
  previewGradesSizes: GradeSize[];
  inputTags: string[];
  grade: Grade[];
  shippingCost: string | null;
  isGrade: boolean;
  // formGradeSize: FormData;
  // formErrorsGradeSize: FormErrors;
  // formNameFilesGrade?: NameFiles;
  onSubmitRegister: (e: FormEvent) => void;
  FileUpload: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeFormFileInput: (
    inputName: string
  ) => (file: File | undefined) => void;
  onDeleteFile: (value: number) => void;
  onAddTag: (tag: string) => void;
  onRemoveTag: (value: string) => void;
  onSelectCategory: (e: React.FocusEvent<HTMLSelectElement>) => void;
  onRemoveCategory: (value: string) => void;
  addQuantiValue: (e: FormEvent) => void;
  removeGrade: (index: number) => void;
  onShippingCalc: (
    height: string,
    width: string,
    depth: string,
    weight: string
  ) => void;
  addSize: (e: FormEvent) => void;
  // onChangeFormGradeSize: OnChangeFormInput;
  onChangeFormFileInputGradeSize: (
    e: React.ChangeEvent<HTMLInputElement>
  ) => void;
  onGrade: (inputName: string) => (value: string) => void;
}

const statusOptions = [
  { value: 'Ativo', label: 'Ativo' },
  { value: 'Inativo', label: 'Inativo' },
  { value: 'Estoque Zerado', label: 'Estoque Zerado' },
];
const promotionOptions = [
  { value: 'yes', label: 'Sim' },
  { value: 'no', label: 'Não' },
];

export const CreateProductUI: React.FC<CreateProductUIProps> = ({
  formData,
  formErrors,
  selectedCategories,
  categoryOptions,
  inputTags,
  filesImage,
  previewFiles,
  previewGradesSizes,
  grade,
  // formGradeSize,
  // formErrorsGradeSize,
  // formNameFilesGrade,
  shippingCost,
  filesImageGrade,
  previewFilesGrade,
  isGrade,
  onChangeFormInput,
  onSubmitRegister,
  FileUpload,
  onDeleteFile,
  onAddTag,
  onRemoveTag,
  onSelectCategory,
  onRemoveCategory,
  addQuantiValue,
  removeGrade,
  onShippingCalc,
  addSize,
  onGrade,
  // onChangeFormGradeSize,
  // onChangeFormFileInput,
  onChangeFormFileInputGradeSize,
}) => {
  return (
    <section className="flex flex-col md:flex-row items-center justify-between w-full container bg-white rounded-lg shadow-sm mt-4">
      <form
        onSubmit={onSubmitRegister}
        className="flex-col items-center w-full space-y-4"
      >
        <InputText
          name="name"
          label="Nome do Produto *"
          placeholder="Digite o nome do produto..."
          //value={formData[FormRegisterProduct.name]}
          onChange={(e) =>
            onChangeFormInput(FormRegisterProduct.name)(e.target.value)
          }
          error={formErrors.name && formErrors.name[0]}
          inputClassName="rounded-lg p-2 w-full bg-[#fafafa] shadow-inner"
        />
        <TextArea
          name="description"
          label="Descrição *"
          placeholder="Descreva o produto..."
          value={formData[FormRegisterProduct.description]}
          onChange={(e) =>
            onChangeFormInput(FormRegisterProduct.description)(e.target.value)
          }
          error={formErrors.description && formErrors.description[0]}
          textAreaClassName="rounded-lg p-2 w-full bg-[#fafafa] shadow-inner"
        />
        <div className="grid grid-cols-1 md:grid-cols-2 grid-rows-2 md:grid-rows-1 gap-4">
          {/* Status do Produto */}
          <InputText
            name="sku"
            label="SKU *"
            placeholder="Digite o código do SKU..."
            value={formData[FormRegisterProduct.sku]}
            onChange={(e) =>
              onChangeFormInput(FormRegisterProduct.sku)(e.target.value)
            }
            error={formErrors.sku && formErrors.sku[0]}
            inputClassName="rounded-lg p-2 w-full bg-[#fafafa] shadow-inner"
          />
          <Select
            name="status"
            label="Status *"
            value={formData[FormRegisterProduct.status]}
            onChange={(e) =>
              onChangeFormInput(FormRegisterProduct.status)(e.target.value)
            }
            error={formErrors.status && formErrors.status[0]}
            options={statusOptions}
            selectClassName="rounded-lg p-2 w-full bg-[#fafafa] shadow-inner"
          />
          {/* <InputText
            name="stock"
            label="Estoque Total *"
            type="number"
            placeholder="Digite a quantidade em estoque..."
            value={formData[FormRegisterProduct.stock]}
            onChange={(e) =>
              onChangeFormInput(FormRegisterProduct.stock)(e.target.value)
            }
            error={formErrors.stock && formErrors.stock[0]}
            inputClassName="rounded-lg p-2 w-full bg-[#fafafa] shadow-inner"
          /> */}
          {/* Promoção */}
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 grid-rows-2 md:grid-rows-1 gap-4">
          {/* Preço
          <InputText
            name="price"
            label="Preço *"
            type="number"
            placeholder="Digite o Preço..."
            value={formData[FormRegisterProduct.price]}
            onChange={(e) =>
              onChangeFormInput(FormRegisterProduct.price)(e.target.value)
            }
            error={formErrors.price && formErrors.price[0]}
            inputClassName="rounded-lg p-2 w-full bg-[#fafafa] shadow-inner"
          /> */}
          <Select
            name="promotion"
            label="Promoção *"
            value={formData[FormRegisterProduct.promotion]}
            onChange={(e) =>
              onChangeFormInput(FormRegisterProduct.promotion)(e.target.value)
            }
            error={formErrors.promotion && formErrors.promotion[0]}
            options={promotionOptions}
            selectClassName="rounded-lg p-2 w-full bg-[#fafafa] shadow-inner"
          />
          <InputText
            name="parcel"
            label="Quantiade de Parcelas*"
            type="number"
            max={12}
            placeholder="Digite o numero de parcelas..."
            value={formData[FormRegisterProduct.parcel]}
            onChange={(e) =>
              onChangeFormInput(FormRegisterProduct.parcel)(e.target.value)
            }
            error={formErrors.parcel && formErrors.parcel[0]}
            inputClassName="rounded-lg p-2 w-full bg-[#fafafa] shadow-inner"
          />
          {/* <InputText
            name="discount"
            label="Preço com Desconto"
            type="number"
            placeholder="Digite o Preço..."
            value={formData[FormRegisterProduct.priceDiscount]}
            onChange={(e) =>
              onChangeFormInput(FormRegisterProduct.priceDiscount)(
                e.target.value
              )
            }
            error={formErrors.priceDiscount && formErrors.priceDiscount[0]}
            inputClassName="rounded-lg p-2 w-full bg-[#fafafa] shadow-inner"
            disabled={
              formData[FormRegisterProduct.promotion] === 'yes' ? false : true
            }
          /> */}
          {/* SKU */}
        </div>
        {/* Seletor de Categorias */}
        <CategorySelector
          categorys={categoryOptions}
          selectedCategories={selectedCategories}
          selectCategory={onSelectCategory}
          removeCategory={onRemoveCategory}
          error={
            selectedCategories.length === 0
              ? 'Selecione pelo menos uma Categoria'
              : null
          }
        />
        <InputTags
          tags={inputTags}
          addTag={onAddTag}
          removeTag={onRemoveTag}
          error={inputTags.length === 0 ? 'Adicione pelo menos uma tag.' : null}
          inputClassName="rounded-lg p-2 w-full bg-[#fafafa] shadow-inner"
        />
        {/* Marca */}
        <InputText
          name="brand"
          label="Marca *"
          placeholder="Digite a marca do produto..."
          value={formData[FormRegisterProduct.brandName]}
          onChange={(e) =>
            onChangeFormInput(FormRegisterProduct.brandName)(e.target.value)
          }
          error={formErrors.brandName && formErrors.brandName[0]}
          inputClassName="rounded-lg p-2 w-full bg-[#fafafa] shadow-inner"
        />
        {/* Dimensões */}
        <div className="grid grid-cols-1 md:grid-cols-4 grid-rows-4 md:grid-rows-1 gap-4">
          <InputText
            name="dimensions.width"
            type="number"
            label="Largura (cm) *"
            placeholder="Digite a largura..."
            value={formData[FormRegisterProduct.dimensionsWidth]}
            onChange={(e) => {
              const value = e.target.value;

              if (/^\d*\.?\d*$/.test(value) && parseFloat(value) >= 0) {
                onChangeFormInput(FormRegisterProduct.dimensionsWidth)(value);
              } else {
                onChangeFormInput(FormRegisterProduct.dimensionsWidth)('');
              }
            }}
            onBlur={() =>
              onShippingCalc(
                formData[FormRegisterProduct.dimensionsHeight],
                formData[FormRegisterProduct.dimensionsWidth],
                formData[FormRegisterProduct.dimensionsDepth],
                formData[FormRegisterProduct.dimensionsWeight]
              )
            }
            error={formErrors.dimensionsWidth && formErrors.dimensionsWidth[0]}
            inputClassName="rounded-lg p-2 w-full bg-[#fafafa] shadow-inner"
          />

          <InputText
            name="dimensions.height"
            label="Altura (cm) *"
            type="number"
            placeholder="Digite a altura..."
            value={formData[FormRegisterProduct.dimensionsHeight]}
            onChange={(e) => {
              const value = e.target.value;

              if (/^\d*\.?\d*$/.test(value) && parseFloat(value) >= 0) {
                onChangeFormInput(FormRegisterProduct.dimensionsHeight)(value);
              } else {
                onChangeFormInput(FormRegisterProduct.dimensionsHeight)('');
              }
            }}
            onBlur={() =>
              onShippingCalc(
                formData[FormRegisterProduct.dimensionsHeight],
                formData[FormRegisterProduct.dimensionsWidth],
                formData[FormRegisterProduct.dimensionsDepth],
                formData[FormRegisterProduct.dimensionsWeight]
              )
            }
            error={
              formErrors.dimensionsHeight && formErrors.dimensionsHeight[0]
            }
            inputClassName="rounded-lg p-2 w-full bg-[#fafafa] shadow-inner"
          />

          <InputText
            name="dimensions.depth"
            label="Profundidade (cm) *"
            type="number"
            placeholder="Digite a profundidade..."
            value={formData[FormRegisterProduct.dimensionsDepth]}
            onChange={(e) => {
              const value = e.target.value;

              if (/^\d*\.?\d*$/.test(value) && parseFloat(value) >= 0) {
                onChangeFormInput(FormRegisterProduct.dimensionsDepth)(value);
              } else {
                onChangeFormInput(FormRegisterProduct.dimensionsDepth)('');
              }
            }}
            onBlur={() =>
              onShippingCalc(
                formData[FormRegisterProduct.dimensionsHeight],
                formData[FormRegisterProduct.dimensionsWidth],
                formData[FormRegisterProduct.dimensionsDepth],
                formData[FormRegisterProduct.dimensionsWeight]
              )
            }
            error={formErrors.dimensionsDepth && formErrors.dimensionsDepth[0]}
            inputClassName="rounded-lg p-2 w-full bg-[#fafafa] shadow-inner"
          />

          <InputText
            name="dimensions.weight"
            label="Peso *"
            type="number"
            placeholder="Digite o peso..."
            value={formData[FormRegisterProduct.dimensionsWeight]}
            onChange={(e) => {
              const value = e.target.value;

              if (/^\d*\.?\d*$/.test(value) && parseFloat(value) >= 0) {
                onChangeFormInput(FormRegisterProduct.dimensionsWeight)(value);
              } else {
                onChangeFormInput(FormRegisterProduct.dimensionsWeight)('');
              }
            }}
            onBlur={() =>
              onShippingCalc(
                formData[FormRegisterProduct.dimensionsHeight],
                formData[FormRegisterProduct.dimensionsWidth],
                formData[FormRegisterProduct.dimensionsDepth],
                formData[FormRegisterProduct.dimensionsWeight]
              )
            }
            error={
              formErrors.dimensionsWeight && formErrors.dimensionsWeight[0]
            }
            inputClassName="rounded-lg p-2 w-full bg-[#fafafa] shadow-inner"
          />
        </div>
        <h4 className="text-sm w-full text-gray-500">
          As dimensões máximas são de 100 cm em cada lado (altura, largura e
          comprimento), e a soma dos três lados não pode ultrapassar 200 cm. As
          dimensões mínimas são de 15 cm x 10 cm x 1 cm. O peso máximo é de 30
          kg.
        </h4>
        <div className="flex gap-2 flex-col min-w-full">
          {shippingCost && (
            <div className="flex w-full flex-col border rounded-lg p-2 shadow-lg">
              <h4 className="text-sm text-gray-500">
                A taxa de frete acima é um valor padrão. O valor exato do custo
                do frete será calculado no momento da compra baseando-se no
                endereço do comprador e do vendedor. Para promoções ativas de
                frete grátis, esta taxa não será considerada.
              </h4>
              <h4 className="text-sm font-semibold flex gap-1 items-center w-full justify-end">
                Frete Base: <span className="text-red-500">{shippingCost}</span>
              </h4>
            </div>
          )}
          <div className="flex gap-2 w-full items-center">
            <div className="w-full flex gap-2">
              <Checkbox
                name="shipping"
                value="true"
                checked={formData[FormRegisterProduct.freeShipping] === 'true'}
                label="Frete Grátis"
                error={formErrors.freeShipping && formErrors.freeShipping[0]}
                onChange={(value) => {
                  const newValue = value === 'true' ? 'true' : 'false';
                  onChangeFormInput(FormRegisterProduct.freeShipping)(newValue);
                }}
                checkboxClassName="w-[1rem] h-[1rem] border-red-400 border-[.1rem] accent-red-500 cursor-pointer"
                labelClassName="text-gray-500 text-md font-normal cursor-pointer"
              />
            </div>
          </div>
        </div>

        <div className="flex gap-2 w-full items-center">
          <div className="w-full flex gap-2">
            <Checkbox
              name="isGrade"
              value="Sim"
              checked={formData[FormRegisterProduct.isGrade] === 'true'}
              label="Produto terá grade?"
              error={formErrors.isGrade && formErrors.isGrade[0]}
              onChange={(value) => {
                const newValue = value === 'true' ? 'true' : 'false';
                onChangeFormInput(FormRegisterProduct.isGrade)(newValue);
                onGrade(FormRegisterProduct.isGrade)(newValue);
              }}
              checkboxClassName="w-[1rem] h-[1rem] border-red-400 border-[.1rem]"
              labelClassName="text-gray-500 text-md font-"
            />
          </div>
        </div>

        {formErrors.isGrade && (
          <p className="text-red-500 text-sm">{formErrors.isGrade[0]}</p>
        )}
        {isGrade ? (
          <GradeForm
            formData={formData}
            formErrors={formErrors}
            variants={grade}
            onChangeFormInput={onChangeFormInput}
            addVariant={addQuantiValue}
            removeVariant={removeGrade}
            addSize={addSize}
            // formGradeSize={formGradeSize}
            // formErrorsGradeSize={formErrorsGradeSize}
            // onChangeFormGradeSize={onChangeFormGradeSize}
            onChangeFormFileInput={onChangeFormFileInputGradeSize}
            filesImageGrade={filesImageGrade}
            previewFilesGrade={previewFilesGrade}
            previewGradesSizes={previewGradesSizes}
            isEdit={false}
          />
        ) : (
          <>
            <InputText
              label="Valor unitário"
              name="price"
              type="number"
              id="price"
              value={formData[FormInputGradeSize.price]}
              onChange={(e) =>
                onChangeFormInput(FormInputGradeSize.price)(e.target.value)
              }
              error={formErrors.price?.[0]}
              inputClassName="rounded-lg p-2 w-full bg-[#fafafa] shadow-inner"
              placeholder="Digite o preço"
            />
            <InputText
              label="Valor + Desconto"
              name="discount"
              type="number"
              id="discount"
              value={formData[FormInputGradeSize.discount]}
              onChange={(e) =>
                onChangeFormInput(FormInputGradeSize.discount)(e.target.value)
              }
              error={formErrors.discount?.[0]}
              inputClassName="rounded-lg p-2 w-full bg-[#fafafa] shadow-inner"
              placeholder="Digite o valor com desconto"
            />
            <InputText
              label="Estoque"
              name="stock"
              type="number"
              id="stock"
              value={formData[FormInputGradeSize.stock]}
              onChange={(e) =>
                onChangeFormInput(FormInputGradeSize.stock)(e.target.value)
              }
              error={formErrors.stock?.[0]}
              inputClassName="rounded-lg p-2 w-full bg-[#fafafa] shadow-inner"
              placeholder="Digite a quantidade em estoque"
            />
          </>
        )}
        {/* Garantia */}
        <InputText
          name="warranty"
          label="Garantia *"
          placeholder="Digite ..."
          value={formData[FormRegisterProduct.warranty]}
          onChange={(e) =>
            onChangeFormInput(FormRegisterProduct.warranty)(e.target.value)
          }
          error={formErrors.warranty && formErrors.warranty[0]}
          inputClassName="rounded-lg p-2 w-full bg-[#fafafa] shadow-inner"
        />
        {/* Imagem */}
        {/* File Upload */}
        <div>
          <label>
            Upload de até{' '}
            <span className="text-red-500 font-semibold">
              4 imagens e 1 vídeo ou 5 imagens
            </span>
            <br />
            tamanho recomendado{' '}
            <span className="text-red-500 font-semibold">
              Min 800px X 800px
            </span>{' '}
            ou{' '}
            <span className="text-red-500 font-semibold">
              Max 1600px X 1600px
            </span>
          </label>
          <InputFile
            name="mediaFiles"
            label="Imagens ou vídeo"
            multiple
            onChange={FileUpload}
            error={filesImage.length > 5 ? 'Máximo de 5 arquivos' : undefined}
          />

          <div className="flex space-x-2 mt-2">
            {previewFiles.map((preview, index) => (
              <div key={index} className="relative">
                <img
                  src={preview}
                  alt={`File ${index}`}
                  className="w-24 h-24 object-cover rounded"
                />
                <button
                  type="button"
                  className="absolute top-0 right-0 bg-red-500 text-white p-1 rounded-full"
                  onClick={() => onDeleteFile(index)}
                >
                  X
                </button>
              </div>
            ))}
          </div>
          {/* YouTube URL */}
          {/* <InputText
              name="youtubeUrl"
              label="URL do YouTube (opcional)"
              placeholder="Insira o link do YouTube"
              value={youtubeUrl}
              onChange={handleYoutubeUrl}
              error={
                files.some((file) => file.type.startsWith('video/'))
                  ? 'Não pode enviar vídeo e YouTube juntos.'
                  : undefined
              }
            /> */}
        </div>
        {/* Botão de Cadastro */}
        <div className="w-full flex justify-center">
          <Button
            type="submit"
            title="Cadastrar Produto"
            buttonStyle="red"
            size="xlg"
            className="w-[80%]"
            disabled={
              formData.name === '' ||
              formData.description === '' ||
              formData.status === '' ||
              selectedCategories.length === 0
            }
          />
        </div>
      </form>
    </section>
  );
};
