import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Product } from 'src/model/Product';
import { StoreProfileUI } from './StoreProfile/ui';
import { api } from 'src/services/api';

export const StoreProfileScreen: React.FC<{
  valuePrice: (product: Product, discount: boolean) => string;
}> = ({ valuePrice }): JSX.Element => {
  const { id: storeIdFromUrl } = useParams<{ id?: string }>(); // Pegamos o ID da URL (caso exista)
  const location = useLocation(); // Pegamos o caminho atual
  const [imageDetail, setImageDetail] = useState<string>('');
  const [imageBanner, setImageBanner] = useState<string>('');
  const [storeName, setStoreName] = useState<string>('');
  const [productCount, setProductCount] = useState<number>(0);
  const [products, setProducts] = useState<Product[]>([]);
  const [storeDescription, setStoreDescription] = useState<string>('');
  const [storeEmail, setStoreEmail] = useState<string>('');
  const [storePhone, setStorePhone] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        let storeData;
        let productData = [];

        if (storeIdFromUrl) {
          const storeResponse = await api.get(
            `/product/store/${storeIdFromUrl}`
          );
          storeData = storeResponse.data;

          const productResponse = await api.get(
            `/product/store/${storeIdFromUrl}`
          );
          productData = productResponse.data;
        } else if (location.pathname === '/store-profile') {
          console.log('Entrou storeprofile');
          const storeResponse = await api.get(`/store/find-store/`);
          storeData = storeResponse.data;

          const productResponse = await api.get(`/product/find-store/`);
          productData = productResponse.data;
        } else {
          throw new Error('Rota desconhecida');
        }

        console.log('Dados da loja carregados:', storeData);
        console.log('Produtos carregados:', productData);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        setProducts(productData);
        setProductCount(productData.length);
        setStoreName(storeData.name || 'Loja Desconhecida');
        setStoreDescription(storeData.description || 'Sem descrição');
        setStoreEmail(storeData.email || 'Sem e-mail');
        setStorePhone(storeData.phone || 'Sem telefone');
        setImageDetail(storeData.imageDetail || '');
        setImageBanner(storeData.imageBanner || '');
      } catch (error) {
        console.error('Erro ao carregar dados da loja:', error);
        setError('Erro ao carregar dados');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [storeIdFromUrl, location.pathname]); // Executa novamente quando a URL mudar

  if (loading) {
    return <div>Carregando...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <StoreProfileUI
      storeName={storeName}
      productCount={productCount}
      products={products}
      valuePrice={valuePrice}
      storeDescription={storeDescription}
      storeEmail={storeEmail}
      storePhone={storePhone}
      imageDetail={imageDetail}
      imageBanner={imageBanner}
      storeImage={''}
    />
  );
};
