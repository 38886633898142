import { FC, FormEvent } from 'react';
import { Button } from 'src/components/Button';
import { InputText } from 'src/components/InputText';
import { UseFormReturn } from 'src/hooks';

interface EditGradeSizeProps
  extends Pick<UseFormReturn, 'formData' | 'formErrors' | 'onChangeFormInput'> {
  submitGradesize: (e: FormEvent) => void;
  onClose: () => void;
}

export enum EditGradeSizes {
  size = 'size',
  stock = 'stock',
  price = 'price',
  discount = 'discount',
}

export const EditGradeSize: FC<EditGradeSizeProps> = ({
  formData,
  formErrors,
  onChangeFormInput,
  submitGradesize,
  onClose,
}) => {
  return (
    <form onSubmit={submitGradesize}>
      <div className="w-full h-full">
        <hr className="mb-4" />
        <h2 className="text-lg font-bold mb-4">Edição de variação</h2>
        <div className="flex items-center justify-between mb-4">
          <div>
            <InputText
              label="Tamanho"
              name="size"
              type="text"
              id="size"
              value={formData[EditGradeSizes.size]}
              onChange={(e) =>
                onChangeFormInput(EditGradeSizes.size)(e.target.value)
              }
              error={formErrors.size?.[0]}
              inputClassName="rounded-lg p-2 w-full bg-[#fafafa] shadow-inner"
              placeholder="Digite o preço"
            />
            <InputText
              label="Valor unitário"
              name="price"
              type="number"
              id="price"
              value={formData[EditGradeSizes.price]}
              onChange={(e) =>
                onChangeFormInput(EditGradeSizes.price)(e.target.value)
              }
              error={formErrors.price?.[0]}
              inputClassName="rounded-lg p-2 w-full bg-[#fafafa] shadow-inner"
              placeholder="Digite o preço"
            />
          </div>
          <div>
            <InputText
              label="Valor + Desconto"
              name="discount"
              type="number"
              id="discount"
              value={formData[EditGradeSizes.discount]}
              onChange={(e) =>
                onChangeFormInput(EditGradeSizes.discount)(e.target.value)
              }
              error={formErrors.discount?.[0]}
              inputClassName="rounded-lg p-2 w-full bg-[#fafafa] shadow-inner"
              placeholder="Digite o valor com desconto"
            />
            <InputText
              label="Estoque"
              name="stock"
              type="number"
              id="stock"
              value={formData[EditGradeSizes.stock]}
              onChange={(e) =>
                onChangeFormInput(EditGradeSizes.stock)(e.target.value)
              }
              error={formErrors.stock?.[0]}
              inputClassName="rounded-lg p-2 w-full bg-[#fafafa] shadow-inner"
              placeholder="Digite a quantidade em estoque"
            />
          </div>
        </div>
        <div className="flex justify-center">
          <Button
            type="submit"
            buttonStyle="primary"
            className="mt-4 mr-2"
            title="Salvar Alterações"
          />
          <Button
            onClick={onClose}
            buttonStyle="red"
            className="mt-4"
            title="fechar"
          />
        </div>
      </div>
    </form>
  );
};
