import React from 'react';
import { NotificationComponentScreen } from 'src/components/NotificationComponent';
import { AuthWrapper } from 'src/features/core/auth/screen/AuthWrapper';
import { AlertMessage } from 'src/model/AlertMessage';

interface NotificationsUIProps {
  alertMessage: AlertMessage[];
}
export const NotificationsUI: React.FC<NotificationsUIProps> = ({
  alertMessage,
}): JSX.Element => {
  return (
    <AuthWrapper>
      <div className="w-[80%] bg-white mt-4 p-4 rounded-md border">
        <NotificationComponentScreen
          alertMessage={alertMessage}
          variant="page"
        />
      </div>
    </AuthWrapper>
  );
};
